import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Table } from "components";
import { useOrganisations } from "hooks";
import { ReactComponent as Plus } from "assets/iconsBtn/plus.svg";
import { Id } from "types";
import { Header } from "../components";
import styles from "../styles.module.scss";

function OrganisationsPage() {
  const navigate = useNavigate();
  const { requireOrganisationsByPage, organisations, pending, pagination, clear } =
    useOrganisations();

  useEffect(() => {
    requireOrganisationsByPage();

    return () => {
      clear();
    };
  }, [requireOrganisationsByPage, clear]);

  const navigateToAddOrganisation = () => {
    navigate("/admin/organisations/add");
  };

  const navigateToRemoveOrganisation = (id: Id) => {
    navigate(`/admin/organisations/${id}/remove`);
  };

  const navigateToEditOrganisation = (id: Id) => {
    navigate(`/admin/organisations/${id}/edit`);
  };

  const onPageChange = ({ selected }) => {
    requireOrganisationsByPage(selected + 1);
  };

  return (
    <>
      <Header
        title='Организации'
        addEntityFeature={
          <Button className={styles.button} onClick={navigateToAddOrganisation}>
            <Plus /> Добавить организацию
          </Button>
        }
      />
      <Table
        headers={["ID", "Название организации"]}
        editable
        removable
        values={organisations}
        widthValues={[57]}
        fields={["id", "name"]}
        paginate
        pending={pending && organisations.length === 0}
        pageCount={pagination.count_pages}
        removeCallback={navigateToRemoveOrganisation}
        onPageChange={onPageChange}
        editCallback={navigateToEditOrganisation}
        currentPage={pagination.current_page}
      />
      <Outlet />
    </>
  );
}

const OrganisationsPageComponent = observer(OrganisationsPage);
export { OrganisationsPageComponent as OrganisationsPage };

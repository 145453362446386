import { makeAutoObservable, observable } from "mobx";
import { Id, Manager, Pagination } from "types";
import { toJS } from "libs";

class ManagersStore {
  private _managers = observable<Manager>([]);

  private _pagination: Pagination = { current_page: 1, count_pages: NaN };

  constructor() {
    makeAutoObservable(this);
  }

  get managers() {
    return toJS(this._managers);
  }

  set managers(managers: Manager[]) {
    this._managers = observable(managers);
  }

  get pagination() {
    return toJS(this._pagination);
  }

  set pagination(pagination: Pagination) {
    this._pagination = pagination;
  }

  getManager(id: Id): Manager | undefined {
    const manager = this._managers.find((m) => m.id === Number(id));

    if (!manager) {
      return undefined;
    }

    return toJS(manager);
  }

  updateManager(manager: Manager) {
    const index = this._managers.findIndex((u) => u.id === Number(manager.id));
    this._managers.spliceWithArray(index, 1, [manager]);
  }

  addManager(manager: Manager) {
    this._managers.push(manager);
  }
}

const managersStore = new ManagersStore();
export { managersStore };

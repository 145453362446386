import { useContext, useEffect, useState } from "react";
import { Loader, PageWrapper, Select } from "components";
import { useSeasons } from "hooks";
import { ReactComponent as PlusLightIcon } from "assets/icons/plus_icon_light.svg";
import { ReactComponent as PlusDarkIcon } from "assets/icons/plus_icon_dark.svg";
import { ReactComponent as AddedLightIcon } from "assets/icons/added_icon_light.svg";
import { ReactComponent as AddedDarkIcon } from "assets/icons/added_icon_dark.svg";
import { ReactComponent as EmptyColorsIcon } from "assets/icons/empty_colors.svg";
import { Paint } from "types";
import { ColorsContext } from "contexts";
import styles from "./styles.module.scss";

export function SeasonsPage() {
  const { requireSeasons, seasons, requirePaintsBySeason, paints, isPending } = useSeasons();
  const [season, setSeason] = useState<string | undefined>(undefined);
  const { setUserColors, userColors } = useContext(ColorsContext);

  useEffect(() => {
    requireSeasons();
  }, [requireSeasons]);

  const changeSeason = (value: string) => {
    setSeason(value);

    const foundSeason = seasons.find((s) => s.name === value);

    if (!foundSeason) {
      console.error(`season with value=${value} not found`);
      return;
    }

    requirePaintsBySeason(foundSeason.id);
  };

  const addPaintToUserColors = (paint: Paint) => () => {
    const foundIndex = userColors.findIndex(
      (c) => c.color.id === paint.color.id && c.id === paint.id,
    );

    if (foundIndex === -1) {
      const newUserColors = [...userColors];
      newUserColors.push(paint);
      setUserColors(newUserColors);
    } else {
      const newUserColors = [...userColors];
      newUserColors.splice(foundIndex, 1);
      setUserColors(newUserColors);
    }
  };

  const renderPaintIcon = (paint: Paint) => {
    if (paint.color === null) return null;

    const foundUserColor = userColors.find(
      (c) => c.color.id === paint.color.id && c.id === paint.id,
    );

    if (paint.color.sense === "light" && !foundUserColor) {
      return <PlusDarkIcon className={styles.season_icon} onClick={addPaintToUserColors(paint)} />;
    }

    if (paint.color.sense !== "light" && !foundUserColor) {
      return <PlusLightIcon className={styles.season_icon} onClick={addPaintToUserColors(paint)} />;
    }

    if (paint.color.sense === "light" && foundUserColor) {
      return <AddedDarkIcon className={styles.season_icon} onClick={addPaintToUserColors(paint)} />;
    }

    if (paint.color.sense !== "light" && foundUserColor) {
      return (
        <AddedLightIcon className={styles.season_icon} onClick={addPaintToUserColors(paint)} />
      );
    }

    return null;
  };

  const formStylesForPaint = (paint: Paint) => {
    const background = `#${paint.color.hex}`;
    const color = paint.color?.sense === "light" ? "#0F1B24" : "#fff";

    return {
      background,
      color,
    };
  };

  const renderPaintEffect = (paint: Paint) => {
    if (!paint?.effect) return null;

    return (
      <img
        className={styles.season_effect}
        loading='lazy'
        src={paint.effect.max}
        alt={paint.effect.name}
      />
    );
  };

  const renderPaints = () => {
    if (paints.length === 0) {
      return (
        <div className={styles.emptyColors}>
          <EmptyColorsIcon />
          <p>Подборка не была выбрана</p>
        </div>
      );
    }

    return paints.map((p) => {
      if (p.color === null) return null;

      return (
        <div key={p.id} className={styles.season} style={formStylesForPaint(p)}>
          <span>#{p.color.hex}</span>
          <br />
          <span>{p.name}</span>
          {renderPaintIcon(p)}
          {renderPaintEffect(p)}
        </div>
      );
    });
  };

  const renderContent = () => {
    if (isPending) return <Loader />;

    const style =
      paints.length === 0 ? { justifyContent: "center", height: "calc(100vh - 300px)" } : undefined;

    return (
      <>
        <header className={styles.header}>
          <Select
            value={season}
            className={styles.header_select}
            options={seasons.map((s) => s.name)}
            onChange={changeSeason}
            placeholder='Выберите сезон'
          />
        </header>
        <div className={styles.container} style={style}>
          {renderPaints()}
        </div>
      </>
    );
  };

  return <PageWrapper>{renderContent()}</PageWrapper>;
}

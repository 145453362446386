import cn from "classnames";
import { ReactComponent as ResizeMinIcon } from "assets/iconsBtn/resize_min.svg";
import { ReactComponent as ResizeMaxIcon } from "assets/iconsBtn/resize_max.svg";
import { ReactComponent as EditIcon } from "assets/iconsBtn/edit.svg";
import { ReactComponent as ShareIcon } from "assets/iconsBtn/share.svg";
import { ReactComponent as DeleteIcon } from "assets/iconsBtn/delete.svg";
import { ReactComponent as CloseIcon } from "assets/iconsBtn/close.svg";
import { ReactComponent as PaintIcon } from "assets/iconsBtn/paint.svg";
import { ReactComponent as ArrowLeft } from "assets/iconsBtn/arrow_left.svg";
import { ReactComponent as ArrowRight } from "assets/iconsBtn/arrow_right.svg";
import { ReactComponent as PlusIcon } from "assets/iconsBtn/plus.svg";
import { ReactComponent as MinusIcon } from "assets/iconsBtn/minus.svg";
import { ReactComponent as EyeIcon } from "assets/iconsBtn/eye.svg";
import { ReactComponent as AboutIcon } from "assets/iconsBtn/about.svg";
import { ReactComponent as HideEyeIcon } from "assets/iconsBtn/hideEye.svg";
import styles from "./buttonIcon.module.scss";

export const imgCollection = {
  resizeMin: { img: <ResizeMinIcon /> },
  resizeMax: { img: <ResizeMaxIcon /> },
  edit: { img: <EditIcon /> },
  share: { img: <ShareIcon /> },
  delete: { img: <DeleteIcon /> },
  close: { img: <CloseIcon /> },
  paint: { img: <PaintIcon /> },
  arrow_right: { img: <ArrowLeft /> },
  arrow_left: { img: <ArrowRight /> },
  plus: { img: <PlusIcon /> },
  minus: { img: <MinusIcon /> },
  eye: { img: <EyeIcon /> },
  about: { img: <AboutIcon /> },
  hideEye: { img: <HideEyeIcon /> },
};

interface ButtonIconProps {
  iconType: keyof typeof imgCollection;
  onClick?: () => void;
  className?: string;
  text?: string;
  type?: "submit" | "button";
  title?: string;
  disabled?: boolean;
}

function ButtonIcon({
  iconType,
  onClick,
  className,
  text,
  type,
  title,
  disabled,
}: ButtonIconProps) {
  return (
    <button
      disabled={disabled}
      title={title}
      type={type}
      className={cn(styles.ButtonIcon_root, className)}
      onClick={onClick}
    >
      {imgCollection[iconType].img}
      {text && <p>{text}</p>}
    </button>
  );
}

export { ButtonIcon };

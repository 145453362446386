import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, Outlet } from "react-router-dom";
import { Table } from "components/Table";
import { ReactComponent as Plus } from "assets/iconsBtn/plus.svg";
import { Button, ButtonIcon } from "components";
import { useUsers } from "hooks";
import { Id } from "types";
import { DownloadUsers } from "features/admin";
import { Header } from "../components";
import styles from "../styles.module.scss";

function UsersPage() {
  const navigate = useNavigate();
  const { users, pending, pagination, requireUsersByPage, clear } = useUsers();

  useEffect(() => {
    requireUsersByPage();

    return () => {
      clear();
    };
  }, [clear, requireUsersByPage]);

  const moveToAddUser = () => {
    navigate("/admin/users/add");
  };

  const moveToEditUser = (id: Id) => {
    navigate(`/admin/users/${id}/edit`);
  };

  const moveToRemoveUser = (id: Id) => {
    navigate(`/admin/users/${id}/remove`);
  };

  const moveToAboutUser = (id: Id) => {
    navigate(`/admin/users/${id}/about`);
  };

  const onPageChange = ({ selected }) => {
    requireUsersByPage(selected + 1);
  };

  return (
    <>
      <Header
        title='Пользователи'
        uploadCVSFile={<DownloadUsers />}
        addEntityFeature={
          <Button onClick={moveToAddUser} className={styles.button}>
            <Plus /> Добавить пользователя
          </Button>
        }
      />
      <Table
        headers={["ID", "E-mail", "Телефон", "ФИО"]}
        values={users}
        fields={["id", "email", "phone", "name"]}
        widthValues={[57, 300, 170, 400]}
        editable
        removable
        editCallback={moveToEditUser}
        removeCallback={moveToRemoveUser}
        additionalFeatures={[
          {
            id: 1,
            component: <ButtonIcon className={styles.addUser_actions_btn} iconType='about' />,
            width: 46,
            callback: moveToAboutUser,
          },
        ]}
        pending={pending}
        paginate
        pageCount={pagination.count_pages}
        onPageChange={onPageChange}
        currentPage={pagination.current_page}
      />
      <Outlet />
    </>
  );
}

const UsersPageComponent = observer(UsersPage);
export { UsersPageComponent as UsersPage };

import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useManagers } from "hooks/useManagers";
import { AboutEntity } from "../components";

function AboutManagerPage() {
  const { id } = useParams();
  const { getManager } = useManagers();

  if (!id) return null;

  const manager = getManager(id);

  if (!manager) return null;

  return <AboutEntity entity={manager} variant='manager' />;
}

const AboutManagerPageComponent = observer(AboutManagerPage);
export { AboutManagerPageComponent as AboutManagerPage };

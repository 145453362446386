import { Suspense, useState } from "react";
import { useNavigate, Await, useLoaderData } from "react-router-dom";
import classNames from "classnames";
import { Input, Loader, Modal } from "components";
import { Paint, Season } from "types";
import styles from "../styles.module.scss";

function filterPaints(paints: Paint[], keyword: string): Paint[] {
  return paints.filter(
    (p: Paint) =>
      p.color.ncs.toLowerCase().includes(keyword.toLowerCase()) ||
      p.color.hex.toLowerCase().includes(keyword.toLowerCase()),
  );
}

export function AboutSeasonPage() {
  const navigate = useNavigate();
  const { seasonPromise } = useLoaderData() as { seasonPromise: Promise<Season> };
  const [keyword, setKeyword] = useState<string>("");

  const close = () => {
    navigate("/admin/seasons");
  };

  const onChangeKeyword = (value: string) => {
    setKeyword(value);
  };

  return (
    <Modal className={styles.addUser} padding={24} width={726} height={538} open close={close}>
      <Suspense fallback={<Loader />}>
        <h3>Просмотр подборки</h3>
        <Await resolve={seasonPromise}>
          {(season) => (
            <>
              <div className={styles.seasons_nameContainer}>
                <p className={styles.seasons_nameLabel}>Название подборки</p>
                <p className={styles.seasons_name}>{season.name}</p>
              </div>
              <div className={styles.seasons_productsContainer}>
                <p>Продукты подборки</p>
                <Input value={keyword} onChange={onChangeKeyword} placeholder='Поиск...' />
              </div>
              <div className={styles.seasons_products}>
                {filterPaints(season.paints, keyword).map((p: Paint) => (
                  <div
                    className={classNames(styles.seasons_product, {
                      [styles.seasons_productLight]: p.color.sense !== "light",
                    })}
                    key={p.id}
                    style={{ background: `#${p.color.hex}` }}
                  >
                    <p>{p.color.ncs}</p>
                    <p>{p.name}</p>
                    <img
                      loading='lazy'
                      className={styles.seasons_productEffect}
                      src={p.effect.min}
                      alt={p.effect.name}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </Modal>
  );
}

import { ReactNode, useContext } from "react";
import cn from "classnames";
import { ReactComponent as Prev } from "assets/iconsEditor/undo.svg";
import { ReactComponent as Next } from "assets/iconsEditor/next.svg";
import { ReactComponent as Dropper } from "assets/iconsEditor/dropper.svg";
import { ReactComponent as Paint } from "assets/iconsEditor/paint.svg";
import { ReactComponent as Brush } from "assets/iconsEditor/brush.svg";
import { ReactComponent as Eraser } from "assets/iconsEditor/eraser.svg";
import { ReactComponent as Delete } from "assets/iconsBtn/remove.svg";
import { ReactComponent as ZoomIn } from "assets/iconsBtn/zoomIn.svg";
import { ReactComponent as ZoomOut } from "assets/iconsBtn/zoomOut.svg";
import { UploadPhoto } from "features/uploadPhoto";
import { CANVAS_CONTAINER_HEIGHT, TOOLS } from "constants/index";
import { Tool } from "types";
import { DrawContext, HistoryContext, UserPhotoContext } from "contexts";
import { Switcher } from "components";
import styles from "../../pages/EditorPages/PresetPage/Sections/sections.module.scss";

const imgEmptyStyles = { height: CANVAS_CONTAINER_HEIGHT, background: "white" };

interface DecorateSectionProps {
  variant: "preset" | "custom";
  drawFeature: ReactNode | null;
  downloadFeature: ReactNode | null;
  configureBrushFeature?: ReactNode | null;
  configureEraserFeature?: ReactNode | null;
  expandToFullScreenFeature: ReactNode | null;
  switchTimeOnDayFeature?: ReactNode | null;
  zoomFeatures?: ReactNode[];
  setTab?: (tab: number) => void;
}

function DecorateSection({
  variant,
  drawFeature = null,
  downloadFeature = null,
  zoomFeatures = [],
  configureBrushFeature = null,
  configureEraserFeature = null,
  expandToFullScreenFeature = null,
  switchTimeOnDayFeature = null,
  setTab,
}: DecorateSectionProps) {
  const { tool, setTool, changeVisibleHelp, isVisibleHelp, resetAll } = useContext(DrawContext);
  const { img } = useContext(UserPhotoContext);
  const { next, prev, resetSnapshots } = useContext(HistoryContext);

  const changeTool = (t: Tool) => () => {
    if (tool === t) {
      setTool(TOOLS.fillFood);
      return;
    }

    if (t === TOOLS.eyeDropper && setTab) setTab(2);

    setTool(t);
  };

  const resetAllChanges = () => {
    resetAll();
    resetSnapshots();
  };

  const renderActions = () => {
    if (!variant) return null;

    if (variant === "preset") {
      return (
        <div className={styles.WorkPanel_actions}>
          <Prev
            className={cn(styles.WorkPanel_actionsIcon, {
              // [styles.WorkPanel_actionsIcon_active]: history.length > 0,
            })}
            onClick={prev}
          />
          <Next
            className={cn(styles.WorkPanel_actionsIcon, {
              // [styles.WorkPanel_actionsIcon_active]: removedHistoryItemsCount > 0,
            })}
            onClick={next}
          />
          <Delete className={styles.WorkPanel_actionsIcon} onClick={resetAllChanges} />
          <Paint
            onClick={changeTool("fillFood")}
            className={cn(styles.WorkPanel_actionsIcon, {
              [styles.WorkPanel_actionsIconActive]: tool === TOOLS.fillFood,
            })}
          />
          <Dropper
            className={cn(styles.WorkPanel_actionsIcon, {
              [styles.WorkPanel_actionsIconActive]: tool === TOOLS.eyeDropper,
            })}
            onClick={changeTool("eyeDropper")}
          />
          <span className={styles.WorkPanel_actionsIcon}>{downloadFeature}</span>
          {/* <Favor className={styles.WorkPanel_actionsIcon} /> */}
          <ZoomIn
            className={cn(styles.WorkPanel_actionsIcon, {
              [styles.WorkPanel_actionsIconActive]: tool === TOOLS.zoomIn,
            })}
            onClick={changeTool("zoomIn")}
          />
          <ZoomOut
            className={cn(styles.WorkPanel_actionsIcon, {
              [styles.WorkPanel_actionsIconActive]: tool === TOOLS.zoomOut,
            })}
            onClick={changeTool("zoomOut")}
          />
          <Switcher
            className={styles.WorkPanel_switcher}
            checked={isVisibleHelp}
            onChange={changeVisibleHelp}
          />
          <p className={styles.WorkPanel_switcherLabel}>Подсказки</p>
        </div>
      );
    }

    if (variant === "custom" && img) {
      return (
        <div className={styles.WorkPanel_actions}>
          <Delete className={styles.WorkPanel_actionsIcon} onClick={resetAll} />
          <Paint
            onClick={changeTool("fillFood")}
            className={cn(styles.WorkPanel_actionsIcon, {
              [styles.WorkPanel_actionsIconActive]: tool === TOOLS.fillFood,
            })}
          />
          <Brush
            onClick={changeTool("brush")}
            className={cn(styles.WorkPanel_actionsIcon, {
              [styles.WorkPanel_actionsIconActive]: tool === TOOLS.brush,
            })}
            id='tool-brush'
          />
          <Eraser
            onClick={changeTool("eraser")}
            className={cn(styles.WorkPanel_actionsIcon, {
              [styles.WorkPanel_actionsIconActive]: tool === TOOLS.eraser,
            })}
            id='tool-eraser'
          />
          <ZoomIn
            className={cn(styles.WorkPanel_actionsIcon, {
              [styles.WorkPanel_actionsIconActive]: tool === TOOLS.zoomIn,
            })}
            onClick={changeTool("zoomIn")}
          />
          <ZoomOut
            className={cn(styles.WorkPanel_actionsIcon, {
              [styles.WorkPanel_actionsIconActive]: tool === TOOLS.zoomOut,
            })}
            onClick={changeTool("zoomOut")}
          />
          {configureBrushFeature}
          {configureEraserFeature}
          {downloadFeature}
        </div>
      );
    }

    return null;
  };

  const renderDrawFeature = () => {
    if (!drawFeature) return null;

    return drawFeature;
  };

  const renderImg = () => {
    if (variant === "custom") {
      return (
        <div
          className={styles.WorkPanel_workAreaImg}
          style={!img ? { height: CANVAS_CONTAINER_HEIGHT + 48, background: "white" } : undefined}
        >
          {img ? renderDrawFeature() : <UploadPhoto />}
          {img ? <div className={styles.zoom}>{zoomFeatures.map((zf) => zf)}</div> : null}
          {img ? (
            <div className={styles.expandToFullScreen}>{expandToFullScreenFeature}</div>
          ) : null}
        </div>
      );
    }

    return (
      <div className={styles.WorkPanel_workAreaImg} style={imgEmptyStyles}>
        {renderDrawFeature()}
        <div className={styles.switchTimeOfDay}>{switchTimeOnDayFeature}</div>
        <div className={styles.expandToFullScreen}>{expandToFullScreenFeature}</div>
      </div>
    );
  };

  return (
    <div className={styles.WorkPanel}>
      {renderActions()}
      <div className={styles.WorkPanel_workArea}>{renderImg()}</div>
    </div>
  );
}

export default DecorateSection;

import { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Input, ReactSelect, Option } from "components";
import { useOrganisations, useUsers } from "hooks";
import { User } from "types";
import { ToastContext } from "contexts";
import { isError } from "libs";
import styles from "./styles.module.scss";

interface UserToManagerProps {
  close: () => void;
  user: User;
}

interface Values {
  organisation: Option;
  sub_organisation: string;
}

interface Errors {
  organisation: string[];
  organisation_sub: string[];
}

function UserToManager({ close, user }: UserToManagerProps) {
  const { control, register, handleSubmit } = useForm<Values>();
  const { requireOrganisationsByKeyword, pending } = useOrganisations();
  const { toast } = useContext(ToastContext);
  const { toManager, requireUsersByPage } = useUsers();
  const [toManagerPending, setToManagerPending] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<Errors | null>(null);

  const fetchOrganisationsByKeyword = async (keyword: string) => {
    const res = await requireOrganisationsByKeyword(keyword);
    return res.map((o) => ({ value: o.id, label: o.name }));
  };

  const onSubmit: SubmitHandler<Values> = (values) => {
    setToManagerPending(true);

    toast
      .promise(
        toManager({
          id: user.id,
          organisation_id: values.organisation.value,
          organisation_sub: values.sub_organisation,
        }),
        {
          pending: "Перевод в менеджера...",
          success: "Пользователь успешно переведён!",
          error: "Ошибка при переводе пользователя!",
        },
      )
      .then(() => {
        requireUsersByPage();
        navigate("/admin/users");
      })
      .catch((e) => {
        if (isError(e) && e.response) {
          setErrors(e.response.data.error);
        }
      })
      .finally(() => {
        setToManagerPending(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ReactSelect
        error={errors?.organisation?.toString()}
        required
        label='Наименование организации'
        placeholder='Поиск...'
        name='organisation'
        control={control}
        isAsync
        pending={pending}
        callback={fetchOrganisationsByKeyword}
      />
      <Input
        error={errors?.organisation_sub?.toString()}
        register={register}
        name='sub_organisation'
        placeholder='Введите подразделение'
        label='Подразделение'
      />
      <div className={styles.actions}>
        <Button disabled={toManagerPending} type='submit'>
          Перевести
        </Button>
        <Button className={styles.cancel} onClick={close}>
          Отмена
        </Button>
      </div>
    </form>
  );
}

export { UserToManager };

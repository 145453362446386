import { ReactNode } from "react";
import cn from "classnames";
import styles from "./button.module.scss";

interface ButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  children: ReactNode;
}

function Button({
  children,
  className,
  disabled,
  type = "button",
  onClick,
}: ButtonProps): JSX.Element {
  return (
    <button
      className={cn(styles.Button_root, className)}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export { Button };

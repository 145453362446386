import { useContext, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { Input, PageWrapper, Select, Loader, Button } from "components";
import { ColorsContext } from "contexts";
import { useColors } from "libs";
import { Brand, Product, Collection, Effect, Color } from "types";
import { ReactComponent as EmptyColorsIcon } from "assets/icons/empty_colors.svg";
import { filterColorsByNameOrCode } from "./helpers";
import styles from "./colourspage.module.scss";

function ColoursPage() {
  const {
    brands,
    products,
    collections,
    effects,
    effect,
    brand,
    collection,
    product,
    colors,
    isPending,
    resetFilters,
    setColors,
    colorsParams,
  } = useContext(ColorsContext);
  const [keyword, setKeyword] = useState("");
  const { changeBrand, changeProduct, changeCollection, changeEffect, requireColor } = useColors();
  const activeEffect = useMemo(
    () => effects.find((e: Effect) => e.name === effect),
    [effects, effect],
  );

  useEffect(() => {
    resetFilters();
    requireColor();

    return () => {
      setColors([]);
    };
  }, [requireColor, resetFilters, setColors]);

  const changeKeyword = (value: string) => {
    setKeyword(value);
  };

  const clearSearchResults = () => {
    setKeyword("");
    requireColor(colorsParams);
  };

  const renderColors = () => {
    const filteredColorsByNameOrCode: Color[] = filterColorsByNameOrCode(colors, keyword);

    if (filteredColorsByNameOrCode.length === 0) {
      return (
        <div className={styles.emptyColors}>
          <EmptyColorsIcon />
          <p>Поиск не дал результатов</p>
          <Button onClick={clearSearchResults}>Сбросить поиск</Button>
        </div>
      );
    }

    return filteredColorsByNameOrCode.map((c: Color) => (
      <Link key={c.id} to={`/colours/${c.id}`} style={{ position: "relative" }}>
        {activeEffect ? (
          <img
            loading='lazy'
            className={styles.colorEffect}
            src={activeEffect.min}
            alt={effect.name}
          />
        ) : null}
        <div
          className={cn(styles.ColoursPage_item, {
            [styles.ColoursPage_itemLigth]: c.sense !== "light",
          })}
          style={{ background: `#${c.hex}` }}
        >
          <div>
            <span className={styles.ColoursPage_item__code}>#{c.hex}</span>
          </div>
        </div>
      </Link>
    ));
  };

  return (
    <PageWrapper>
      <div className={styles.ColoursPage_root}>
        <div className={styles.ColoursPage_filters}>
          <div className={styles.ColoursPage_filters__section}>
            <Input
              placeholder='Название или код'
              className={styles.RegistrationPage_input}
              icon='search'
              value={keyword}
              onChange={changeKeyword}
            />
          </div>
          <div className={styles.ColoursPage_filters__section}>
            <Select
              value={brand}
              options={["Все бренды", ...brands.map((b: Brand) => b.name)]}
              className={styles.ColoursPage_filters__sectionSelect}
              placeholder='Все бренды'
              onChange={changeBrand}
            />
            <Select
              value={product}
              options={["Все продукты", ...products.map((p: Product) => p.name)]}
              className={styles.ColoursPage_filters__sectionSelect}
              placeholder='Все продукты'
              onChange={changeProduct}
            />
            <Select
              value={collection}
              options={["Все коллекции", ...collections.map((c: Collection) => c.name)]}
              placeholder='Все коллекции'
              className={styles.ColoursPage_filters__sectionSelect}
              onChange={changeCollection}
            />
            <Select
              value={effect}
              options={["Без эффекта", ...effects.map((e: Effect) => e.name)]}
              placeholder='Без эффекта'
              className={styles.ColoursPage_filters__sectionSelect}
              onChange={changeEffect}
            />
          </div>
        </div>
        <div className={styles.ColoursPage_pallete}>{isPending ? <Loader /> : renderColors()}</div>
      </div>
    </PageWrapper>
  );
}

export default ColoursPage;

import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "components";
import { useOrganisations } from "hooks";
import { ToastContext } from "contexts";
import styles from "./styles.module.scss";

function RemoveOrganisation() {
  const { id } = useParams();
  const [pending, setPending] = useState(false);
  const { getOrganisation, removeOrganisation } = useOrganisations();
  const { toast } = useContext(ToastContext);
  const navigate = useNavigate();

  const onClick = () => {
    if (!id) throw new Error("id is required");

    const organisation = getOrganisation(id);

    if (!organisation) throw new Error("organisation was not found");

    setPending(true);

    toast
      .promise(removeOrganisation(id), {
        pending: "Удаление организации...",
        success: "Организация успешно удалена!",
        error: "Ошибка при удалении организации!",
      })
      .then(() => {
        navigate("/admin/organisations");
      })
      .catch(() => {
        navigate(`/admin/organisations/${id}/remove/denied`);
      })
      .finally(() => {
        setPending(false);
      });
  };

  const cancel = () => {
    navigate("/admin/organisations");
  };

  return (
    <div className={styles.actions} style={{ justifyContent: "center" }}>
      <Button disabled={pending} onClick={onClick}>
        Удалить
      </Button>
      <Button onClick={cancel} className={styles.cancel}>
        Отмена
      </Button>
    </div>
  );
}

export { RemoveOrganisation };

import { useContext } from "react";
import { useParams } from "react-router-dom";
import { Select } from "components";
import { ColorsContext, DrawContext } from "contexts";
import { TOOLS } from "constants/index";
import { useColors } from "libs";
import { Brand, Collection, Effect, Product } from "types";
import styles from "./colourpage.module.scss";

interface ColorDescriptionProps {
  isInsideModal?: boolean;
}

function ColorDescription({ isInsideModal }: ColorDescriptionProps) {
  const { colourName } = useParams();
  const {
    brand,
    product,
    collection,
    effect,
    brands,
    products,
    collections,
    effects,
    paint,
    userColors,
    setUserColors,
    resetFilters,
    isPending,
    resetOtherColors,
    setPaint,
  } = useContext(ColorsContext);
  const { changeEffect, changeCollection, changeProduct, changeBrand, requireColor } = useColors();
  const { setTool, changeColor } = useContext(DrawContext);

  const addPaintToSelected = () => {
    if (!userColors.some((uc) => uc.color.id === paint.color.id)) {
      setUserColors([...userColors, paint]);
      setTool(TOOLS.fillFood);
      changeColor(paint.color);
      setPaint(paint);
    }
  };

  const renderPaintInfo = () => {
    if (!paint) return null;

    const foundPaint = userColors.find((uc) => uc.color.id === paint.color.id);

    return (
      <>
        <div className={styles.ColourPage_info__item}>
          <p>Тип краски</p>
          <span>{paint?.decoration}</span>
        </div>
        <div className={styles.ColourPage_info__item}>
          <p>Название краски</p>
          <span>{paint?.name}</span>
        </div>
        <div className={styles.ColourPage_info__item}>
          <p>Описание</p>
          <span className={styles.ColourPage_info__itemText}>{paint?.description}</span>
        </div>
        <button onClick={addPaintToSelected} className={styles.palette__link}>
          {foundPaint ? "Добавлен" : "Добавить в выбранные"}
        </button>
      </>
    );
  };

  const handleReset = () => {
    resetFilters();

    if (colourName) {
      requireColor({ color: colourName });
    } else {
      requireColor();
    }

    if (isInsideModal) {
      resetOtherColors();
    }
  };

  const renderContent = () => (
    <>
      <button className={styles.clearFilters} onClick={handleReset}>
        Очистить
      </button>
      <div className={styles.ColourPage_info__item}>
        <p>Бренд</p>
        <Select
          headerClassName={styles.textureHeader}
          nameClassName={styles.textureName}
          value={brand}
          options={["Все бренды", ...brands.map((b: Brand) => b.name)]}
          placeholder='Все бренды'
          onChange={changeBrand}
          disabled={isPending}
        />
      </div>
      <div className={styles.ColourPage_info__item}>
        <p>Материал (продукт)</p>
        <Select
          headerClassName={styles.textureHeader}
          nameClassName={styles.textureName}
          value={product}
          options={["Все продукты", ...products.map((b: Product) => b.name)]}
          placeholder='Все продукты'
          onChange={changeProduct}
          disabled={isPending}
        />
      </div>
      <div className={styles.ColourPage_info__item}>
        <p>Коллекция</p>
        <Select
          headerClassName={styles.textureHeader}
          nameClassName={styles.textureName}
          value={collection}
          options={["Все коллекции", ...collections.map((c: Collection) => c.name)]}
          placeholder='Все коллекции'
          onChange={changeCollection}
          disabled={isPending}
        />
      </div>
      <div className={styles.ColourPage_info__item}>
        <p>Эффект</p>
        <Select
          value={effect}
          headerClassName={styles.textureHeader}
          nameClassName={styles.textureName}
          placeholder='Без эффекта'
          options={["Без эффекта"].concat(effects.map((t: Effect) => t.name))}
          onChange={changeEffect}
          withBoxShadow={false}
          disabled={isPending}
        />
      </div>
      {renderPaintInfo()}
    </>
  );

  return <div className={styles.ColourPage_info}>{renderContent()}</div>;
}

export { ColorDescription };

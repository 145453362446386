import { AuthHeader } from "pages/AdminPages/components";
import Footer from "components/PageWrapper/Footer";
import { EnterCode } from "features/admin";
import styles from "../styles.module.scss";

export function CodePage() {
  return (
    <div className={styles.auth}>
      <AuthHeader />
      <p className={styles.auth_title}>Введите код</p>
      <EnterCode className={styles.auth_form} />
      <Footer />
    </div>
  );
}

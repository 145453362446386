import { useCallback, useContext, useEffect, useState } from "react";
import { getColors } from "services";
import { ColorsContext } from "contexts";
import { Color } from "types";

function useRequireColors() {
  const {
    setColors,
    setCollections,
    setProducts,
    setEffects,
    setBrands,
    setPaint,
    setIsPending,
    colorsParams,
  } = useContext(ColorsContext);

  useEffect(() => {
    setIsPending(true);

    getColors(colorsParams)
      .then((res) => {
        setColors(res.colors);
        setBrands(res.filters.brands);
        setCollections(res.filters.collections);
        setProducts(res.filters.products);
        setEffects(res.filters.effects);
        setPaint(res.paint);
      })
      .finally(() => {
        setIsPending(false);
      });
  }, [
    colorsParams,
    setBrands,
    setCollections,
    setColors,
    setEffects,
    setPaint,
    setProducts,
    setIsPending,
  ]);
}

function useColors() {
  const {
    setColors,
    setCollections,
    setProducts,
    setEffects,
    setBrands,
    setColorsParams,
    setPaint,
    setIsPending,
    colorsParams,
    setBrand,
    brands,
    setProduct,
    products,
    setCollection,
    collections,
    setEffect,
    effects,
  } = useContext(ColorsContext);

  const requireColor = useCallback(
    async (params?) => {
      setIsPending(true);

      const res = await getColors(params).finally(() => {
        setColorsParams(params);
        setIsPending(false);
      });

      setColors(res.colors);
      setBrands(res.filters.brands);
      setCollections(res.filters.collections);
      setProducts(res.filters.products);
      setEffects(res.filters.effects);

      if (res.paint) setPaint(res.paint);

      return res;
    },
    [
      setBrands,
      setCollections,
      setColors,
      setColorsParams,
      setEffects,
      setIsPending,
      setPaint,
      setProducts,
    ],
  );

  const changeBrand = (value) => {
    setBrand(value);

    if (value === "Все бренды") {
      const newParams = { ...colorsParams };

      delete newParams.brandId;

      requireColor(newParams);
    } else {
      const brand = brands.find((b) => b.name === value);

      if (!brand) return;

      requireColor({ ...colorsParams, brandId: brand.id });
    }
  };

  const changeProduct = (value) => {
    setProduct(value);

    if (value === "Все продукты") {
      const newParams = { ...colorsParams };

      delete newParams.productId;

      requireColor(newParams);
    } else {
      const product = products.find((p) => p.name === value);

      if (!product) return;

      requireColor({ ...colorsParams, productId: product.id });
    }
  };

  const changeCollection = (value) => {
    setCollection(value);

    if (value === "Все коллекции") {
      const newParams = { ...colorsParams };

      delete newParams.collectionId;

      requireColor(newParams);
    } else {
      const collection = collections.find((c) => c.name === value);

      if (!collection) return;

      requireColor({ ...colorsParams, collectionId: collection.id });
    }
  };

  const changeEffect = (value) => {
    setEffect(value);

    if (value === "Без эффекта") {
      const newParams = { ...colorsParams };

      delete newParams.effect;

      requireColor(newParams);
    } else {
      const effect = effects.find((e) => e.name === value);

      if (!effect) return;

      requireColor({ ...colorsParams, effect: effect.id });
    }
  };

  return { requireColor, changeBrand, changeProduct, changeCollection, changeEffect };
}

function useOtherColors() {
  const [isColorsPending, setIsColorsPending] = useState(false);
  const {
    requireAccentColors,
    requireComplimentaryColors,
    requireSimilarColors,
    accentColors,
    complimentaryColors,
    similarColors,
  } = useContext(ColorsContext);

  const requireOtherColors = useCallback(
    (color: Color) => {
      if (!color) return;

      setIsColorsPending(true);

      const accentColorsPromise = requireAccentColors(color);
      const complimentaryColorsPromise = requireComplimentaryColors(color);
      const similarColorsPromise = requireSimilarColors(color);

      Promise.allSettled<[Color[], Color[], Color[]]>([
        accentColorsPromise,
        complimentaryColorsPromise,
        similarColorsPromise,
      ]).finally(() => {
        setIsColorsPending(false);
      });
    },
    [requireAccentColors, requireComplimentaryColors, requireSimilarColors],
  );

  return { isColorsPending, requireOtherColors, accentColors, complimentaryColors, similarColors };
}

export { useColors, useRequireColors, useOtherColors };

import { useNavigate } from "react-router-dom";
import { Modal } from "components";
import { EditOrganisation } from "features/admin";
import styles from "../styles.module.scss";

function EditOrganisationPage() {
  const navigate = useNavigate();

  const close = () => {
    navigate("/admin/organisations");
  };

  return (
    <Modal className={styles.addUser} width={430} open close={close}>
      <h3>Редактировать организацию</h3>
      <EditOrganisation />
    </Modal>
  );
}

export { EditOrganisationPage };

import { useContext } from "react";
import { Tooltip } from "react-tooltip";
import cn from "classnames";
import { DrawContext } from "contexts";
import { ReactComponent as Thin1 } from "assets/iconsEditor/thin-1.svg";
import { ReactComponent as Thin2 } from "assets/iconsEditor/thin-2.svg";
import { ReactComponent as Thin3 } from "assets/iconsEditor/thin-3.svg";
import { ReactComponent as Thin4 } from "assets/iconsEditor/thin-4.svg";
import { ERASER_SIZES } from "constants/index";
import { Id } from "types";
import styles from "./styles.module.scss";

interface ConfigureEraserToolProps {
  id: Id;
}

function ConfigureEraserTool({ id }: ConfigureEraserToolProps) {
  const { setEraserThin, eraserThin } = useContext(DrawContext);

  const changeBrushThinByClick = (value: number) => () => {
    setEraserThin(value);
  };

  return (
    <Tooltip opacity={1} className={styles.tooltip} place='bottom' anchorSelect={`${id}`} clickable>
      <div className={styles.tooltip_content}>
        <div className={styles.tooltip_config}>
          <div
            className={cn(styles.tooltip_tool, {
              [styles.tooltip_toolActive]: eraserThin === ERASER_SIZES[7],
            })}
            onClick={changeBrushThinByClick(ERASER_SIZES[7])}
          >
            <Thin1 />
          </div>
          <div
            className={cn(styles.tooltip_tool, {
              [styles.tooltip_toolActive]: eraserThin === ERASER_SIZES[12],
            })}
            onClick={changeBrushThinByClick(ERASER_SIZES[12])}
          >
            <Thin2 />
          </div>
          <div
            className={cn(styles.tooltip_tool, {
              [styles.tooltip_toolActive]: eraserThin === ERASER_SIZES[20],
            })}
            onClick={changeBrushThinByClick(ERASER_SIZES[20])}
          >
            <Thin3 />
          </div>
          <div
            className={cn(styles.tooltip_tool, {
              [styles.tooltip_toolActive]: eraserThin === ERASER_SIZES[30],
            })}
            onClick={changeBrushThinByClick(ERASER_SIZES[30])}
          >
            <Thin4 />
          </div>
        </div>
      </div>
    </Tooltip>
  );
}

export { ConfigureEraserTool };

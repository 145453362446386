import { useCallback, useState } from "react";
import { AdminPreset, Id, Pagination } from "types";
import { presetsStore } from "stores/presetsStore";
import AdminPresetsService from "services/admin/adminPresetsService";

interface UsePresets {
  presets: AdminPreset[];
  pagination: Pagination | null;
  requirePresetsByPage: (page?: number) => void;
  pending: boolean;
  clear: () => void;
  getPreset: (id: Id) => AdminPreset | undefined;
  updatePresetAllow: (id: Id, organisationsIds: Id[]) => Promise<AdminPreset>;
}

function usePresets(): UsePresets {
  const [pending, setPending] = useState(false);

  const requirePresetsByPage = useCallback((page?: number) => {
    setPending(true);

    AdminPresetsService.requirePresets(page || presetsStore.pagination.current_page)
      .then((res) => {
        presetsStore.presets = res.presets;
        presetsStore.pagination = res.pagination;
      })
      .finally(() => {
        setPending(false);
      });
  }, []);

  const clear = useCallback(() => {
    presetsStore.presets = [];
    presetsStore.pagination = { count_pages: NaN, current_page: 1 };
  }, []);

  const getPreset = (id: Id) => presetsStore.getPreset(id);

  const updatePresetAllow = async (id: Id, organisationsIds: Id[]): Promise<AdminPreset> => {
    const res = await AdminPresetsService.updatePresetAllow(id, organisationsIds);
    presetsStore.updatePreset(res);
    return res;
  };

  return {
    presets: presetsStore.presets,
    pagination: presetsStore.pagination,
    requirePresetsByPage,
    pending,
    clear,
    getPreset,
    updatePresetAllow,
  };
}

export { usePresets };

export const CANVAS_CONTAINER_WIDTH = 1062;
export const CANVAS_CONTAINER_HEIGHT = 664;

export const TOOLS = {
  brush: "brush",
  eraser: "eraser",
  fillFood: "fillFood",
  zoomIn: "zoomIn",
  zoomOut: "zoomOut",
  eyeDropper: "eyeDropper",
};

export const IMAGE_TYPE = {
  interior: "Внутренняя отделка",
  exterior: "Внешняя отделка",
};

export const BRUSH_SIZES = { 7: 7, 10: 10, 15: 15, 20: 20 };
export const ERASER_SIZES = { 7: 7, 12: 12, 20: 20, 30: 30 };
export const APP = "idg-deco";
export const PAGINATION_COUNT = 11;
// @ts-ignore
export const BASE_API_URL = window.__CONFIG__.REACT_APP_API;

import { useContext } from "react";
import { ReactComponent as Share } from "assets/iconsEditor/share.svg";
import { DrawContext } from "contexts";
import { downloadImg } from "libs/downloadImg";
import styles from "./downloadUserPhoto.module.scss";

function DownloadUserPhoto() {
  const { stageRef } = useContext(DrawContext);

  const handleClick = () => {
    if (!stageRef.current) return;

    stageRef.current
      .toImage({
        mimeType: "image/jpeg",
        quality: 1,
        imageSmoothingEnabled: true,
        pixelRatio: 2,
      })
      .then(async (res) => {
        await downloadImg(res.src, "Untitled.jpeg");
      });
  };

  return <Share className={styles.icon} title='Скачать' onClick={handleClick} />;
}

export { DownloadUserPhoto };

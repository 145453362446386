import { makeAutoObservable, observable } from "mobx";
import { toJS } from "libs";
import { Season, Pagination } from "types";

class SeasonsStore {
  private _seasons = observable<Season>([]);

  private _pagination = observable<Pagination>({ count_pages: NaN, current_page: 1 });

  constructor() {
    makeAutoObservable(this);
  }

  get seasons() {
    return toJS(this._seasons);
  }

  set seasons(seasons: Season[]) {
    this._seasons = observable(seasons);
  }

  get pagination() {
    return toJS(this._pagination);
  }

  set pagination(pagination: Pagination) {
    this._pagination = observable(pagination);
  }
}

const seasonsStore = new SeasonsStore();
export { seasonsStore };

import { createContext, useCallback, useReducer, useState } from "react";
import { getAccentColors, getComplimentaryColors, getSimilarColors } from "services";
import { Brand, Collection, Color, Effect, Id, Product } from "types";
import { sortAsc } from "libs";

const ColorsContext = createContext(Object.create(null));

interface State {
  colors: Color[];
  collections: Collection[];
  userColors: Color[];
  brands: Brand[];
  eyeDropperColors: any[];
  brand: string;
  collection: string;
  product: string;
  products: Product[];
  effects: Effect[];
  effect: string;
  colorsParams: any;
  paint: any;
  accentColors: Color[];
  complimentaryColors: Color[];
  similarColors: Color[];
}

const initialState: State = {
  colors: [],
  collections: [],
  userColors: [],
  brands: [],
  eyeDropperColors: [],
  brand: "Все бренды",
  collection: "Все коллекции",
  product: "Все продукты",
  products: [],
  effects: [],
  effect: "Без эффекта",
  colorsParams: null,
  paint: null,
  accentColors: [],
  complimentaryColors: [],
  similarColors: [],
};

type Action =
  | { type: "setColors"; payload: Color[] }
  | { type: "setCollections"; payload: Collection[] }
  | { type: "setUserColors"; payload: Color[] }
  | { type: "setBrands"; payload: Brand[] }
  | { type: "setEyeDropperColor"; payload: any }
  | { type: "setBrand"; payload: string }
  | { type: "setCollection"; payload: string }
  | { type: "setProducts"; payload: Product[] }
  | { type: "setEffects"; payload: Effect[] }
  | { type: "setProduct"; payload: string }
  | { type: "setEffect"; payload: string }
  | { type: "setColorsParams"; payload: any }
  | { type: "setPaint"; payload: any }
  | { type: "resetFilters" }
  | { type: "resetUserColors" }
  | { type: "setAccentColors"; payload: Color[] }
  | { type: "setComplimentaryColors"; payload: Color[] }
  | { type: "setSimilarColors"; payload: Color[] };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "setColors":
      return { ...state, colors: action.payload };

    case "setCollections":
      return { ...state, collections: sortAsc(action.payload, "name") };

    case "setUserColors":
      return { ...state, userColors: action.payload };

    case "setBrands":
      return { ...state, brands: sortAsc(action.payload, "name") };

    case "setEyeDropperColor":
      return { ...state, eyeDropperColors: action.payload };

    case "setBrand":
      return { ...state, brand: action.payload };

    case "setCollection":
      return { ...state, collection: action.payload };

    case "setProducts":
      return { ...state, products: sortAsc(action.payload, "name") };

    case "setEffects":
      return { ...state, effects: sortAsc(action.payload, "name") };

    case "setProduct":
      return { ...state, product: action.payload };

    case "setEffect":
      return { ...state, effect: action.payload };

    case "setColorsParams":
      return { ...state, colorsParams: action.payload };

    case "setPaint":
      return { ...state, paint: action.payload };

    case "resetFilters":
      return {
        ...state,
        brands: initialState.brands,
        brand: initialState.brand,
        collection: initialState.collection,
        product: initialState.product,
        products: initialState.products,
        effect: initialState.effect,
        effects: initialState.effects,
        paint: initialState.paint,
        colorsParams: initialState.colorsParams,
      };

    case "resetUserColors":
      return { ...state, userColors: initialState.userColors };

    case "setAccentColors":
      return { ...state, accentColors: action.payload };

    case "setComplimentaryColors":
      return { ...state, complimentaryColors: action.payload };

    case "setSimilarColors":
      return { ...state, similarColors: action.payload };

    default:
      return { ...state };
  }
};

function ColorsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isPending, setIsPending] = useState(true);

  const setColors = useCallback((colors: Color[]) => {
    dispatch({ type: "setColors", payload: colors });
  }, []);

  const setUserColors = useCallback((userColors: Color[]) => {
    dispatch({ type: "setUserColors", payload: userColors });
  }, []);

  const setCollections = useCallback((collections: Collection[]) => {
    dispatch({ type: "setCollections", payload: collections });
  }, []);

  const getColor = useCallback(
    (id: Id) => {
      const color = state.colors.find((c) => c.id === id);

      if (!color) {
        return state.accentColors
          .concat(state.complimentaryColors)
          .concat(state.similarColors)
          .find((c) => c.id === id);
      }

      return color;
    },
    [state.colors, state.accentColors, state.complimentaryColors, state.similarColors],
  );

  const requireAccentColors = useCallback(async (color: Color) => {
    const res = await getAccentColors(color.hex);
    dispatch({ type: "setAccentColors", payload: res });
  }, []);

  const requireComplimentaryColors = useCallback(async (color: Color) => {
    const res = await getComplimentaryColors(color.hex);
    dispatch({ type: "setComplimentaryColors", payload: res });
  }, []);

  const requireSimilarColors = useCallback(async (color: Color) => {
    const res = await getSimilarColors(color.hex);
    dispatch({ type: "setSimilarColors", payload: res });
  }, []);

  const setEyeDropperColors = useCallback((eyeDropperColors) => {
    dispatch({ type: "setEyeDropperColor", payload: eyeDropperColors });
  }, []);

  const setBrand = useCallback((brand: string) => {
    dispatch({ type: "setBrand", payload: brand });
  }, []);

  const setCollection = useCallback((collection: string) => {
    dispatch({ type: "setCollection", payload: collection });
  }, []);

  const setProducts = useCallback((products: Product[]) => {
    dispatch({ type: "setProducts", payload: products });
  }, []);

  const setEffects = useCallback((effects: Effect[]) => {
    dispatch({ type: "setEffects", payload: effects });
  }, []);

  const setProduct = useCallback((product: string) => {
    dispatch({ type: "setProduct", payload: product });
  }, []);

  const setEffect = useCallback((effect: string) => {
    dispatch({ type: "setEffect", payload: effect });
  }, []);

  const setBrands = useCallback((brands: Brand[]) => {
    dispatch({ type: "setBrands", payload: brands });
  }, []);

  const setColorsParams = useCallback((colorsParams) => {
    dispatch({ type: "setColorsParams", payload: colorsParams });
  }, []);

  const setPaint = useCallback((paint) => {
    dispatch({ type: "setPaint", payload: paint });
  }, []);

  const resetFilters = useCallback(() => {
    dispatch({ type: "resetFilters" });
  }, []);

  const resetUserColors = useCallback(() => {
    dispatch({ type: "resetUserColors" });
  }, []);

  const resetOtherColors = useCallback(() => {
    dispatch({ type: "setAccentColors", payload: [] });
    dispatch({ type: "setComplimentaryColors", payload: [] });
    dispatch({ type: "setSimilarColors", payload: [] });
  }, []);

  return (
    <ColorsContext.Provider
      value={{
        colors: state.colors,
        setColors,
        getColor,
        collections: state.collections,
        setCollections,
        userColors: state.userColors,
        setUserColors,
        requireAccentColors,
        requireComplimentaryColors,
        requireSimilarColors,
        brands: state.brands,
        eyeDropperColors: state.eyeDropperColors,
        setEyeDropperColors,
        isPending,
        setIsPending,
        brand: state.brand,
        setBrand,
        collection: state.collection,
        setCollection,
        products: state.products,
        setProducts,
        effects: state.effects,
        setEffects,
        product: state.product,
        setProduct,
        effect: state.effect,
        setEffect,
        setBrands,
        colorsParams: state.colorsParams,
        setColorsParams,
        paint: state.paint,
        setPaint,
        resetFilters,
        resetUserColors,
        accentColors: state.accentColors,
        complimentaryColors: state.complimentaryColors,
        similarColors: state.similarColors,
        resetOtherColors,
      }}
    >
      {children}
    </ColorsContext.Provider>
  );
}

export { ColorsContext, ColorsProvider };

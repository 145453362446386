import { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Table, ButtonIcon, Loader } from "components";
import { Id } from "types";
import { RestoreSeason } from "features/admin";
import { useAdminSeasons } from "hooks";
import { Header } from "../components";
import styles from "../styles.module.scss";

function SeasonsPage() {
  const navigate = useNavigate();
  const { requireSeasons, seasons, pagination, clear, isPending } = useAdminSeasons();

  useEffect(() => {
    requireSeasons();

    return () => {
      clear();
    };
  }, [requireSeasons, clear]);

  const moveToRemoveSeason = (id: Id) => {
    navigate(`/admin/seasons/${id}/remove`);
  };

  const onPageChange = ({ selected }) => {
    requireSeasons(selected + 1);
  };

  const moveToAboutSeason = (id: Id) => {
    navigate(`/admin/seasons/${id}/about`);
  };

  const restoreSeasonCallback = (id: Id) => <RestoreSeason id={id} />;

  return (
    <Suspense fallback={<Loader />}>
      <Header title='Сезонные подборки' />
      <Table
        headers={["ID", "Название", "Продукты"]}
        values={seasons.map(({ id, name, paint_names, deleted_at }) => ({
          id,
          name,
          paint_names,
          deleted_at,
        }))}
        deleteAtFieldName='deleted_at'
        fields={["id", "name", "paint_names"]}
        widthValues={[57, 200, 400]}
        paginate
        pageCount={pagination.count_pages}
        currentPage={pagination.current_page}
        onPageChange={onPageChange}
        removable
        removableIcon='eye'
        removeCallback={moveToRemoveSeason}
        additionalFeatures={[
          {
            id: 1,
            component: <ButtonIcon className={styles.addUser_actions_btn} iconType='about' />,
            width: 46,
            callback: moveToAboutSeason,
          },
        ]}
        restoreEntityCallback={restoreSeasonCallback}
        pending={isPending}
      />
      <Outlet />
    </Suspense>
  );
}

const SeasonsPageComponent = observer(SeasonsPage);
export { SeasonsPageComponent as SeasonsPage };

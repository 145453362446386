import { makeAutoObservable, observable } from "mobx";
import { Id, Organisation, Pagination } from "types";
import { toJS } from "libs";

class OrganisationsStore {
  private _organisations = observable<Organisation>([]);

  private _pagination: Pagination = { current_page: 1, count_pages: NaN };

  constructor() {
    makeAutoObservable(this);
  }

  get organisations(): Organisation[] {
    return toJS(this._organisations);
  }

  set organisations(organisations: Organisation[]) {
    this._organisations = observable(organisations);
  }

  get pagination(): Pagination {
    return toJS(this._pagination);
  }

  set pagination(pagination) {
    this._pagination = pagination;
  }

  getOrganisation(id: Id) {
    const organisation = this._organisations.find((o) => String(o.id) === id);

    if (!organisation) return undefined;

    return organisation;
  }

  updateOrganisation(organisation: Organisation) {
    const index = this._organisations.findIndex((u) => u.id === Number(organisation.id));
    this._organisations.spliceWithArray(index, 1, [organisation]);
  }
}

const organisationsStore = new OrganisationsStore();
export { organisationsStore };

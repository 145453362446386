import { useContext } from "react";
import { Tooltip } from "react-tooltip";
import cn from "classnames";
import Slider from "rc-slider";
import { DrawContext } from "contexts";
import { ReactComponent as Thin1 } from "assets/iconsEditor/thin-1.svg";
import { ReactComponent as Thin2 } from "assets/iconsEditor/thin-2.svg";
import { ReactComponent as Thin3 } from "assets/iconsEditor/thin-3.svg";
import { ReactComponent as Thin4 } from "assets/iconsEditor/thin-4.svg";
import { BRUSH_SIZES } from "constants/index";
import { Id } from "types";
import styles from "./styles.module.scss";

interface ConfigureBrushToolProps {
  id: Id;
}

function ConfigureBrushTool({ id }: ConfigureBrushToolProps) {
  const { setBrushThin, brushThin, setOpacity, opacity } = useContext(DrawContext);

  const changeBrushThinByClick = (value: number) => () => {
    setBrushThin(value);
  };

  const changeBrushOpacity = (value: number | number[]) => {
    setOpacity((value as number) / 100);
  };

  return (
    <Tooltip opacity={1} className={styles.tooltip} place='bottom' anchorSelect={`${id}`} clickable>
      <div className={styles.tooltip_content}>
        <div className={styles.tooltip_config}>
          <div
            className={cn(styles.tooltip_tool, {
              [styles.tooltip_toolActive]: brushThin === BRUSH_SIZES[7],
            })}
            onClick={changeBrushThinByClick(BRUSH_SIZES[7])}
          >
            <Thin1 />
          </div>
          <div
            className={cn(styles.tooltip_tool, {
              [styles.tooltip_toolActive]: brushThin === BRUSH_SIZES[10],
            })}
            onClick={changeBrushThinByClick(BRUSH_SIZES[10])}
          >
            <Thin2 />
          </div>
          <div
            className={cn(styles.tooltip_tool, {
              [styles.tooltip_toolActive]: brushThin === BRUSH_SIZES[15],
            })}
            onClick={changeBrushThinByClick(BRUSH_SIZES[15])}
          >
            <Thin3 />
          </div>
          <div
            className={cn(styles.tooltip_tool, {
              [styles.tooltip_toolActive]: brushThin === BRUSH_SIZES[20],
            })}
            onClick={changeBrushThinByClick(BRUSH_SIZES[20])}
          >
            <Thin4 />
          </div>
        </div>
        <Slider
          onChange={changeBrushOpacity}
          value={opacity * 100}
          min={0}
          max={100}
          styles={{
            handle: { backgroundColor: "white", borderColor: "white", width: 16, height: 16 },
            rail: {
              height: 8,
              background: "linear-gradient(270deg, #90B1CB 0%, rgba(144, 177, 203, 0.00) 100%)",
            },
            track: {
              height: 8,
              borderRadius: 4,
              background: "linear-gradient(270deg, #90B1CB 0%, rgba(144, 177, 203, 0.00) 100%)",
            },
          }}
        />
      </div>
    </Tooltip>
  );
}

export { ConfigureBrushTool };

import { useNavigate } from "react-router-dom";
import { Modal } from "components";
import { AddUser } from "features/admin";
import styles from "../styles.module.scss";

function AddUserPage() {
  const navigate = useNavigate();

  const moveToUsersPage = () => {
    navigate("/admin/users");
  };

  return (
    <Modal open width={430} className={styles.addUser} close={moveToUsersPage}>
      <h3>Добавить пользователя</h3>
      <AddUser close={moveToUsersPage} />
    </Modal>
  );
}

export { AddUserPage };

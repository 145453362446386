import { createContext, ReactNode } from "react";
import { toast, ToastContainer } from "react-toastify";

const ToastContext = createContext<{ toast: typeof toast }>(Object.create(null));

interface ToastProviderProps {
  children: ReactNode;
}

function ToastProvider({ children }: ToastProviderProps) {
  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  );
}

export { ToastContext, ToastProvider };

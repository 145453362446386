import { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";
import cn from "classnames";
import styles from "./styles.module.scss";

interface ModalProps {
  target?: HTMLElement;
  open: boolean;
  close: () => void;
  children: ReactNode;
  width?: number | string;
  padding?: number | string;
  className?: string;
  height?: number | string;
}

function Modal({
  target = document.body,
  open = false,
  close,
  children,
  width,
  padding = 36,
  className = undefined,
  height,
}: ModalProps) {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "initial";
    };
  }, []);

  const handleClose = () => {
    if (!close) return;

    close();
  };

  const renderModal = () => (
    <>
      <div onClick={handleClose} className={styles.background} />
      <div style={{ width, padding, minHeight: height }} className={cn(styles.modal, className)}>
        {children}
        <span className={styles.modal__close} onClick={handleClose}>
          &#x2715;
        </span>
      </div>
    </>
  );

  if (!open) return null;

  return createPortal(renderModal(), target);
}

export { Modal };

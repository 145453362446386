import { createContext, useEffect, useState } from "react";
import { getPresets } from "../services";

const PresetsContext = createContext(Object.create(null));

function PresetsProvider({ children }) {
  const [presets, setPresets] = useState([]);
  const [masks, setMasks] = useState([]);

  useEffect(() => {
    getPresets().then((res) => {
      setPresets(res);
    });
  }, []);

  return (
    <PresetsContext.Provider value={{ presets, masks, saveMasks: setMasks }}>
      {children}
    </PresetsContext.Provider>
  );
}

export { PresetsContext, PresetsProvider };

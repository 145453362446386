import { Id, Manager } from "types";
import { CreateManagerParams } from "services/admin/managersService";

export interface Values extends Pick<Manager, "email" | "name" | "phone" | "id"> {
  organisation: { value: string; label: string };
  organisation_sub: string;
  password: string;
}

function formManager(values: Values, id?: Id): CreateManagerParams {
  const manager: CreateManagerParams = {
    email: values.email,
    name: values.name,
    organisation_id: values.organisation.value,
    organisation_sub: values.organisation_sub,
  };

  if (id) {
    manager.id = id;
  }

  if (values?.phone) {
    manager.phone = values.phone;
  }

  if (values?.password) {
    manager.password = values.password;
  }

  return manager;
}

export { formManager };

import fileDownload from "js-file-download";
import { Id, Manager, Pagination } from "types";
import { privateApi, Response } from "libs";
import { PAGINATION_COUNT } from "constants/index";

export interface CreateManagerParams extends Partial<Manager> {
  password?: string;
  organisation_id?: Id;
  organisation_sub?: string;
}

class ManagersService {
  private static uri = "/admin/managers";

  static async requireManagers(
    page: number,
    limit: number = PAGINATION_COUNT,
  ): Promise<{ managers: Manager[]; pagination: Pagination }> {
    const res = await privateApi.get<{ data: Manager[]; pagination: Pagination }>(
      ManagersService.uri,
      {
        params: { page, limit },
      },
    );
    return { managers: res.data.data, pagination: res.data.pagination };
  }

  static async createManager(manager: CreateManagerParams): Promise<Manager> {
    const res = await privateApi.post<Response<Manager>>(ManagersService.uri, manager);
    return res.data.data;
  }

  static async updateManager(manager: CreateManagerParams): Promise<Manager> {
    const res = await privateApi.put<Response<Manager>>(
      `${ManagersService.uri}/${manager.id}`,
      manager,
    );
    return res.data.data;
  }

  static async removeManagerById(id: Id): Promise<void> {
    await privateApi.delete(`${ManagersService.uri}/${id}`);
  }

  static async requireManagerById(id: Id): Promise<Manager> {
    const res = await privateApi.get<Response<Manager>>(`${ManagersService.uri}/${id}`);
    return res.data.data;
  }

  static async restoreManager(id: Id): Promise<void> {
    await privateApi.put(`${ManagersService.uri}/restore/${id}`);
  }

  static async downloadManagers(): Promise<void> {
    const res = await privateApi.get(`${ManagersService.uri}/export`, { responseType: "blob" });
    fileDownload(res.data, "managers.xlsx");
  }
}

export default ManagersService;

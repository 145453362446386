import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Stage, Layer, Image as ImageLayer, Line } from "react-konva";
import cn from "classnames";
import { TOOLS } from "constants/index";
import { FillFlood } from "libs/fillFlood";
import { useZoom } from "libs/useZoom";
import { DrawContext, UserPhotoContext } from "contexts";
import { convertContoursToFormat } from "./helpers/convertContoursToFormat";
import styles from "./decorateUserPhoto.module.scss";

const layerWidth = 980;
const layerHeight = 664;

function DecorateUserPhoto() {
  const {
    tool,
    color,
    brushThin,
    setLayers,
    stageRef,
    opacity,
    eraserThin,
    isResetAll,
    setIsResetAll,
  } = useContext(DrawContext);
  const { img } = useContext(UserPhotoContext);
  const imgRef = useRef<any>(null);
  const [brushAndEraser, setBrushAndEraser] = useState<any[]>([]);
  const isDrawing = useRef(false);
  const [mainImg, setMainImg] = useState<any>(null);
  const { handleWheel, zoomIn, zoomOut } = useZoom();

  useEffect(() => {
    if (!isResetAll) return;

    setBrushAndEraser([]);

    setIsResetAll(false);
  }, [isResetAll, setIsResetAll]);

  const handleMouseUpOutside = () => {
    isDrawing.current = false;
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUpOutside);

    return () => {
      document.removeEventListener("mouseup", handleMouseUpOutside);
    };
  }, []);

  useEffect(() => {
    if (!img) return;

    const newImg = new Image();

    newImg.onload = () => {
      setMainImg(newImg);
    };

    newImg.src = img;
  }, [img]);

  const renderImageLayer = useMemo(() => {
    if (!img) return null;

    const newImg = new Image();

    newImg.src = img;

    return <ImageLayer ref={imgRef} image={newImg} />;
  }, [img]);

  const handleClickOnStage = useCallback(
    (e) => {
      if (!stageRef.current || !imgRef.current) return;

      if (tool === TOOLS.fillFood) {
        const stage = stageRef.current;
        const pointer = stage.getPointerPosition();
        const oldScale = stage.scaleX();
        const pointerX = (pointer.x - stage.x()) / oldScale;
        const pointerY = (pointer.y - stage.y()) / oldScale;
        const fillFlood = new FillFlood(imgRef.current.getCanvas(), imgRef.current.attrs.image);

        fillFlood.draw({ x: pointerX, y: pointerY }, e.evt);

        setBrushAndEraser((prevState) => [
          ...prevState,
          { tool, color: color.hex, points: convertContoursToFormat(fillFlood.contours()) },
        ]);

        setLayers([...stageRef.current.getLayers()]);
      }

      if (tool === TOOLS.zoomIn) {
        zoomIn();
      }

      if (tool === TOOLS.zoomOut) {
        zoomOut();
      }
    },
    [color, setLayers, tool],
  );

  const handleMouseDown = useCallback(
    (e) => {
      if (e.evt.button === 0 && (tool === TOOLS.brush || tool === TOOLS.eraser)) {
        isDrawing.current = true;

        const pos = stageRef.current.getPointerPosition();

        const stage = stageRef.current.getStage();
        const pointer = stage.getPointerPosition();
        const oldScale = stage.scaleX();

        pos.x = (pointer.x - stage.x()) / oldScale;
        pos.y = (pointer.y - stage.y()) / oldScale;

        setBrushAndEraser((prevState) => [
          ...prevState,
          { tool, opacity, color: color.hex, brushThin, eraserThin, points: [pos.x, pos.y] },
        ]);

        setLayers([...stageRef.current.getLayers()]);
      }
    },
    [brushThin, color, eraserThin, opacity, setLayers, tool],
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDrawing.current) return;

      if (e.evt.button === 0 && (tool === TOOLS.brush || tool === TOOLS.eraser)) {
        const stage = stageRef.current;
        const point = stage.getPointerPosition();
        const oldScale = stage.scaleX();

        point.x = (point.x - stage.x()) / oldScale;
        point.y = (point.y - stage.y()) / oldScale;

        setBrushAndEraser((prevState) => {
          const lastLine = prevState[prevState.length - 1];

          lastLine.points = lastLine.points.concat([point.x, point.y]);

          prevState.splice(prevState.length - 1, 1, lastLine);

          return [...prevState.concat()];
        });
      }
    },
    [tool],
  );

  const handleMouseUp = useCallback(
    (e) => {
      if (e.evt.button === 0 && (tool === TOOLS.brush || tool === TOOLS.eraser)) {
        isDrawing.current = false;
      }
    },
    [tool],
  );

  const renderFillFoodLines = useMemo(
    () =>
      brushAndEraser.map((l, i) => (
        <Line
          key={i}
          opacity={l.tool === TOOLS.brush ? l.opacity : undefined}
          points={l.points}
          stroke={l.color}
          strokeWidth={l.tool === TOOLS.brush ? l.brushThin : l.eraserThin}
          lineCap='round'
          lineJoin='round'
          tension={1}
          closed={l.tool === TOOLS.fillFood}
          fill={l.tool === TOOLS.fillFood ? l.color : undefined}
          globalCompositeOperation={l.tool === TOOLS.eraser ? "destination-out" : "source-over"}
        />
      )),
    [brushAndEraser],
  );

  if (mainImg === null) return null;

  return (
    <Stage
      className={cn(styles.container, {
        [styles.fillFloodIcon]: tool === TOOLS.fillFood,
        [styles.brushIcon]: tool === TOOLS.brush,
        [styles.eraserIcon]: tool === TOOLS.eraser,
        [styles.zoomInIcon]: tool === TOOLS.zoomIn,
        [styles.zoomOutIcon]: tool === TOOLS.zoomOut,
      })}
      onClick={handleClickOnStage}
      ref={stageRef}
      width={layerWidth}
      height={layerHeight}
      onMouseDown={handleMouseDown}
      onMousemove={handleMouseMove}
      onMouseup={handleMouseUp}
      onWheel={handleWheel}
      draggable={false}
    >
      <Layer>{renderImageLayer}</Layer>
      <Layer>
        {renderImageLayer}
        {renderFillFoodLines}
      </Layer>
    </Stage>
  );
}

export { DecorateUserPhoto };

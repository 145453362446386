import { createContext, useState } from "react";
import { IMAGE_TYPE } from "../constants";

const UserPhotoContext = createContext(Object.create(null));

function UserPhotoProvider({ children }) {
  const [img, setImg] = useState(null);
  const [imgType, setImgType] = useState(IMAGE_TYPE.interior);

  return (
    <UserPhotoContext.Provider value={{ img, setImg, imgType, setImgType }}>
      {children}
    </UserPhotoContext.Provider>
  );
}

export { UserPhotoContext, UserPhotoProvider };

import { useState, useRef, useEffect } from "react";
import cn from "classnames";
import { ReactComponent as ArrowSelect } from "assets/icons/arrow_dropdown_light.svg";
import styles from "./select.module.scss";

interface SelectProps {
  label?: string;
  className?: string;
  placeholder?: string;
  withBoxShadow?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  headerClassName?: string;
  nameClassName?: string;
  dropdownClassName?: string;
  dropdownItemClassName?: string;
  isGray?: boolean;
  options?: string[];
  disabled?: boolean;
}

function Select({
  label,
  className,
  placeholder,
  withBoxShadow = true,
  value,
  onChange,
  headerClassName,
  nameClassName,
  dropdownClassName,
  dropdownItemClassName,
  isGray = false,
  options = ["option1", "option2", "option3"],
  disabled,
}: SelectProps) {
  const rootEl = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const onClick = (e) => rootEl.current?.contains(e.target) || setOpen(false);
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, []);

  return (
    <div className={cn(styles.Select_root, className)} ref={rootEl} title={value}>
      {label && <p className={styles.Select_label}>{label}</p>}

      <div
        onClick={() => (disabled ? null : setOpen((p) => !p))}
        className={cn(styles.Select_component, headerClassName, {
          [styles.Select_withShadow]: withBoxShadow,
          [styles.Select_gray]: isGray || disabled,
        })}
      >
        <span className={cn(styles.Select_name, nameClassName)}>{value || placeholder}</span>
        {disabled ? null : (
          <ArrowSelect className={cn(styles.Select_arrow, { [styles.Select_open]: isOpen })} />
        )}
      </div>

      {isOpen && (
        <div
          className={cn(styles.Select_dropDown, dropdownClassName, {
            [styles.Select_withShadow]: !withBoxShadow && isGray,
          })}
        >
          {options.map((item) => (
            <div
              className={cn(styles.Select_dropDown__item, dropdownItemClassName)}
              onClick={() => {
                if (!onChange) return;

                onChange(item);
                setOpen(false);
              }}
              key={item}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export { Select };

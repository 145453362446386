import { PageWrapper } from "../../components";
import { UploadPhoto } from "../../features/uploadPhoto";

function SettingsPage() {
  return (
    <PageWrapper>
      <UploadPhoto />
    </PageWrapper>
  );
}

export default SettingsPage;

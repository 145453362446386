import cn from "classnames";
import styles from "./tabs.module.scss";

interface TabsProps {
  className?: string;
  activeTab: number;
  items: { label: string; value: number }[];
  onClick: (value: number) => void;
}

function Tabs({ className, activeTab, items, onClick }: TabsProps) {
  return (
    <div className={cn(styles.Tabs_root, className)}>
      {items.map((i) => (
        <div
          key={i.value}
          className={cn(styles.Tabs_tab, { [styles.Tabs_active]: activeTab === i.value })}
          onClick={() => onClick(i.value)}
        >
          {i.label}
        </div>
      ))}
    </div>
  );
}

export { Tabs };

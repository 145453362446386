import { Id, Organisation, Pagination } from "types";
import { privateApi, Response } from "libs";
import { PAGINATION_COUNT } from "constants/index";

interface RequireOrganisationsParams {
  page: number;
  limit?: number;
  search?: string;
}

class OrganisationsService {
  private static uri = "/admin/organisations";

  static async requireOrganisations({
    page,
    limit = PAGINATION_COUNT,
    search,
  }: RequireOrganisationsParams): Promise<{
    organisations: Organisation[];
    pagination: Pagination;
  }> {
    const res = await privateApi.get<{ data: Organisation[]; pagination: Pagination }>(
      OrganisationsService.uri,
      {
        params: { page, limit, search },
      },
    );
    return { organisations: res.data.data, pagination: res.data.pagination };
  }

  static async createOrganisation(organisation: Omit<Organisation, "id">): Promise<Organisation> {
    const res = await privateApi.post<Response<Organisation>>(
      OrganisationsService.uri,
      organisation,
    );
    return res.data.data;
  }

  static async updateOrganisation(organisation: Partial<Organisation>): Promise<Organisation> {
    const res = await privateApi.put<Response<Organisation>>(
      `${OrganisationsService.uri}/${organisation.id}`,
      organisation,
    );
    return res.data.data;
  }

  static async destroyOrganisation(id: Id): Promise<void> {
    await privateApi.delete(`${OrganisationsService.uri}/${id}`);
  }

  static async requireOrganisationById(id: Id): Promise<Organisation> {
    const res = await privateApi.get<Response<Organisation>>(`${OrganisationsService.uri}/${id}`);
    return res.data.data;
  }
}

export default OrganisationsService;

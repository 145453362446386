import { useNavigate } from "react-router-dom";
import { AddManager } from "features/admin";
import { Modal } from "components";
import styles from "../styles.module.scss";

function AddManagerPage() {
  const navigate = useNavigate();

  const moveToUsersPage = () => {
    navigate("/admin/managers");
  };

  return (
    <Modal open width={430} className={styles.addUser} close={moveToUsersPage}>
      <>
        <h3>Добавить менеджера</h3>
        <AddManager close={moveToUsersPage} />
      </>
    </Modal>
  );
}

export { AddManagerPage };

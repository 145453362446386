import { useCallback, useState } from "react";
import { Id, Pagination, User } from "types";
import { usersStore } from "stores";
import UsersService, {
  CreateUserParams,
  EditUserParams,
  ToManager,
} from "services/admin/usersService";

interface UseUsers {
  users: User[];
  pending: boolean;
  pagination: Pagination;
  requireUsersByPage: (page?: number) => void;
  getUser: (id: Id) => User | undefined;
  editUser: (values: EditUserParams) => Promise<void>;
  deleteUser: (id: Id) => Promise<void>;
  createUser: (values: CreateUserParams) => Promise<void>;
  clear: () => void;
  toManager: (params: ToManager) => Promise<void>;
  downloadUsers: () => Promise<void>;
}

function useUsers(): UseUsers {
  const [pending, setPending] = useState(false);

  const requireUsersByPage = useCallback((page?: number) => {
    setPending(true);

    UsersService.requireUsers(page || usersStore.pagination.current_page)
      .then((res) => {
        usersStore.users = res.users;
        usersStore.pagination = res.pagination;
      })
      .finally(() => {
        setPending(false);
      });
  }, []);

  const getUser = (id: Id) => usersStore.getUser(id);

  const editUser = async (values: EditUserParams) => {
    const res = await UsersService.updateUser(values);
    usersStore.updateUser(res);
  };

  const deleteUser = async (id: Id) => {
    await UsersService.removeUserById(id);
    let page = usersStore.pagination.current_page;

    if (usersStore.users.length === 1) page--;

    const res = await UsersService.requireUsers(page);
    usersStore.users = res.users;
    usersStore.pagination = res.pagination;
  };

  const createUser = async (values: CreateUserParams) => {
    await UsersService.createUser(values);
    const res = await UsersService.requireUsers(usersStore.pagination.current_page);
    usersStore.users = res.users;
    usersStore.pagination = res.pagination;
  };

  const clear = useCallback(() => {
    usersStore.users = [];
    usersStore.pagination = { current_page: 1, count_pages: NaN };
  }, []);

  const toManager = async (params: ToManager) => {
    await UsersService.toManager(params);
  };

  const downloadUsers = async () => {
    await UsersService.downloadUsers();
  };

  return {
    users: usersStore.users,
    pending,
    pagination: usersStore.pagination,
    requireUsersByPage,
    getUser,
    editUser,
    deleteUser,
    createUser,
    clear,
    toManager,
    downloadUsers,
  };
}

export { useUsers };
export type { CreateUserParams, EditUserParams };

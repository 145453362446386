async function downloadImg(src: string, name: string) {
  const response = await fetch(src);
  const blobImage = await response.blob();
  const href = URL.createObjectURL(blobImage);
  const anchorElement = document.createElement("a");

  anchorElement.href = href;
  anchorElement.download = name;

  document.body.appendChild(anchorElement);
  anchorElement.click();

  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
}

export { downloadImg };

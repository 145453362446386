import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import {
  DrawProvider,
  UserPhotoProvider,
  ColorsProvider,
  PresetsProvider,
  ToastProvider,
} from "contexts";
import Router from "constants/routes";
import reportWebVitals from "./reportWebVitals";
import "./global/styles/index.scss";
import "rc-slider/assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <PresetsProvider>
    <ColorsProvider>
      <UserPhotoProvider>
        <DrawProvider>
          <ToastProvider>
            <RouterProvider router={Router} />
          </ToastProvider>
        </DrawProvider>
      </UserPhotoProvider>
    </ColorsProvider>
  </PresetsProvider>,
);
reportWebVitals();

import { ReactNode } from "react";
import styles from "./styles.module.scss";

interface HeaderProps {
  title: string;
  uploadCVSFile?: ReactNode;
  addEntityFeature?: ReactNode;
}

function Header({ title, uploadCVSFile, addEntityFeature }: HeaderProps) {
  return (
    <header className={styles.header}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.actions}>
        {uploadCVSFile}
        {addEntityFeature}
      </div>
    </header>
  );
}

export { Header };

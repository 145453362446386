import { Link, NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as Home } from "assets/icons/home.svg";
import { ReactComponent as Layers } from "assets/icons/layers.svg";
import { ReactComponent as Image } from "assets/icons/images.svg";
import { ReactComponent as Camera } from "assets/icons/camera.svg";
import { ReactComponent as SeasonsIcon } from "assets/icons/seasons_menu.svg";
import styles from "./header.module.scss";

interface HeaderProps {
  withMenu?: boolean;
}

function Header({ withMenu }: HeaderProps) {
  return (
    <div className={styles.Header_root}>
      <Link to='/'>
        <Logo />
      </Link>

      {withMenu && (
        <div className={styles.Header_actions}>
          <div className={styles.Header_navLinks}>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.Header_linkActive : styles.Header_link
              }
              to='/'
            >
              <Home />
              Главная
            </NavLink>
            <NavLink className={styles.Header_link} to='/colours'>
              <Layers />
              Цвета
            </NavLink>
            <NavLink className={styles.Header_link} to='/seasons'>
              <SeasonsIcon />
              Подборки
            </NavLink>
            <NavLink className={styles.Header_link} to='/editor/presets'>
              <Image />
              Интерьер
            </NavLink>
            <NavLink className={styles.Header_link} to='/upload-photo'>
              <Camera />
              Загрузить фото
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;

import { useCallback, useContext, useMemo, useState, MouseEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Loader, Modal } from "components";
import { useColors, useOtherColors } from "libs";
import { Color } from "types";
import { ColorsContext } from "contexts";
import styles from "./colourpage.module.scss";

interface OtherColorsProps {
  isPending: boolean;
  isInsideModal?: boolean;
}

function OtherColors({ isPending, isInsideModal }: OtherColorsProps) {
  const { accentColors, similarColors, complimentaryColors, requireOtherColors } = useOtherColors();
  const { requireColor } = useColors();
  const { colors, setBrand, setCollection, setProduct, setEffect, colorsParams } =
    useContext(ColorsContext);
  const [isOpen, setIsOpen] = useState(false);
  const [activeColor, setActiveColor] = useState<Color | null>(null);
  const navigate = useNavigate();

  const handleClickOnColor = useCallback(
    (c: Color) => (e: MouseEvent<HTMLAnchorElement>) => {
      if (isInsideModal) {
        e.preventDefault();
        requireColor({ ...colorsParams, color: c.id }).then((res) => {
          const clr = res?.colors?.find((cc: Color) => cc.id === c.id);

          if (clr) requireOtherColors(clr);
        });
      }

      const color = colors.find((clr: Color) => c.id === clr.id);

      if (!color) {
        setIsOpen(true);
      }

      setActiveColor(c);
    },
    [isInsideModal, colors, requireColor, colorsParams, requireOtherColors],
  );

  const handleClickOnModal = () => {
    if (activeColor) {
      setBrand("Все бренды");
      setCollection("Все коллекции");
      setProduct("Все продукты");
      setEffect("Без эффекта");

      requireColor({ color: activeColor.id })
        .then((res) => {
          const clr = res?.colors?.find((cc: Color) => cc.id === activeColor.id);

          if (clr) requireOtherColors(clr);
        })
        .finally(() => {
          setIsOpen(false);
        });
    }
  };

  const closeModal = () => {
    navigate(-1);
    setIsOpen(false);
  };

  const renderAccentColors = useMemo(
    () =>
      accentColors.map((c: Color) => (
        <Link title={c.ncs} onClick={handleClickOnColor(c)} key={c.id} to={`/colours/${c.id}`}>
          <div
            className={styles.ColourPage_couples__boxColorsItem}
            style={{ background: `#${c.hex}` }}
          />
        </Link>
      )),
    [accentColors, handleClickOnColor],
  );

  const renderSimilarColors = useMemo(
    () =>
      similarColors.map((c: Color) => (
        <Link title={c.ncs} onClick={handleClickOnColor(c)} key={c.id} to={`/colours/${c.id}`}>
          <div
            className={styles.ColourPage_couples__boxColorsItem}
            style={{ background: `#${c.hex}` }}
          />
        </Link>
      )),
    [handleClickOnColor, similarColors],
  );

  const renderComplimentaryColors = useMemo(
    () =>
      complimentaryColors.map((c: Color) => (
        <Link title={c.ncs} onClick={handleClickOnColor(c)} key={c.id} to={`/colours/${c.id}`}>
          <div
            className={styles.ColourPage_couples__boxColorsItem}
            style={{ background: `#${c.hex}` }}
          />
        </Link>
      )),
    [complimentaryColors, handleClickOnColor],
  );

  return (
    <div className={styles.ColourPage_couples}>
      {isPending ? (
        <Loader />
      ) : (
        <>
          <div className={styles.ColourPage_couples__box}>
            <p className={styles.ColourPage_couples__boxTitle}>Комплиментарные:</p>
            <div className={styles.ColourPage_couples__boxColors}>{renderComplimentaryColors}</div>
          </div>
          <div className={styles.ColourPage_couples__box}>
            <p className={styles.ColourPage_couples__boxTitle}>Акцентные:</p>
            <div className={styles.ColourPage_couples__boxColors}>{renderAccentColors}</div>
          </div>
          <div className={styles.ColourPage_couples__box}>
            <p className={styles.ColourPage_couples__boxTitle}>Похожие:</p>
            <div className={styles.ColourPage_couples__boxColors}>{renderSimilarColors}</div>
          </div>
        </>
      )}
      <Modal width={530} className={styles.OtherColorModal} open={isOpen} close={closeModal}>
        <p>Выбранные параметры не соответствуют данному цвету. Параметры будут сброшены.</p>
        <div className={styles.OtherColorModal__actions}>
          <Button onClick={handleClickOnModal}>Подтвердить</Button>
          <Button onClick={closeModal} className={styles.OtherColorModal__cancel}>
            Отмена
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export { OtherColors };

function hexToRgb(hex: string) {
  const newHex = hex.replace(/^#/, "");

  const bigint = parseInt(newHex, 16);
  // eslint-disable-next-line no-bitwise
  const r = (bigint >> 16) & 255;
  // eslint-disable-next-line no-bitwise
  const g = (bigint >> 8) & 255;
  // eslint-disable-next-line no-bitwise
  const b = bigint & 255;

  return { r, g, b };
}

export { hexToRgb };

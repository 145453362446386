function makeUniqueArray(arr) {
  const uniqueMap = {};

  arr.forEach((item) => {
    uniqueMap[item.textureId] = item;
  });

  return Object.values(uniqueMap);
}

export { makeUniqueArray };

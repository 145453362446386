import { useState } from "react";
import { PageWrapper } from "components";
import { DecoratePreset } from "features/decoratePreset/decoratePreset";
import { HistoryProvider } from "contexts";
import { DownloadUserPhoto } from "features/downloadUserPhoto";
import { ExpandToFullScreen } from "features/expandToFullScreen";
// import { SwitchTimeOfDay } from "features/switchTimeOfDay";
import DecorateSection from "../../../components/DecorateSection/DecorateSection";
import ActionsPanel from "./Sections/ActionsSection";
import styles from "./presetpage.module.scss";

function PresetPage() {
  const [activeTab, setTab] = useState<number>(1);

  const changeTab = (v: number) => {
    setTab(v);
  };

  return (
    <PageWrapper>
      <div className={styles.PresetPage_root}>
        <HistoryProvider>
          <ActionsPanel activeTab={activeTab} setTab={changeTab} />
          <DecorateSection
            setTab={changeTab}
            variant='preset'
            drawFeature={<DecoratePreset />}
            downloadFeature={<DownloadUserPhoto />}
            expandToFullScreenFeature={<ExpandToFullScreen />}
            // switchTimeOnDayFeature={<SwitchTimeOfDay />}
          />
        </HistoryProvider>
      </div>
    </PageWrapper>
  );
}

export default PresetPage;

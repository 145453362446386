import { useCallback, useState } from "react";
import { Id, Manager, Pagination } from "types";
import { managersStore } from "stores";
import ManagersService, { CreateManagerParams } from "services/admin/managersService";

interface UseManagers {
  managers: Manager[];
  pending: boolean;
  pagination: Pagination;
  requireManagersByPage: (page?: number) => void;
  getManager: (id: Id) => Manager | undefined;
  editManager: (manager: CreateManagerParams) => Promise<void>;
  deleteManager: (id: Id) => Promise<void>;
  requireManagerById: (id: Id) => void;
  createManager: (values: CreateManagerParams) => Promise<void>;
  clear: () => void;
  restoreManager: (id: Id) => Promise<void>;
  downloadManagers: () => Promise<void>;
}
function useManagers(): UseManagers {
  const [pending, setPending] = useState(false);

  const requireManagersByPage = useCallback((page?: number) => {
    setPending(true);

    ManagersService.requireManagers(page || managersStore.pagination.current_page)
      .then((res) => {
        managersStore.managers = res.managers;
        managersStore.pagination = res.pagination;
      })
      .finally(() => {
        setPending(false);
      });
  }, []);

  const getManager = (id: Id) => managersStore.getManager(id);

  const editManager = async (manager: CreateManagerParams) => {
    const res = await ManagersService.updateManager(manager);
    managersStore.updateManager(res);
  };

  const deleteManager = async (id: Id) => {
    await ManagersService.removeManagerById(id);
    let page = managersStore.pagination.current_page;

    if (managersStore.managers.length === 1) page--;

    const res = await ManagersService.requireManagers(page);
    managersStore.managers = res.managers;
    managersStore.pagination = res.pagination;
  };

  const requireManagerById = (id: Id) => {
    ManagersService.requireManagerById(id).then((res) => {
      managersStore.addManager(res);
    });
  };

  const createManager = async (values: CreateManagerParams) => {
    await ManagersService.createManager(values);
    const res = await ManagersService.requireManagers(managersStore.pagination.current_page);
    managersStore.managers = res.managers;
    managersStore.pagination = res.pagination;
  };

  const clear = useCallback(() => {
    managersStore.managers = [];
    managersStore.pagination = { count_pages: NaN, current_page: 1 };
  }, []);

  const restoreManager = async (id: Id) => {
    await ManagersService.restoreManager(id);
  };

  const downloadManagers = async () => {
    await ManagersService.downloadManagers();
  };

  return {
    managers: managersStore.managers,
    pending,
    pagination: managersStore.pagination,
    requireManagersByPage,
    getManager,
    editManager,
    deleteManager,
    requireManagerById,
    createManager,
    clear,
    restoreManager,
    downloadManagers,
  };
}

export { useManagers };

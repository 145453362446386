import { useNavigate } from "react-router-dom";
import { Modal } from "components";
import { EditManager, EditUser } from "features/admin";
import styles from "../styles.module.scss";

function EditEntity({ variant = "user" }) {
  const navigator = useNavigate();
  const isManager = variant === "manager";

  const moveToEntitiesPage = () => {
    if (isManager) {
      navigator("/admin/managers");
      return;
    }

    navigator("/admin/users");
  };

  const renderTitle = () => {
    if (isManager) return <h3>Редактировать менеджера</h3>;
    return <h3>Редактировать пользователя</h3>;
  };

  const renderForm = () => {
    if (isManager) return <EditManager close={moveToEntitiesPage} />;

    return <EditUser close={moveToEntitiesPage} />;
  };

  return (
    <Modal open width={430} className={styles.addUser} close={moveToEntitiesPage}>
      {renderTitle()}
      {renderForm()}
    </Modal>
  );
}

export { EditEntity };

import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Modal } from "components";
import { UserToManager } from "features/admin";
import { useUsers } from "hooks";
import { observer } from "mobx-react-lite";
import styles from "../styles.module.scss";

function UserToManagerPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getUser } = useUsers();

  if (!id) return <Navigate to='/admin/users' />;

  const user = getUser(id);

  if (!user) return <Navigate to='/admin/users' />;

  const close = () => {
    navigate("/admin/users");
  };

  return (
    <Modal className={styles.addUser} width={430} open close={close}>
      <h3>Изменение роли</h3>
      <p>Для изменения роли требуется ввести наименование организации и подразделение</p>
      <UserToManager user={user} close={close} />
    </Modal>
  );
}

const UserToManagerPageComponent = observer(UserToManagerPage);
export { UserToManagerPageComponent as UserToManagerPage };

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { readAndCompressImage } from "browser-image-resizer";
import { Select, UploadFile } from "components";
import { UserPhotoContext } from "contexts";
import { CANVAS_CONTAINER_HEIGHT, CANVAS_CONTAINER_WIDTH, IMAGE_TYPE } from "constants/index";
import styles from "./uploadPhoto.module.scss";

const imageTypeOptions = [IMAGE_TYPE.interior, IMAGE_TYPE.exterior];

const config = {
  quality: 1,
  maxWidth: CANVAS_CONTAINER_WIDTH,
  maxHeight: CANVAS_CONTAINER_HEIGHT,
  debug: false,
};

function UploadPhoto() {
  const { setImg, setImgType, imgType } = useContext(UserPhotoContext);
  const navigate = useNavigate();

  const onChangeTypeImage = (type) => {
    setImgType(type);
  };

  const onChangeFile = (inp) => {
    if (inp.target.files && inp.target.files[0]) {
      const file = inp.target.files[0];

      if (file.type !== "image/jpeg") return;

      readAndCompressImage(file, config).then((resizedImage) => {
        const reader = new FileReader();

        reader.onload = function (e) {
          setImg(e.target?.result);
        };

        reader.readAsDataURL(resizedImage);

        navigate("/editor/presets/user-photo");
      });
    }
  };

  return (
    <div className={styles.SettingsPage_root}>
      <p className={styles.SettingsPage_title}>Загрузить фото</p>
      <div className={styles.SettingsPage_options}>
        <Select
          value={imgType}
          label='Тип изображения'
          options={imageTypeOptions}
          onChange={onChangeTypeImage}
        />
        <UploadFile onChange={onChangeFile} />
      </div>
    </div>
  );
}

export { UploadPhoto };

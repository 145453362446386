import { useCallback, useState } from "react";
import { Id } from "react-toastify";
import { SeasonsService } from "services/seasonsService";
import { Paint, Season } from "types";

interface UseSeasons {
  seasons: Season[];
  paints: Paint[];
  requireSeasons: () => Promise<void>;
  requirePaintsBySeason: (id: Id) => Promise<any>;
  isPending: boolean;
}

export function useSeasons(): UseSeasons {
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [paints, setPaints] = useState<Paint[]>([]);
  const [isPending, setIsPending] = useState(false);

  const requireSeasons = useCallback(async () => {
    const res = await SeasonsService.requireSeasons();
    setSeasons(res);
  }, []);

  const requirePaintsBySeason = async (id: Id) => {
    setIsPending(true);
    const res = await SeasonsService.requirePaintsById(id);
    setPaints(res);
    setIsPending(false);
  };

  return {
    seasons,
    paints,
    requireSeasons,
    requirePaintsBySeason,
    isPending,
  };
}

import { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { ReactComponent as DropdownIcon } from "assets/icons/arrow_dropdown_light.svg";
import { ReactComponent as OpenEyeIcon } from "assets/icons/open_eye.svg";
import { ReactComponent as ClosedEyeIcon } from "assets/icons/closed_eye.svg";
import { ReactComponent as DeleteIcon } from "assets/iconsBtn/delete.svg";
import { DrawContext, UserPhotoContext } from "contexts";
import styles from "./layers.module.scss";

function Layers() {
  const { layers, isResetAll } = useContext(DrawContext);
  const { setImg, img } = useContext(UserPhotoContext);
  const [open, setOpen] = useState(false);
  const [subLayers, setSubLayers] = useState<any[]>([]);
  const [mainImg, setMainImg] = useState<any>(null);

  useEffect(() => {
    if (!isResetAll) return;

    setSubLayers([]);
  }, [isResetAll]);

  useEffect(() => {
    if (!img) return;

    const newImg = new Image();

    newImg.onload = () => {
      setMainImg(newImg);
    };

    newImg.src = img;
  }, [img]);

  useEffect(() => {
    if (layers.length === 0) return;

    const subLayersTemp: any[] = [];

    if (layers[1].children.length > 0) {
      subLayersTemp.push(...layers[1].getChildren());
    }

    setSubLayers(subLayersTemp.sort((a, b) => a._id - b._id));
  }, [layers]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const hideOrVisibleLayer = (layer) => () => {
    if (layer.isVisible()) {
      layer.visible(false);
    } else {
      layer.visible(true);
    }

    const subLayerIndex = subLayers.findIndex((sb) => sb._id === layer._id);

    if (subLayerIndex !== -1) {
      setSubLayers([
        ...subLayers.slice(0, subLayerIndex),
        layer,
        ...subLayers.slice(subLayerIndex + 1),
      ]);
    }
  };

  const destroySubLayer = (layer) => () => {
    layer.destroy();

    setSubLayers(subLayers.filter((sb) => sb._id !== layer._id));

    if (layer.attrs.image) setImg(null);
  };

  const renderAdditionalNodeInfo = (attrs) => {
    if (attrs.image) return "изображение";
    if (attrs.fill) return "заливка";
    if (!attrs.fill && attrs.globalCompositeOperation === "source-over") return "кисть";
    if (!attrs.fill && attrs.globalCompositeOperation === "destination-out") return "ластик";
  };

  const renderNodeColor = (attrs) => {
    if (attrs.fill)
      return <div className={styles.container_layer_color} style={{ background: attrs.fill }} />;
    if (!attrs.fill && attrs.globalCompositeOperation === "source-over")
      return <div className={styles.container_layer_color} style={{ background: attrs.stroke }} />;
  };

  const renderEyeIcons = (sb) => {
    if (sb.attrs.image) return null;

    return sb.isVisible() ? (
      <OpenEyeIcon onClick={hideOrVisibleLayer(sb)} />
    ) : (
      <ClosedEyeIcon onClick={hideOrVisibleLayer(sb)} />
    );
  };

  const renderDeleteIcon = (sb) => {
    if (sb.attrs.image) return null;

    return <DeleteIcon onClick={destroySubLayer(sb)} />;
  };

  const renderLayers = () =>
    subLayers.map((sb, i) => (
      <div className={styles.container_layer} key={sb._id}>
        <div className={styles.container_layer_about}>
          {`Слой ${i + 1} (${renderAdditionalNodeInfo(sb.attrs)})`}
          {renderNodeColor(sb.attrs)}
        </div>
        <div className={styles.container_layer_actions}>
          {renderEyeIcons(sb)}
          {renderDeleteIcon(sb)}
        </div>
      </div>
    ));

  if (!img) return null;

  return (
    <div className={cn(styles.container, { [styles.container_open]: open })}>
      <div className={styles.container_header} onClick={handleOpen}>
        <span className={styles.container_header_title}>Слои</span>
        <DropdownIcon
          className={cn(styles.container_header_dropdown, {
            [styles.container_header_dropdown_open]: open,
          })}
        />
      </div>
      <div
        style={{ height: open ? mainImg?.height ?? 667 : 0 }}
        className={cn(styles.container_layers, { [styles.container_layers_hidden]: !open })}
      >
        {renderLayers()}
      </div>
    </div>
  );
}

export { Layers };

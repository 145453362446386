import { useContext } from "react";
import { rgbaToHex } from "libs/rgbaToHex";
import { ColorsContext, DrawContext, PresetsContext } from "contexts";
import { hexToRgb } from "libs/hexToRgb";
import { ReactComponent as PlusDark } from "assets/icons/plus_icon_dark.svg";
import styles from "./sections.module.scss";

const day = "день";
const night = "ночь";

interface TextureInfoProps {
  texture: any;
}

function TextureInfo({ texture }: TextureInfoProps) {
  const { masks } = useContext(PresetsContext);
  const { setTextures, textures, timeOfDay } = useContext(DrawContext);
  const { paint } = useContext(ColorsContext);
  const hexColor = rgbaToHex(texture.color?.r, texture.color?.g, texture.color?.b);

  const changeTextureColor = () => {
    if (!paint) return;

    const mask = masks.find((m) => m.name === texture.name);
    const effect = mask.textures.find((t) => t.name === paint.effect.name);

    if (effect.base) {
      setTextures([
        ...textures.map((t) => {
          if (t.index === texture.index) {
            const rgbColor = hexToRgb(paint.color.hex);

            return {
              ...t,
              color: { r: rgbColor.r, g: rgbColor.g, b: rgbColor.b },
              visible: true,
              ncs: paint.color.ncs,
              effect: null,
              nightEffect: null,
              image: t.oldImage,
              decoration: paint.decoration,
            };
          }

          return t;
        }),
      ]);
    } else {
      const dayImg = new Image();
      const nightImg = new Image();

      dayImg.crossOrigin = "Anonymous";
      nightImg.crossOrigin = "Anonymous";

      dayImg.src = effect.texture;
      nightImg.src = effect.texture.replace(day, night);

      dayImg.onload = () => {
        setTextures([
          ...textures.map((t) => {
            if (t.index === texture.index) {
              const rgbColor = hexToRgb(paint.color.hex);

              return {
                ...t,
                color: { r: rgbColor.r, g: rgbColor.g, b: rgbColor.b },
                visible: true,
                ncs: paint.color.ncs,
                image: timeOfDay === day ? dayImg : nightImg,
                effect: dayImg,
                nightEffect: nightImg,
                decoration: paint.decoration,
              };
            }

            return t;
          }),
        ]);
      };
    }
  };

  const renderColorName = () => {
    if (!texture?.color && !texture?.colorName) {
      return "Не выбрано";
    }

    if (hexColor) {
      return `${texture.ncs} #${hexColor}`;
    }

    return null;
  };

  const renderColorContainer = () => {
    if (!hexColor) {
      return (
        <div
          className={styles.partPreset__color}
          style={{
            border: "0.5px solid rgba(196, 201, 206, 0.64)",
            cursor: "pointer",
          }}
        >
          <PlusDark />
        </div>
      );
    }

    return <div className={styles.partPreset__color} style={{ background: `#${hexColor}` }} />;
  };

  return (
    <div className={styles.partPreset}>
      <div className={styles.partPreset__container} onClick={changeTextureColor}>
        {renderColorContainer()}
        <div className={styles.partPreset__desc}>
          <span className={styles.partPreset__label}>{texture.name}</span>
          <span className={styles.partPreset__title}>{renderColorName()}</span>
        </div>
      </div>
    </div>
  );
}

export { TextureInfo };

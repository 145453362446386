import { Outlet, useNavigate } from "react-router-dom";
import { Table } from "components";
import { Header } from "../components";

const values = [
  {
    id: 1,
    name: "Пресет 1",
    colors:
      "Lorem ipsum dolor sit amet consectetur. Pretium felis felis feugiat in ut pellentesque. " +
      "Elit est neque sed odio magna consectetur quis sed tincidunt. Vitae.",
  },
];

function SeasonPalettesPage() {
  const navigate = useNavigate();

  const moveToEditPalette = (id) => {
    navigate(`/admin/season-palettes/${id}/edit`);
  };

  return (
    <>
      <Header title='Сезонные палитры' />
      <Table
        headers={["ID", "Название", "Цвета"]}
        fields={["id"]}
        values={values}
        editable
        widthValues={[57, 140]}
        editCallback={moveToEditPalette}
      />
      <Outlet />
    </>
  );
}

export { SeasonPalettesPage };

import { useNavigate } from "react-router-dom";
import { Modal } from "components";
import { AddOrganisation } from "features/admin";
import styles from "../styles.module.scss";

function AddOrganisationPage() {
  const navigate = useNavigate();

  const moveToOrganisationsPage = () => {
    navigate("/admin/organisations");
  };

  return (
    <Modal width={430} className={styles.addUser} open close={moveToOrganisationsPage}>
      <h3>Добавить организацию</h3>
      <AddOrganisation />
    </Modal>
  );
}

export { AddOrganisationPage };

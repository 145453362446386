import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { ReactComponent as Dropper } from "assets/iconsEditor/dropper.svg";
import { Input, Loader, Modal, Select, Tabs } from "components";
import { DrawContext, ColorsContext, HistoryContext } from "contexts";
import { filterColorsByNameOrCode } from "pages/ColoursPage/helpers";
import { TOOLS } from "constants/index";
import { ColorDescription } from "pages/ColorPage/colorDescription";
import { Palette } from "pages/ColorPage/palette";
import { OtherColors } from "pages/ColorPage/otherColors";
import { ColorView } from "pages/ColorPage/colorView";
import { useColors, useOtherColors } from "libs/useColors";
import { Color } from "types";
import { ResetSelectedColor } from "features/resetSelectedColor";
import { TextureInfo } from "./TextureInfo";
import { makeUniqueArray } from "./makeUniqueArray";
import { formOptions } from "./helpers";
import styles from "./sections.module.scss";

interface ActionsPanelProps {
  activeTab: number;
  setTab: (tab: number) => void;
  layersFeature?: ReactNode | null;
}

function ActionsPanel({ activeTab, setTab, layersFeature = null }: ActionsPanelProps) {
  const { color, changeColor, textureInside, setTextureInside, setTool, textures } =
    useContext(DrawContext);
  const {
    colors,
    userColors,
    eyeDropperColors,
    collection,
    collections,
    brand,
    brands,
    colorsParams,
    products,
    product,
    effect,
    effects,
    setPaint,
    setIsPending,
    isPending,
  } = useContext(ColorsContext);
  const { history } = useContext(HistoryContext);
  const [keyword, setKeyword] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { changeBrand, changeProduct, changeCollection, changeEffect, requireColor } = useColors();
  const colorsParamsRef = useRef(colorsParams);
  const { requireOtherColors, isColorsPending } = useOtherColors();

  useEffect(() => {
    colorsParamsRef.current = colorsParams;

    return () => {
      changeColor({ hex: "#ffffff" });
      setTool(TOOLS.fillFood);
    };
  }, [changeColor, colorsParams, setTool]);

  useEffect(() => {
    requireColor(colorsParamsRef.current);
  }, [requireColor, requireOtherColors]);

  const handleOpenModal = useCallback(
    (c: Color) => () => {
      setIsPending(true);

      requireColor({ ...colorsParamsRef.current, color: c.id }).then((res) => {
        const clr = res?.colors?.find((cc: Color) => cc.id === c.id);

        if (clr) requireOtherColors(clr);
      });

      setIsOpenModal(true);
    },
    [requireColor, requireOtherColors, setIsPending],
  );

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    const newTexturesInside = textureInside;

    textureInside?.forEach((ps, i) => {
      const foundHistory = makeUniqueArray(history).find((h) => h.textureId === i);

      newTexturesInside[i] = {
        ...newTexturesInside[i],
        color: foundHistory?.color ?? null,
        colorName: foundHistory?.colorName ?? null,
      };
    });

    setTextureInside(newTexturesInside);
  }, [history, setTextureInside, textureInside]);

  // скролл до выбранного цвета
  useEffect(() => {
    if (!colorsParamsRef.current) return;

    const foundColorDiv = document.getElementById(
      `actions_section_${colorsParamsRef.current.color}`,
    );

    if (!foundColorDiv) return;

    foundColorDiv.scrollIntoView({
      behavior: "instant",
      block: "center",
    });
  }, [activeTab, isOpenModal, colorsParams]);

  const changeKeyword = (value: string) => {
    setKeyword(value);
  };

  const renderColors = useMemo(() => {
    const filteredColorsByNameOrCode = filterColorsByNameOrCode(colors, keyword);

    return (
      <>
        <ResetSelectedColor />
        {filteredColorsByNameOrCode.map((c: Color) => (
          <div
            key={c.id}
            title={c.ncs}
            className={cn(styles.color, {
              [styles.SelectedColor]: c.id === colorsParams?.color,
            })}
            style={{ background: `#${c.hex}` }}
            onClick={handleOpenModal(c)}
          >
            <div id={`actions_section_${c.id}`} title={c.ncs} />
          </div>
        ))}
      </>
    );
  }, [colorsParams?.color, colors, handleOpenModal, keyword]);

  const renderUserColors = useMemo(
    () =>
      userColors.map((c) => (
        <div
          key={c.color.id}
          title={c.color.ncs}
          onClick={() => {
            changeColor(c.color);
            setTool(TOOLS.fillFood);
            setPaint(c);
          }}
          style={{ background: `#${c.color.hex}` }}
          className={cn(styles.color, { [styles.SelectedColor]: `#${c.color.hex}` === color.hex })}
        />
      )),
    [changeColor, color.hex, setTool, userColors, setPaint],
  );

  const renderEyeDropperColors = useMemo(
    () =>
      eyeDropperColors.map((c: Color) => (
        <div
          key={c.id}
          title={c.ncs}
          onClick={handleOpenModal(c)}
          style={{ background: `#${c.hex}` }}
          className={cn(styles.color, { [styles.SelectedColor]: `#${c.hex}` === color.hex })}
        />
      )),
    [color.hex, eyeDropperColors, handleOpenModal],
  );

  const renderEyeDropperContainer = () => {
    if (eyeDropperColors.length === 0 || window.location.pathname.includes("user-photo")) {
      return null;
    }

    return (
      <div className={styles.SelectedColors__dropperContainer}>
        <div className={styles.SelectedColors__dropper}>
          <Dropper />
          <span>Пипетка</span>
        </div>
        <div className={styles.Colors}>{renderEyeDropperColors}</div>
      </div>
    );
  };

  const renderAndSortTextures = () =>
    textures.sort((a, b) => a.index - b.index).map((t) => <TextureInfo key={t.name} texture={t} />);

  const renderTabContent = () => {
    if (activeTab === 1) {
      return (
        <>
          <Select
            value={brand}
            options={formOptions("Все бренды", brands)}
            placeholder='Все бренды'
            className={styles.Select}
            onChange={changeBrand}
            disabled={isPending}
          />
          <Select
            value={product}
            options={formOptions("Все продукты", products)}
            placeholder='Все продукты'
            className={styles.Select}
            onChange={changeProduct}
            disabled={isPending}
          />
          <Select
            value={collection}
            options={formOptions("Все коллекции", collections)}
            placeholder='Все коллекции'
            className={styles.Select}
            onChange={changeCollection}
            disabled={isPending}
          />
          <Select
            value={effect}
            className={styles.Select}
            placeholder='Без эффекта'
            options={formOptions("Без эффекта", effects)}
            onChange={changeEffect}
            disabled={isPending}
          />
          <Input
            placeholder='Название или код'
            className={styles.Input}
            icon='search'
            value={keyword}
            onChange={changeKeyword}
          />
          <div className={styles.Colors} style={{ height: isPending ? "100%" : undefined }}>
            {isPending ? <Loader /> : renderColors}
          </div>
        </>
      );
    }

    if (activeTab === 2) {
      return (
        <div className={styles.SelectedColors}>
          <div className={styles.Colors}>{renderUserColors}</div>
          {renderEyeDropperContainer()}
        </div>
      );
    }

    if (activeTab === 3) {
      return <div className={styles.colorsInside}>{renderAndSortTextures()}</div>;
    }

    return null;
  };

  const renderStub = () => {
    if (colorsParams?.color || isPending || isColorsPending) return null;
    return <div className={styles.stub}>Выберите цвет из палитры</div>;
  };

  return (
    <div className={styles.ActionPanel}>
      <Tabs
        items={
          // TODO: временная история
          window.location.pathname.includes("user-photo")
            ? [
                { label: "Палитра", value: 1 },
                { label: "Выбранные ", value: 2 },
              ]
            : [
                { label: "Палитра", value: 1 },
                { label: "Выбранные ", value: 2 },
                { label: "В интерьере ", value: 3 },
              ]
        }
        activeTab={activeTab}
        onClick={(v) => setTab(v)}
      />
      {renderTabContent()}
      {layersFeature}
      <Modal open={isOpenModal} close={handleCloseModal}>
        <div className={styles.modal__content}>
          <ColorDescription isInsideModal />
          <Palette isInsideModal />
          <div className={styles.container}>
            {renderStub()}
            <OtherColors isInsideModal isPending={isColorsPending} />
            <ColorView />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ActionsPanel;

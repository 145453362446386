import { useCallback, useContext } from "react";
import { DrawContext } from "contexts";

function useZoom() {
  const { stageRef } = useContext(DrawContext);

  const zoomIn = useCallback(() => {
    const stage = stageRef.current;
    const pointerPos = stage.getPointerPosition(); // Получаем позицию курсора относительно холста

    const clickPosRelativeToRect = {
      x: (pointerPos.x - stage.x()) / stage.scaleX(),
      y: (pointerPos.y - stage.y()) / stage.scaleY(),
    };

    stage.scaleX(stage.scaleX() * 1.2);
    stage.scaleY(stage.scaleY() * 1.2);

    stage.x(pointerPos.x - clickPosRelativeToRect.x * stage.scaleX());
    stage.y(pointerPos.y - clickPosRelativeToRect.y * stage.scaleY());

    stage.batchDraw();
  }, [stageRef]);

  const zoomOut = useCallback(() => {
    const stage = stageRef.current;
    const pointerPos = stage.getPointerPosition(); // Получаем позицию курсора относительно холста

    const clickPosRelativeToRect = {
      x: (pointerPos.x - stage.x()) / stage.scaleX(),
      y: (pointerPos.y - stage.y()) / stage.scaleY(),
    };

    const newScaleX = stage.scaleX() / 1.2;
    const newScaleY = stage.scaleY() / 1.2;

    stage.scaleX(Math.max(newScaleX, 1));
    stage.scaleY(Math.max(newScaleY, 1));

    if (stage.scaleX() === 1 && stage.scaleY() === 1) {
      stage.position({ x: 0, y: 0 });
    } else {
      stage.x(pointerPos.x - clickPosRelativeToRect.x * stage.scaleX());
      stage.y(pointerPos.y - clickPosRelativeToRect.y * stage.scaleY());
    }

    stage.batchDraw();
  }, [stageRef]);

  const handleWheel = useCallback(
    (e) => {
      e.evt.preventDefault();

      const stage = stageRef.current;
      const oldScaleX = stage.scaleX();
      const oldScaleY = stage.scaleY();
      const pointerPos = stage.getPointerPosition();
      const scaleBy = e.evt.deltaY < 0 ? 1.2 : 1 / 1.2;
      const newScaleX = Math.max(oldScaleX * scaleBy, 1);
      const newScaleY = Math.max(oldScaleY * scaleBy, 1);

      if (newScaleX === 1 && newScaleY === 1) {
        stage.position({ x: 0, y: 0 });
        stage.scaleX(newScaleX);
        stage.scaleY(newScaleY);
      } else {
        stage.scaleX(newScaleX);
        stage.scaleY(newScaleY);

        stage.x(pointerPos.x - ((pointerPos.x - stage.x()) / oldScaleX) * newScaleX);
        stage.y(pointerPos.y - ((pointerPos.y - stage.y()) / oldScaleY) * newScaleY);
      }

      stage.batchDraw();
    },
    [stageRef],
  );

  return { handleWheel, zoomIn, zoomOut };
}

export { useZoom };

import { useNavigate } from "react-router-dom";
import { Button, Modal } from "components";
import { RemoveManager, RemoveUser } from "features/admin";
import styles from "../styles.module.scss";

interface RemoveEntityProps {
  variant: "user" | "manager";
}

function RemoveEntity({ variant = "user" }: RemoveEntityProps) {
  const navigate = useNavigate();
  const isManager = variant === "manager";

  const moveToUsersPage = () => {
    if (isManager) {
      navigate("/admin/managers");
      return;
    }

    navigate("/admin/users");
  };

  const renderTitle = () => {
    if (isManager) {
      return (
        <h3>
          Вы действительно хотите
          <br /> скрыть менеджера?
        </h3>
      );
    }

    return (
      <>
        <h3>
          Вы действительно хотите
          <br /> удалить пользователя?
        </h3>
        <p>Это действие нельзя отменить</p>
      </>
    );
  };

  const renderRemoveFeat = () => {
    if (variant === "user") return <RemoveUser />;
    if (variant === "manager") return <RemoveManager />;

    return null;
  };

  return (
    <Modal open width={430} className={styles.addUser} close={moveToUsersPage}>
      {renderTitle()}
      <div className={styles.addUser_actions} style={{ justifyContent: "center" }}>
        {renderRemoveFeat()}
        <Button className={styles.addUser_actions_cancel} onClick={moveToUsersPage}>
          Отмена
        </Button>
      </div>
    </Modal>
  );
}

export { RemoveEntity };

import { Link } from "react-router-dom";
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import styles from "./styles.module.scss";

function AuthHeader() {
  return (
    <header className={styles.authHeader}>
      <Link to='/'>
        <LogoIcon />
      </Link>
      <div className={styles.authHeader_divider} />
      <p className={styles.authHeader_title}>Панель администратора</p>
    </header>
  );
}

export { AuthHeader };

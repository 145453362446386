import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate } from "react-router-dom";
import { Table } from "components";
import { usePresets } from "hooks";
import { Id } from "types";
import { Header } from "../components";

function PresetsPage() {
  const navigate = useNavigate();
  const { requirePresetsByPage, presets, pending, clear, pagination } = usePresets();

  useEffect(() => {
    requirePresetsByPage();

    return () => {
      clear();
    };
  }, [requirePresetsByPage, clear]);

  const moveToEditPreset = (id: Id) => {
    navigate(`/admin/presets/${id}/edit`);
  };

  return (
    <>
      <Header title='Пресеты' />
      <Table
        headers={["ID", "Название", "Доступен организациям"]}
        fields={["id", "name", "organisations"]}
        values={presets.map((p) => ({
          id: p.id,
          name: p.name,
          organisations: p.organisations.map((o) => o.name).join(", "),
        }))}
        editable
        widthValues={[200, 300]}
        editCallback={moveToEditPreset}
        pending={pending}
        paginate
        pageCount={pagination?.count_pages}
      />
      <Outlet />
    </>
  );
}

const PresetsPageComponent = observer(PresetsPage);
export { PresetsPageComponent as PresetsAdminPage };

function convertContoursToFormat(contours: any): any[] {
  const result: any[] = [];

  contours
    .filter((c) => !c.inner)
    .forEach((contour: any) => {
      contour.points.forEach((point: any) => {
        result.push(point.x, point.y);
      });
    });

  return result;
}

export { convertContoursToFormat };

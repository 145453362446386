import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface PhoneMaskProps extends PhoneInputProps {
  error?: string;
  label?: string;
  name: string;
}

export function PhoneMask({ label, name, error, value, ...props }: PhoneMaskProps) {
  return (
    <label style={{ cursor: "default" }}>
      <p className={styles.label}>{label}</p>
      <PhoneInput
        country='ru'
        inputClass={classNames(styles.input, { [styles.inputError]: !!error })}
        value={value}
        {...props}
      />
      {error ? <p className={styles.error}>{error}</p> : null}
    </label>
  );
}

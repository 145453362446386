import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { Logout } from "features/admin";
import styles from "./sidebar.module.scss";

function Sidebar() {
  const isActiveLink = ({ isActive }) =>
    isActive ? `${styles.navigation_link} ${styles.navigation_linkActive}` : styles.navigation_link;

  return (
    <div className={styles.sidebar}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <nav className={styles.navigation}>
        <NavLink className={isActiveLink} to='/admin/users'>
          Пользователи
        </NavLink>
        <NavLink className={isActiveLink} to='/admin/managers'>
          Менеджеры
        </NavLink>
        <NavLink className={isActiveLink} to='/admin/presets'>
          Пресеты
        </NavLink>
        <NavLink className={isActiveLink} to='/admin/seasons'>
          Сезонные подборки
        </NavLink>
        <NavLink className={isActiveLink} to='/admin/organisations'>
          Организации
        </NavLink>
      </nav>
      <footer>
        <img src='' alt='avatar' />
        <div className={styles.sidebar_footerInfo}>
          <span>Admin</span>
          <span>admin@gmail.com</span>
          <Logout />
        </div>
      </footer>
    </div>
  );
}

export { Sidebar };

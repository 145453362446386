import { Button, Modal } from "components";
import { useNavigate } from "react-router-dom";
import styles from "../styles.module.scss";

export function DeniedRemoveOrganisationPage() {
  const navigate = useNavigate();

  const close = () => {
    navigate("/admin/organisations");
  };

  const navigateToManagers = () => {
    navigate("/admin/managers");
  };

  return (
    <Modal className={styles.addUser} open width={430} close={close}>
      <h3 style={{ whiteSpace: "nowrap" }}>Невозможно удалить организацию</h3>
      <p style={{ paddingBottom: 21 }}>
        К организации прикреплены менеджеры. <br />
        Перенесите менеджеров в другую организацию.
      </p>
      <Button className={styles.actionBtn} onClick={navigateToManagers}>
        Перейти
      </Button>
    </Modal>
  );
}

import { Id } from "types";
import { EditUserParams } from "services/admin/usersService";

function formUpdatedUser(values: EditUserParams, id: Id): EditUserParams {
  const updatedUser: EditUserParams = {
    id,
    email: values.email,
    name: values.name,
    statistics: {
      count_favorites: 0,
      last_auth: "",
    },
  };

  if (values.password) {
    updatedUser.password = values.password;
  }

  if (values.phone) {
    updatedUser.phone = values.phone;
  }

  return updatedUser;
}

export { formUpdatedUser };

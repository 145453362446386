function sortAsc<T>(items: T[], sortBy: string): T[] {
  return items.sort((a, b) => {
    const nameA = a[sortBy].toUpperCase();
    const nameB = b[sortBy].toUpperCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  });
}

export { sortAsc };

import fileDownload from "js-file-download";
import { User, Pagination, Id } from "types";
import { privateApi, Response } from "libs";
import { PAGINATION_COUNT } from "constants/index";

export interface CreateUserParams extends User {
  password: string;
}

export interface EditUserParams extends User {
  password?: string;
}

export interface ToManager {
  id: Id;
  organisation_id: Id;
  organisation_sub: string;
}

class UsersService {
  private static uri = "/admin/users";

  static async requireUsers(
    page: number,
    limit: number = PAGINATION_COUNT,
  ): Promise<{ users: User[]; pagination: Pagination }> {
    const res = await privateApi.get<{ data: User[]; pagination: Pagination }>(UsersService.uri, {
      params: { page, limit },
    });
    return { users: res.data.data, pagination: res.data.pagination };
  }

  static async requireUserById(id: Id): Promise<User> {
    const res = await privateApi.get<Response<User>>(`${UsersService.uri}/${id}`);
    return res.data.data;
  }

  static async createUser(user: CreateUserParams): Promise<User> {
    const res = await privateApi.post<Response<User>>(UsersService.uri, user);
    return res.data.data;
  }

  static async updateUser(user: Partial<EditUserParams>): Promise<User> {
    const res = await privateApi.put<Response<User>>(`${UsersService.uri}/${user.id}`, user);
    return res.data.data;
  }

  static async removeUserById(id: Id): Promise<void> {
    await privateApi.delete(`${UsersService.uri}/${id}`);
  }

  static async toManager({ id, organisation_sub, organisation_id }: ToManager): Promise<void> {
    await privateApi.put(`${UsersService.uri}/to-manager/${id}`, {
      organisation_id,
      organisation_sub,
    });
  }

  static async downloadUsers(): Promise<void> {
    const res = await privateApi.get(`${UsersService.uri}/export`, { responseType: "blob" });
    fileDownload(res.data, "users.xlsx");
  }
}

export default UsersService;

import { useContext, useState } from "react";
import { ButtonIcon } from "components";
import { Id } from "types";
import { ToastContext } from "contexts";
import { useAdminSeasons } from "hooks";
import styles from "./styles.module.scss";

interface RestoreSeasonProps {
  id: Id;
}

export function RestoreSeason({ id }: RestoreSeasonProps) {
  const { toast } = useContext(ToastContext);
  const { restoreSeasonById, requireSeasons } = useAdminSeasons();
  const [isPending, setIsPending] = useState(false);

  const restore = () => {
    setIsPending(true);

    toast
      .promise(restoreSeasonById(id), {
        pending: "Восстановление подборки...",
        success: "Подборка успешно восстановлена!",
        error: "Ошибка при восстановлении подборки!",
      })
      .then(() => {
        requireSeasons();
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <ButtonIcon
      disabled={isPending}
      onClick={restore}
      className={styles.actions_btn}
      iconType='hideEye'
    />
  );
}

import { makeAutoObservable, observable } from "mobx";
import { AdminPreset, Id, Pagination } from "types";
import { toJS } from "libs";

class PresetsStore {
  private _presets = observable<AdminPreset>([]);

  private _pagination: Pagination = { current_page: 1, count_pages: NaN };

  constructor() {
    makeAutoObservable(this);
  }

  get presets(): AdminPreset[] {
    return toJS(this._presets);
  }

  set presets(presets) {
    this._presets = observable(presets);
  }

  get pagination() {
    return toJS(this._pagination);
  }

  set pagination(pagination: Pagination) {
    this._pagination = pagination;
  }

  getPreset(id: Id): AdminPreset | undefined {
    const preset = this._presets.find((p) => p.id === id);

    if (!preset) return undefined;

    return toJS(preset);
  }

  updatePreset(preset: AdminPreset) {
    const index = this._presets.findIndex((u) => u.id === preset.id);
    this._presets.spliceWithArray(index, 1, [preset]);
  }
}

const presetsStore = new PresetsStore();
export { presetsStore };

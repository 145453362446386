import MagicWand from "./magicWandJs";

class FillFlood {
  colorThreshold = 20;

  blurRadius = 5;

  simplifyTolerant = 0;

  simplifyCount = 30;

  hatchLength = 4;

  hatchOffset = 0;

  imageInfo: any = null;

  cacheInd: any = null;

  mask: any = null;

  oldMask: any = null;

  downPoint: any = null;

  addMode = false;

  currentThreshold = this.colorThreshold;

  constructor(imgLayer, img) {
    this.#init(imgLayer, img);
  }

  contours() {
    return MagicWand.simplifyContours(
      MagicWand.traceContours(this.mask),
      this.simplifyTolerant,
      this.simplifyCount,
    );
  }

  draw(pos, e) {
    this.addMode = e.ctrlKey;
    this.downPoint = { x: Math.ceil(pos.x), y: Math.ceil(pos.y) };
    this.#drawMask(this.downPoint.x, this.downPoint.y);
  }

  #init(imgLayer, img) {
    this.imageInfo = {
      width: imgLayer.width,
      height: imgLayer.height,
      context: imgLayer.getContext("2d"),
    };

    const tempCtx: any = document.createElement("canvas").getContext("2d");

    tempCtx.canvas.width = imgLayer.width;
    tempCtx.canvas.height = imgLayer.height;
    tempCtx.drawImage(img, 0, 0);

    this.imageInfo.data = tempCtx.getImageData(0, 0, imgLayer.width, imgLayer.height);
  }

  #drawMask(x, y) {
    if (!this.imageInfo) return;

    const image = {
      data: this.imageInfo.data.data,
      width: this.imageInfo.width,
      height: this.imageInfo.height,
      bytes: 4,
    };

    if (this.addMode && !this.oldMask) {
      this.oldMask = this.mask;
    }

    const old = this.oldMask ? this.oldMask.data : null;

    this.mask = MagicWand.floodFill(image, x, y, this.currentThreshold, old, true);

    if (this.mask) this.mask = MagicWand.gaussBlurOnlyBorder(this.mask, this.blurRadius, old);

    if (this.addMode && this.oldMask) {
      this.mask = this.mask ? this.#concatMasks(this.mask, this.oldMask) : this.oldMask;
    }

    this.#drawBorder();
  }

  #drawBorder(noBorder?) {
    if (!this.mask) return;

    let x;
    let y;
    let i;
    let j;
    let k;
    const w = this.imageInfo.width;
    const h = this.imageInfo.height;
    const ctx = this.imageInfo.context;
    const imgData = ctx.createImageData(w, h);
    const res = imgData.data;

    if (!noBorder) this.cacheInd = MagicWand.getBorderIndices(this.mask);

    ctx.clearRect(0, 0, w, h);

    const len = this.cacheInd.length;

    // eslint-disable-next-line no-plusplus
    for (j = 0; j < len; j++) {
      i = this.cacheInd[j];
      x = i % w; // calc x by index
      y = (i - x) / w; // calc y by index
      k = (y * w + x) * 4;
      if ((x + y + this.hatchOffset) % (this.hatchLength * 2) < this.hatchLength) {
        // detect hatch color
        res[k + 3] = 255; // black, change only alpha
      } else {
        res[k] = 255; // white
        res[k + 1] = 255;
        res[k + 2] = 255;
        res[k + 3] = 255;
      }
    }

    ctx.putImageData(imgData, 0, 0);
  }

  // eslint-disable-next-line class-methods-use-this
  #concatMasks(mask, oldMask) {
    const data1 = oldMask.data;
    const data2 = mask.data;
    const w1 = oldMask.width;
    const w2 = mask.width;
    const b1 = oldMask.bounds;
    const b2 = mask.bounds;
    const b = {
      // bounds for new mask
      minX: Math.min(b1.minX, b2.minX),
      minY: Math.min(b1.minY, b2.minY),
      maxX: Math.max(b1.maxX, b2.maxX),
      maxY: Math.max(b1.maxY, b2.maxY),
    };
    const w = oldMask.width; // size for new mask
    const h = oldMask.height;
    let i;
    let j;
    let k;
    let k1;
    let k2;
    let len;

    const result = new Uint8Array(w * h);

    len = b1.maxX - b1.minX + 1;
    i = b1.minY * w + b1.minX;
    k1 = b1.minY * w1 + b1.minX;
    k2 = b1.maxY * w1 + b1.minX + 1;

    for (k = k1; k < k2; k += w1) {
      result.set(data1.subarray(k, k + len), i); // copy row
      i += w;
    }

    len = b2.maxX - b2.minX + 1;
    i = b2.minY * w + b2.minX;
    k1 = b2.minY * w2 + b2.minX;
    k2 = b2.maxY * w2 + b2.minX + 1;

    for (k = k1; k < k2; k += w2) {
      // walk through cols (X)
      // eslint-disable-next-line no-plusplus
      for (j = 0; j < len; j++) {
        if (data2[k + j] === 1) result[i + j] = 1;
      }
      i += w;
    }

    return {
      data: result,
      width: w,
      height: h,
      bounds: b,
    };
  }
}

export { FillFlood };

import { useNavigate } from "react-router-dom";
import { RemoveOrganisation } from "features/admin";
import { Modal } from "components";
import styles from "../styles.module.scss";

function RemoveOrganisationPage() {
  const navigate = useNavigate();

  const close = () => {
    navigate("/admin/organisations");
  };

  return (
    <Modal className={styles.addUser} width={430} open close={close}>
      <h3>
        Вы действительно хотите
        <br />
        удалить организацию?
      </h3>
      <p>Это действие нельзя отменить</p>
      <RemoveOrganisation />
    </Modal>
  );
}

export { RemoveOrganisationPage };

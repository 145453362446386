import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Modal } from "components";
import { Manager, User } from "types";
import styles from "../styles.module.scss";

interface AboutEntityProps {
  variant: "user" | "manager";
  entity: User | Manager;
}

function AboutEntity({ variant = "user", entity }: AboutEntityProps) {
  const navigator = useNavigate();
  const isManager = variant === "manager";

  const moveToEntitiesPage = () => {
    if (isManager) {
      navigator("/admin/managers");
      return;
    }

    navigator("/admin/users");
  };

  const renderTitle = () => {
    if (isManager) return <h3>О менеджере</h3>;

    return <h3>О пользователе</h3>;
  };

  const renderInfo = () => {
    if (isManager) {
      const manager = entity as Manager;

      return (
        <>
          <div className={styles.addUser_aboutItem}>
            <p className={styles.addUser_aboutItemLabel}>ФИО</p>
            <p className={styles.addUser_aboutItemValue}>{manager.name}</p>
          </div>
          <div className={styles.addUser_aboutItem}>
            <p className={styles.addUser_aboutItemLabel}>E-mail</p>
            <p className={styles.addUser_aboutItemValue}>{manager.email}</p>
          </div>
          <div className={styles.addUser_aboutItem}>
            <p className={styles.addUser_aboutItemLabel}>Телефон</p>
            <p className={styles.addUser_aboutItemValue}>{manager.phone}</p>
          </div>
          <div className={styles.addUser_aboutItem}>
            <p className={styles.addUser_aboutItemLabel}>Наименование организации</p>
            <p className={styles.addUser_aboutItemValue}>{manager?.organisation?.name}</p>
          </div>
          <div className={styles.addUser_aboutItem}>
            <p className={styles.addUser_aboutItemLabel}>Подразделение</p>
            <p className={styles.addUser_aboutItemValue}>{manager?.organisation?.sub}</p>
          </div>
          <div className={styles.addUser_aboutItem}>
            <p className={styles.addUser_aboutItemLabel}>Дата и время авторизации в Системе</p>
            <p className={styles.addUser_aboutItemValue}>
              {format(new Date(entity.statistics.last_auth), "dd.MM.yy в hh:mm")}
            </p>
          </div>
          <div className={styles.addUser_aboutItem}>
            <p className={styles.addUser_aboutItemLabel}>
              Сохранённые пресеты <span>{manager.statistics.count_favorites}</span>
            </p>
            {/* <p className={styles.addUser_aboutItemValue}>Пресет 1 • сохранён 15.05.24 в 16:30</p> */}
            {/* <p className={styles.addUser_aboutItemValue}>Пресет 2 • сохранён 15.05.24 в 16:30</p> */}
          </div>
        </>
      );
    }

    return (
      <>
        <div className={styles.addUser_aboutItem}>
          <p className={styles.addUser_aboutItemLabel}>ФИО</p>
          <p className={styles.addUser_aboutItemValue}>{entity.name}</p>
        </div>
        <div className={styles.addUser_aboutItem}>
          <p className={styles.addUser_aboutItemLabel}>E-mail</p>
          <p className={styles.addUser_aboutItemValue}>{entity.email}</p>
        </div>
        <div className={styles.addUser_aboutItem}>
          <p className={styles.addUser_aboutItemLabel}>Телефон</p>
          <p className={styles.addUser_aboutItemValue}>{entity.phone}</p>
        </div>
        <div className={styles.addUser_aboutItem}>
          <p className={styles.addUser_aboutItemLabel}>Дата и время авторизации в Системе</p>
          <p className={styles.addUser_aboutItemValue}>
            {format(new Date(entity.statistics.last_auth), "dd.MM.yy в hh:mm")}
          </p>
        </div>
        <div className={styles.addUser_aboutItem}>
          <p className={styles.addUser_aboutItemLabel}>
            Сохранённые пресеты <span>{entity.statistics.count_favorites}</span>
          </p>
          {/* <p className={styles.addUser_aboutItemValue}>Пресет 1 • сохранён 15.05.24 в 16:30</p> */}
          {/* <p className={styles.addUser_aboutItemValue}>Пресет 2 • сохранён 15.05.24 в 16:30</p> */}
        </div>
      </>
    );
  };

  return (
    <Modal open width={430} className={styles.addUser} close={moveToEntitiesPage}>
      {renderTitle()}
      <div className={styles.addUser_aboutContainer}>{renderInfo()}</div>
    </Modal>
  );
}

export { AboutEntity };

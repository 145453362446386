import { useCallback, useState } from "react";
import { SeasonsService } from "services/admin/seasonsService";
import { seasonsStore } from "stores/admin";
import { Season, Id, Pagination } from "types";

interface UseAdminSeasons {
  seasons: Season[];
  pagination: Pagination;
  isPending: boolean;
  requireSeasons: (page?: number) => Promise<void>;
  removeSeasonById: (id: Id) => Promise<void>;
  restoreSeasonById: (id: Id) => Promise<void>;
  requireSeasonById: (id: Id) => Promise<Season>;
  clear: () => void;
}

export function useAdminSeasons(): UseAdminSeasons {
  const [isPending, setIsPending] = useState(false);

  const requireSeasons = useCallback(async (page?: number) => {
    setIsPending(true);

    const res = await SeasonsService.requireSeasons(page || seasonsStore.pagination.current_page);
    seasonsStore.seasons = res.seasons;
    seasonsStore.pagination = res.pagination;
    setIsPending(false);
  }, []);

  const removeSeasonById = async (id: Id): Promise<void> => {
    await SeasonsService.removeSeasonById(id);
  };

  const restoreSeasonById = async (id: Id): Promise<void> => {
    await SeasonsService.restoreSeasonById(id);
  };

  const requireSeasonById = useCallback(async (id: Id): Promise<Season> => {
    const res = await SeasonsService.requireSeasonById(id);
    return res;
  }, []);

  const clear = useCallback(() => {
    seasonsStore.seasons = [];
    seasonsStore.pagination = { count_pages: NaN, current_page: 1 };
  }, []);

  return {
    seasons: seasonsStore.seasons,
    pagination: seasonsStore.pagination,
    isPending,
    requireSeasons,
    removeSeasonById,
    restoreSeasonById,
    requireSeasonById,
    clear,
  };
}

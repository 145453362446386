import { MouseEvent, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { ColorsContext } from "contexts";
import { Loader } from "components";
import { Color } from "types";
import { useColors, useOtherColors } from "libs";
import { ResetSelectedColor } from "features/resetSelectedColor";
import styles from "./colourpage.module.scss";

interface PaletteProps {
  isInsideModal?: boolean;
}

function Palette({ isInsideModal = false }: PaletteProps) {
  const { userColors, resetUserColors, isPending, colors, colorsParams } =
    useContext(ColorsContext);
  const { requireColor } = useColors();
  const { requireOtherColors } = useOtherColors();

  // скролл до выбранного цвета
  useEffect(() => {
    if (!colorsParams?.color) return;
    const container = document.querySelector("#pallete");

    if (!container) return;
    const foundColorDiv = container.querySelector(`#pallete_${colorsParams?.color}`);

    if (!foundColorDiv) return;

    foundColorDiv.scrollIntoView({
      behavior: "instant",
      block: "center",
    });
  }, [colorsParams?.color]);

  const handleClickOnUserColor = (c: Color) => (e: MouseEvent<HTMLAnchorElement>) => {
    if (isInsideModal) {
      e.preventDefault();
      requireColor({ ...colorsParams, color: c.id }).then((res) => {
        const clr = res?.colors?.find((cc: Color) => cc.id === c.id);

        if (clr) requireOtherColors(clr);
      });
    }
  };

  const renderColors = () => (
    <>
      {isInsideModal ? <ResetSelectedColor /> : null}
      {colors.map((c: Color) => (
        <Link
          title={c.ncs}
          onClick={handleClickOnUserColor(c)}
          key={c.id}
          to={`/colours/${c.id}`}
          className={cn(styles.ColourPage_couples__boxColorsItem, {
            [styles.ColourPage_couples__boxColorsItemActive]: colorsParams?.color === c.id,
          })}
          style={{ background: `#${c.hex}` }}
          id={`pallete_${c.id}`}
        />
      ))}
    </>
  );

  const renderUserColors = () => (
    <>
      {userColors.map((uc) => (
        <Link
          onClick={handleClickOnUserColor(uc.color)}
          title={uc.color.ncs}
          key={uc.color.id}
          to={`/colours/${uc.color.id}`}
          className={styles.ColourPage_couples__boxColorsItem}
          style={{ background: `#${uc.color.hex}` }}
        />
      ))}
    </>
  );

  return (
    <div className={styles.palette} id='pallete'>
      <p>Палитра</p>
      <div
        style={{ height: isPending ? "100%" : undefined }}
        className={cn(styles.ColourPage_couples__boxColors, styles.palette__colors)}
      >
        {isPending ? <Loader /> : renderColors()}
      </div>
      {userColors.length > 0 ? (
        <div className={styles.palette__section}>
          <p>Выбранные продукты:</p>
          <button className={styles.clearFilters} onClick={resetUserColors}>
            Очистить
          </button>
        </div>
      ) : null}
      <div
        className={cn(styles.ColourPage_couples__boxColors, styles.palette__colors)}
        style={{ maxHeight: 144, height: "auto" }}
      >
        {renderUserColors()}
      </div>
      {isInsideModal ? null : (
        <Link
          style={{ position: "absolute", bottom: 10 }}
          className={styles.palette__link}
          to='/editor/presets'
        >
          Перейти к покраске
        </Link>
      )}
    </div>
  );
}

export { Palette };

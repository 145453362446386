import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "services/admin/adminService";
import { APP } from "constants/index";
import { ToastContext } from "contexts";
import styles from "./styles.module.scss";

function Logout() {
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const { toast } = useContext(ToastContext);

  const logoutFromAdminPanel = () => {
    if (pending) return;

    setPending(true);
    toast
      .promise(logout(), {
        pending: "Выход из аккаунта...",
        success: "Вы вышли из аккаунта!",
        error: "Произошла ошибка при выходе из аккаунта!",
      })
      .then(() => {
        localStorage.removeItem(`${APP}_token`);
        navigate("/");
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <span onClick={logoutFromAdminPanel} className={styles.logout}>
      Выйти из аккаунта
    </span>
  );
}

export { Logout };

import { useContext } from "react";
import { ButtonIcon } from "components";
import { useManagers } from "hooks";
import { Id } from "types";
import { ToastContext } from "contexts";
import styles from "./styles.module.scss";

interface RestoreManagerProps {
  id: Id;
}

export function RestoreManager({ id }: RestoreManagerProps) {
  const { restoreManager, requireManagersByPage } = useManagers();
  const { toast } = useContext(ToastContext);

  const onClick = () => {
    toast
      .promise(restoreManager(id), {
        pending: "Восстановление менеджера...",
        success: "Менеджер успешно восстановлен!",
        error: "Ошибка при восстановлении менеджера!",
      })
      .then(() => {
        requireManagersByPage();
      });
  };

  return <ButtonIcon onClick={onClick} className={styles.actions_btn} iconType='hideEye' />;
}

import Footer from "components/PageWrapper/Footer";
import { AuthHeader } from "pages/AdminPages/components";
import { Login } from "features/admin";
import styles from "../styles.module.scss";

function LoginPage() {
  return (
    <div className={styles.auth}>
      <AuthHeader />
      <p className={styles.auth_title}>Вход в аккаунт</p>
      <Login className={styles.auth_form} />
      <Footer />
    </div>
  );
}

export { LoginPage };

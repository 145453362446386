import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "components";
import { useUsers } from "hooks";
import { ToastContext } from "contexts";

function RemoveUser() {
  const { id } = useParams();
  const { getUser, deleteUser } = useUsers();
  const { toast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [pending, setPending] = useState(false);

  const onClick = () => {
    if (!id) throw new Error("id is required");

    const user = getUser(id);

    if (!user) throw new Error("user is required");

    setPending(true);

    toast
      .promise(deleteUser(id), {
        pending: "Удаление пользователя...",
        success: "Пользователь успешно удален!",
        error: "Ошибка при удалении пользователя!",
      })
      .then(() => {
        navigate("/admin/users");
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <Button disabled={pending} onClick={onClick}>
      Удалить
    </Button>
  );
}

export { RemoveUser };

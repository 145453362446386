import { useCallback, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { ButtonIcon } from "components";
import { DrawContext } from "contexts";
import styles from "./styles.module.scss";

interface ExpandToFullScreenProps {
  className?: string;
}

function ExpandToFullScreen({ className }: ExpandToFullScreenProps) {
  const { stageRef } = useContext(DrawContext);
  const [showPreview, setShowPreview] = useState(false);
  const [preview, setPreview] = useState("");
  const [oldScale, setOldScale] = useState(null);
  const [oldPosition, setOldPosition] = useState(null);

  const handleClick = async () => {
    if (!stageRef.current) return;

    setOldScale(stageRef.current?.scale());
    setOldPosition(stageRef.current?.position());

    stageRef.current.scale({ x: 1, y: 1 });
    stageRef.current.position({ x: 0, y: 0 });

    setShowPreview(true);

    const img = await stageRef.current.toImage();

    setPreview(img.src);
  };

  const closePreview = () => {
    stageRef.current.scale(oldScale);
    stageRef.current.position(oldPosition);
    setShowPreview(false);
  };

  const closePreviewByEsc = useCallback(
    (e) => {
      if (e.code === "Escape") {
        stageRef.current.scale(oldScale);
        stageRef.current.position(oldPosition);
        setShowPreview(false);
      }
    },
    [oldPosition, oldScale, stageRef],
  );

  useEffect(() => {
    document.addEventListener("keyup", closePreviewByEsc);

    return () => {
      document.removeEventListener("keyup", closePreviewByEsc);
    };
  }, [closePreviewByEsc]);

  return (
    <>
      {showPreview
        ? createPortal(
            <div className={styles.container}>
              <ButtonIcon className={styles.icon} onClick={closePreview} iconType='resizeMin' />
              <img src={preview} alt='preview' className={styles.img} />
            </div>,
            document.body,
          )
        : null}
      <ButtonIcon className={className} onClick={handleClick} iconType='resizeMax' />
    </>
  );
}

export { ExpandToFullScreen };

import { makeAutoObservable, observable } from "mobx";
import { Id, Pagination, User } from "types";
import { toJS } from "libs";

class UsersStore {
  private _users = observable<User>([]);

  private _pagination: Pagination = { current_page: 1, count_pages: NaN };

  constructor() {
    makeAutoObservable(this);
  }

  get users(): User[] {
    return toJS(this._users);
  }

  set users(users) {
    this._users = observable<User>(users);
  }

  get pagination() {
    return toJS(this._pagination);
  }

  set pagination(pagination: Pagination) {
    this._pagination = pagination;
  }

  getUser(id: Id) {
    const user = this._users.find((u) => u.id === Number(id));

    if (!user) {
      return undefined;
    }

    return toJS(user);
  }

  updateUser(user: User) {
    const index = this._users.findIndex((u) => u.id === Number(user.id));
    this._users.spliceWithArray(index, 1, [user]);
  }

  removeUser(user: User) {
    const index = this._users.findIndex((u) => u.id === Number(user.id));
    this._users.spliceWithArray(index, 1);
  }
}

const usersStore = new UsersStore();
export { usersStore };

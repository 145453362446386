import { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { SubmitHandler, useForm } from "react-hook-form";
import cn from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { ReactSelect, Button } from "components";
import { useOrganisations, usePresets } from "hooks";
import { ToastContext } from "contexts";
import styles from "./styles.module.scss";

interface EditPresetAccessProps {
  close: () => void;
}

interface Values {
  organisations: { value: number; label: string }[];
}

function EditPresetAccess({ close }: EditPresetAccessProps) {
  const { id } = useParams();
  const { requireOrganisationsByKeyword, pending } = useOrganisations();
  const { getPreset, updatePresetAllow } = usePresets();
  const { toast } = useContext(ToastContext);
  const [isUpdateAllowPending, setIsUpdateAllowPending] = useState(false);
  const navigate = useNavigate();

  const preset = getPreset(id as string);
  const { control, handleSubmit } = useForm<Values>();

  const fetchOrganisationsByKeyword = async (keyword: string) => {
    const res = await requireOrganisationsByKeyword(keyword);
    return res.map((o) => ({ value: o.id, label: o.name }));
  };

  const onSubmit: SubmitHandler<Values> = (values) => {
    setIsUpdateAllowPending(true);

    toast
      .promise(
        updatePresetAllow(
          id as string,
          values.organisations?.map((o) => o.value) ??
            preset?.organisations.map((o) => o.organisation_id),
        ),
        {
          pending: "Обновление пресета...",
          success: "Пресет успешно обновлен!",
          error: "Ошибка при обновлении пресета!",
        },
      )
      .then(() => {
        navigate("/admin/presets");
      })
      .finally(() => {
        setIsUpdateAllowPending(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.aboutContainer}>
        <div className={styles.aboutItem}>
          <p className={styles.aboutItemLabel}>Название пресета</p>
          <p className={styles.aboutItemValue}>{preset?.name}</p>
        </div>
      </div>
      <div className={styles.search}>
        <ReactSelect
          isMulti
          callback={fetchOrganisationsByKeyword}
          isAsync
          control={control}
          name='organisations'
          label='Доступен организациям'
          placeholder='Поиск...'
          pending={pending}
          value={preset?.organisations.map((o) => ({ label: o.name, value: o.id }))}
        />
      </div>
      <div className={cn(styles.actions, styles.actions_container)}>
        <Button disabled={isUpdateAllowPending} type='submit'>
          Сохранить
        </Button>
        <Button className={styles.cancel} onClick={close}>
          Отмена
        </Button>
      </div>
    </form>
  );
}

const EditPresetAccessComponent = observer(EditPresetAccess);
export { EditPresetAccessComponent as EditPresetAccess };

import { useState } from "react";
import Select, { Options } from "react-select";
import AsyncSelect from "react-select/async";
import { Controller, Control } from "react-hook-form";
import cn from "classnames";
import styles from "./reactSelect.module.scss";

export interface Option {
  value: string | number;
  label: string;
}

interface ReactSelectProps {
  isAsync?: boolean;
  label: string;
  placeholder: string;
  name: string;
  className?: string;
  control: Control<any, any, any>;
  options?: Options<Option>;
  callback?: (inputValue: string) => void;
  pending?: boolean;
  error?: string;
  required?: boolean;
  value?: Option | Option[];
  isMulti?: boolean;
}

let timeoutId: NodeJS.Timeout;

function ReactSelect({
  isAsync,
  label,
  placeholder,
  name,
  className,
  control,
  options = [],
  callback,
  pending,
  error,
  required,
  value,
  isMulti,
}: ReactSelectProps) {
  const [option, setOption] = useState<any>(value);

  const loadOptions = (inputValue: string) =>
    new Promise<Options<Option>>((resolve) => {
      clearTimeout(timeoutId);

      if (inputValue.trim() !== "") {
        timeoutId = setTimeout(() => {
          resolve((callback as any)(inputValue));
        }, 1000);
      } else {
        resolve((callback as any)(inputValue));
      }
    });

  const initDefaultInputValue = () => {
    if (Array.isArray(value)) return undefined;
    return value?.label;
  };

  if (isAsync && callback) {
    return (
      <div className={styles.container}>
        <label className={styles.container_label}>{label}</label>
        <Controller
          defaultValue={value}
          name={name}
          control={control}
          render={({ field }) => (
            <AsyncSelect
              {...field}
              noOptionsMessage={() => "Ничего не найдено"}
              required={required}
              cacheOptions
              defaultOptions
              defaultInputValue={initDefaultInputValue()}
              loadOptions={loadOptions}
              classNamePrefix='react-select'
              placeholder={placeholder}
              className={cn(className, { [styles.container_error]: !!error })}
              isLoading={pending}
              isMulti={isMulti}
            />
          )}
        />
        <span className={styles.container_error_msg}>{error}</span>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <label className={styles.container_label}>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            noOptionsMessage={() => "Ничего не найдено"}
            required={required}
            options={options}
            className={cn(className, { [styles.container_error]: !!error })}
            classNamePrefix='react-select'
            placeholder={placeholder}
            isLoading={pending}
            value={option}
            onChange={(e) => {
              field.onChange(e);
              setOption(e);
            }}
            isMulti={isMulti}
          />
        )}
      />
      <span className={styles.container_error_msg}>{error}</span>
    </div>
  );
}

export { ReactSelect };

import { useContext, useState } from "react";
import { ToastContext } from "contexts/toastContext";
import { Button } from "components";
import { useManagers } from "hooks";
import styles from "./styles.module.scss";

export function DownloadManagers() {
  const { downloadManagers } = useManagers();
  const { toast } = useContext(ToastContext);
  const [pending, setPending] = useState(false);

  const download = () => {
    setPending(true);

    toast
      .promise(downloadManagers(), {
        pending: "Скачивание файла...",
        success: "Файл успешно скачан!",
        error: "Ошибка при скачивании файла!",
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <Button disabled={pending} onClick={download} className={styles.button}>
      Выгрузить CSV-файл
    </Button>
  );
}

import { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContext } from "contexts/toastContext";
import { Input, Button } from "components";
import { code } from "services/admin/adminService";
import { isError } from "libs/api";
import styles from "./styles.module.scss";

interface EnterCodeProps {
  className?: string;
}

interface Values {
  code: string;
}

interface Errors {
  code: string[];
}

export function EnterCode({ className = "" }: EnterCodeProps) {
  const { register, handleSubmit } = useForm<Values>();
  const { toast } = useContext(ToastContext);
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<Errors | null>(null);
  const [searchParams] = useSearchParams();

  const onSubmit: SubmitHandler<Values> = (data) => {
    setPending(true);

    toast
      .promise(code(data.code), {
        pending: "Авторизация...",
        success: "Авторизация успешна!",
        error: "Ошибка авторизации!",
      })
      .then(() => {
        navigate("/admin");
      })
      .catch((e) => {
        if (isError(e) && e.response) {
          setErrors(e.response.data.error);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <form
      className={className}
      style={{ maxWidth: 465, paddingTop: 20 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <p className={styles.subTitle}>
        Мы отправили код подтверждения на электронную почту {searchParams.get("email")}. Введите его
        для входа в аккаунт
      </p>
      <Input
        register={register}
        error={errors?.code?.toString()}
        label='Код из письма'
        placeholder='Введите код'
        name='code'
      />
      <Button disabled={pending} type='submit'>
        Продолжить
      </Button>
    </form>
  );
}

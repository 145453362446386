import { useState } from "react";
import { PageWrapper } from "components";
import { DecorateUserPhoto } from "features/decorateUserPhoto";
import { DrawProvider } from "contexts";
import { Layers } from "features/layers";
import { DownloadUserPhoto } from "features/downloadUserPhoto";
import { ConfigureBrushTool } from "features/configureBrushTool";
import { ConfigureEraserTool } from "features/configureEraserTool";
import { ExpandToFullScreen } from "features/expandToFullScreen";
import ActionsPanel from "../PresetPage/Sections/ActionsSection";
import DecorateSection from "../../../components/DecorateSection/DecorateSection";
import styles from "./userPhotoPage.module.scss";

function UserPhotoPage() {
  const [activeTab, setTab] = useState(1);

  return (
    <PageWrapper>
      <div className={styles.container}>
        <DrawProvider>
          <ActionsPanel
            activeTab={activeTab}
            setTab={(v) => setTab(v)}
            layersFeature={<Layers />}
          />
          <DecorateSection
            variant='custom'
            drawFeature={<DecorateUserPhoto />}
            downloadFeature={<DownloadUserPhoto />}
            configureBrushFeature={<ConfigureBrushTool id='#tool-brush' />}
            configureEraserFeature={<ConfigureEraserTool id='#tool-eraser' />}
            expandToFullScreenFeature={<ExpandToFullScreen />}
          />
        </DrawProvider>
      </div>
    </PageWrapper>
  );
}

export { UserPhotoPage };

import { createContext, useCallback, useReducer, useRef } from "react";
import { Action, Tool } from "types";
import { BRUSH_SIZES, TOOLS, ERASER_SIZES } from "../constants";

const DrawContext = createContext(Object.create(null));

interface State {
  tool: Tool;
  color: any;
  brushThin: number;
  layers: any[];
  opacity: number;
  eraserThin: number;
  isVisibleHelp: boolean;
  isResetAll: boolean;
  textureInside: any[];
  timeOfDay: "день" | "ночь";
  presetTextures: any[];
  textureTypes: any;
  textureImgs: any[];
  textures: [];
}

const initialState: State = {
  tool: "brush",
  color: { hex: "#ffffff" },
  brushThin: BRUSH_SIZES[7],
  layers: [],
  opacity: 1,
  eraserThin: ERASER_SIZES[7],
  isVisibleHelp: true,
  isResetAll: false,
  textureInside: [],
  timeOfDay: "день",
  presetTextures: [],
  textureTypes: Object.create(null),
  textureImgs: [],
  textures: [],
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "changeTool":
      return { ...state, tool: action.payload };
    case "changeColor":
      return { ...state, color: action.payload };
    case "changeBrushThin":
      return { ...state, brushThin: action.payload };
    case "changeLayers":
      return { ...state, layers: action.payload };
    case "changeOpacity":
      return { ...state, opacity: action.payload };
    case "changeEraserThin":
      return { ...state, eraserThin: action.payload };
    case "changeVisibleHelp":
      return { ...state, isVisibleHelp: action.payload };
    case "changeIsResetAll":
      return { ...state, isResetAll: action.payload };
    case "changeTextureInside":
      return { ...state, textureInside: action.payload };
    case "changeTimeOfDay":
      return { ...state, timeOfDay: action.payload };
    case "setPresetTextures":
      return { ...state, presetTextures: action.payload };
    case "setTextureTypes":
      return { ...state, textureTypes: action.payload };
    case "setTextureImgs":
      return { ...state, textureImgs: action.payload };
    case "setTextures":
      return { ...state, textures: action.payload };
    default:
      return { ...state };
  }
};

function DrawProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const stageRef = useRef(null);
  const selectedColorRef = useRef({ hex: "#ffffff" });
  const toolRef = useRef(TOOLS.brush);

  const changeVisibleHelp = useCallback((checked) => {
    dispatch({ type: "changeVisibleHelp", payload: checked });
  }, []);

  const setColor = useCallback((color) => {
    dispatch({ type: "changeColor", payload: color });
  }, []);

  const changeColor = useCallback(
    (value) => {
      setColor({ ...value, hex: `#${value.hex}` });
      selectedColorRef.current = { ...value, hex: `#${value.hex}` };
    },
    [setColor],
  );

  const setIsResetAll = useCallback((isResetAll) => {
    dispatch({ type: "changeIsResetAll", payload: isResetAll });
  }, []);

  const resetAll = useCallback(() => {
    setIsResetAll((prevState) => !prevState);
  }, [setIsResetAll]);

  const setTool = useCallback((tool) => {
    dispatch({ type: "changeTool", payload: tool });
    toolRef.current = tool;
  }, []);

  const setBrushThin = useCallback((brushThin) => {
    dispatch({ type: "changeBrushThin", payload: brushThin });
  }, []);

  const setLayers = useCallback((layers) => {
    dispatch({ type: "changeLayers", payload: layers });
  }, []);

  const setOpacity = useCallback((opacity) => {
    dispatch({ type: "changeOpacity", payload: opacity });
  }, []);

  const setEraserThin = useCallback((eraserThin) => {
    dispatch({ type: "changeEraserThin", payload: eraserThin });
  }, []);

  const setTextureInside = useCallback((textureInside) => {
    dispatch({ type: "changeTextureInside", payload: textureInside });
  }, []);

  const setTimeOfDay = useCallback((timeOfDay) => {
    dispatch({ type: "changeTimeOfDay", payload: timeOfDay });
  }, []);

  const setPresetTextures = useCallback((presetTextures) => {
    dispatch({ type: "setPresetTextures", payload: presetTextures });
  }, []);

  const setTextureTypes = useCallback((textureTypes) => {
    dispatch({ type: "setTextureTypes", payload: textureTypes });
  }, []);

  const setTextureImgs = useCallback((textureImgs) => {
    dispatch({ type: "setTextureImgs", payload: textureImgs });
  }, []);

  const setTextures = useCallback((textures) => {
    dispatch({ type: "setTextures", payload: textures });
  }, []);

  return (
    <DrawContext.Provider
      value={{
        tool: state.tool,
        setTool,
        toolRef,
        color: state.color,
        brushThin: state.brushThin,
        setBrushThin,
        layers: state.layers,
        setLayers,
        stageRef,
        opacity: state.opacity,
        setOpacity,
        eraserThin: state.eraserThin,
        setEraserThin,
        isVisibleHelp: state.isVisibleHelp,
        changeVisibleHelp,
        changeColor,
        selectedColorRef,
        isResetAll: state.isResetAll,
        setIsResetAll,
        resetAll,
        textureInside: state.textureInside,
        setTextureInside,
        timeOfDay: state.timeOfDay,
        setTimeOfDay,
        presetTextures: state.presetTextures,
        setPresetTextures,
        textureTypes: state.textureTypes,
        setTextureTypes,
        textureImgs: state.textureImgs,
        setTextureImgs,
        textures: state.textures,
        setTextures,
      }}
    >
      {children}
    </DrawContext.Provider>
  );
}

export { DrawContext, DrawProvider };

import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Input } from "components";
import { login } from "services/admin/adminService";
import { APP } from "constants/index";
import { ToastContext } from "contexts";
import { User } from "types";

interface LoginProps {
  className: string;
}

interface Values extends Pick<User, "email"> {
  password: string;
}

function Login({ className }: LoginProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Values>();
  const navigate = useNavigate();
  const [pending, setPending] = useState(false);
  const { toast } = useContext(ToastContext);

  const onSubmit: SubmitHandler<Values> = (data) => {
    setPending(true);

    toast
      .promise(login<Values>(data), {})
      .then((token) => {
        localStorage.setItem(`${APP}_token`, token);
        navigate(`/admin/code?email=${data.email}`);
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <Input
        error={errors.email?.message}
        name='email'
        register={register}
        placeholder='Введите e-mail'
        label='E-mail'
      />
      <Input
        error={errors.password?.message}
        name='password'
        register={register}
        placeholder='Введите пароль'
        label='Пароль'
        type='password'
      />
      <Button disabled={pending} type='submit'>
        Войти
      </Button>
    </form>
  );
}

export { Login };

import styles from "./footer.module.scss";

// @ts-ignore
import policy from "./files/policy_privecy.pdf";
// @ts-ignore
import terms from "./files/terms.pdf";
// @ts-ignore
import license from "./files/license.pdf";

function Footer() {
  return (
    <div className={styles.Footer_root}>
      <p className={styles.Footer_name}>INTERRA DECO GROUP, {new Date().getFullYear()}</p>
      <div className={styles.Footer_links}>
        <a href={license} target='_blank' rel='noreferrer'>
          Лицензии
        </a>
        <a href={policy} target='_blank' rel='noreferrer'>
          Политика конфиденциальности
        </a>
        <a href={terms} target='_blank' rel='noreferrer'>
          Условия использования
        </a>
      </div>
    </div>
  );
}

export default Footer;

import { api } from "libs/api";

// все цвета
async function getColors(data) {
  const params = new URLSearchParams(data);
  const res = await api.get(`/catalog/colors${data ? `?${params}` : ""}`);
  return res.data.data;
}

// акцентные цвета
async function getAccentColors(color, limit = 6) {
  const res = await api.get(`/catalog/accent-colors/${color}/${limit}`);
  return res.data.data;
}

// комплиментарные цвета
async function getComplimentaryColors(color, limit = 6) {
  const res = await api.get(`/catalog/complimentary-colors/${color}/${limit}`);
  return res.data.data;
}

// похожие цвета
async function getSimilarColors(color, limit = 6) {
  const res = await api.get(`/catalog/similar-colors/${color}/${limit}`);
  return res.data.data;
}

export { getColors, getAccentColors, getComplimentaryColors, getSimilarColors };

import { useNavigate } from "react-router-dom";
import { Modal } from "components";
import { EditPresetAccess } from "features/admin";
import styles from "../styles.module.scss";

function EditPresetPage() {
  const navigate = useNavigate();

  const moveToPresets = () => {
    navigate("/admin/presets");
  };

  return (
    <Modal open width={430} className={styles.addUser} close={moveToPresets}>
      <h3>Редактировать доступ к пресету</h3>
      <EditPresetAccess close={moveToPresets} />
    </Modal>
  );
}

export { EditPresetPage };

import { useContext, useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";
import { ButtonIcon, Loader } from "components";
import { ColorsContext } from "contexts";
import { Color } from "types";
import styles from "./colourpage.module.scss";

function ColorView() {
  const [isResize, setIsResize] = useState(false);
  const { paint, getColor, colorsParams, isPending } = useContext(ColorsContext);
  const { colourName } = useParams();
  const color: Color | undefined = getColor(colourName ?? colorsParams?.color);

  const toggleResize = () => {
    setIsResize(!isResize);
  };

  const renderPreview = () => {
    if (isResize) {
      return (
        <>
          {createPortal(
            <div className={styles.previewColor__full}>
              <img
                loading='lazy'
                className={styles.previewColor__img}
                src={paint?.effect?.max}
                alt={paint?.effect?.name}
              />
              <div style={{ background: `#${color?.hex}`, height: "100%", width: "100%" }} />
              <ButtonIcon
                onClick={toggleResize}
                className={styles.previewColor__icon}
                iconType='resizeMin'
              />
            </div>,
            document.body,
          )}
        </>
      );
    }

    return (
      <>
        <img
          loading='lazy'
          className={styles.previewColor__img}
          src={paint?.effect?.min}
          alt={paint?.effect?.name}
        />
        <div className={styles.previewColor__color} style={{ background: `#${color?.hex}` }} />
        <ButtonIcon
          onClick={toggleResize}
          className={styles.previewColor__icon}
          iconType='resizeMax'
        />
      </>
    );
  };

  return <div className={styles.previewColor}>{isPending ? <Loader /> : renderPreview()}</div>;
}

export { ColorView };

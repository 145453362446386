import { Link } from "react-router-dom";
import classNames from "classnames";
import { PageWrapper } from "components";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as Grid } from "assets/images/grid.svg";
import styles from "./mainpage.module.scss";

function MainPage() {
  return (
    <PageWrapper>
      <div className={styles.MainPage_root}>
        <Link className={styles.MainPage_card} to='/colours'>
          <div className={styles.MainPage_card__content}>
            <Grid />
          </div>
          <div className={styles.MainPage_card__text}>Цвета</div>
        </Link>
        <Link className={styles.MainPage_card} to='/seasons'>
          <div className={classNames(styles.MainPage_card__content, styles.seasons)} />
          <div className={styles.MainPage_card__text}>Сезонные подборки</div>
        </Link>
        <Link className={styles.MainPage_card} to='/editor/presets'>
          <div className={classNames(styles.MainPage_card__content, styles.constuctor)} />
          <div className={styles.MainPage_card__text}>Конструктор цветов в интерьере</div>
        </Link>
        <Link className={styles.MainPage_card} to='/upload-photo'>
          <div className={styles.MainPage_card__content}>
            <div className={styles.MainPage_plusWrapper}>
              <Plus />
            </div>
          </div>
          <div className={styles.MainPage_card__text}>Загрузить фото</div>
        </Link>
      </div>
    </PageWrapper>
  );
}

export default MainPage;

import { Outlet } from "react-router-dom";
import { PageWrapper } from "components";

function AdminPage() {
  return (
    <PageWrapper isAdmin>
      <Outlet />
    </PageWrapper>
  );
}

export { AdminPage };

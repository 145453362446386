import cn from "classnames";
import styles from "./switcher.module.scss";

interface SwitcherProps {
  className?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

function Switcher({ className, checked, onChange }: SwitcherProps) {
  return (
    <div className={cn(styles.Switcher_root, className)}>
      <label className={styles.Switcher_switch}>
        <input type='checkbox' checked={checked} onChange={() => onChange(!checked)} />
        <span className={cn(styles.Switcher_slider, styles.Switcher_round)} />
      </label>
    </div>
  );
}

export { Switcher };

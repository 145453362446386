import { useCallback, useState } from "react";
import { Id, Organisation, Pagination } from "types";
import { organisationsStore, usersStore } from "stores";
import OrganisationsService from "services/admin/organisationsService";

export type Values = Organisation;

interface UseOrganisations {
  organisations: Organisation[];
  pagination: Pagination;
  requireOrganisationsByPage: (page?: number) => void;
  requireOrganisationsByKeyword: (keyword: string) => Promise<Organisation[]>;
  pending: boolean;
  createOrganisation: (organisation: Omit<Organisation, "id">) => Promise<void>;
  removeOrganisation: (id: Id) => Promise<void>;
  getOrganisation: (id: Id) => Organisation | undefined;
  updateOrganisation: (values: Values) => Promise<void>;
  clear: () => void;
}

function useOrganisations(): UseOrganisations {
  const [pending, setPending] = useState(false);

  const requireOrganisationsByKeyword = useCallback(
    async (keyword: string): Promise<Organisation[]> => {
      setPending(true);

      const res = await OrganisationsService.requireOrganisations({ page: 1, search: keyword });

      setPending(false);

      return res.organisations;
    },
    [],
  );

  const requireOrganisationsByPage = useCallback((page?: number) => {
    setPending(true);

    OrganisationsService.requireOrganisations({
      page: page || usersStore.pagination.current_page,
    })
      .then((res) => {
        organisationsStore.organisations = res.organisations;
        organisationsStore.pagination = res.pagination;
      })
      .finally(() => {
        setPending(false);
      });
  }, []);

  const createOrganisation = async (organisation: Omit<Organisation, "id">) => {
    await OrganisationsService.createOrganisation(organisation);
    const res = await OrganisationsService.requireOrganisations({
      page: organisationsStore.pagination.current_page,
    });
    organisationsStore.organisations = res.organisations;
    organisationsStore.pagination = res.pagination;
  };

  const removeOrganisation = async (id: Id) => {
    await OrganisationsService.destroyOrganisation(id);
    let page = organisationsStore.pagination.current_page;

    if (organisationsStore.organisations.length === 1) page--;

    const res = await OrganisationsService.requireOrganisations({ page });
    organisationsStore.organisations = res.organisations;
    organisationsStore.pagination = res.pagination;
  };

  const getOrganisation = (id: Id) => organisationsStore.getOrganisation(id);

  const updateOrganisation = async (values: Values) => {
    const res = await OrganisationsService.updateOrganisation(values);
    organisationsStore.updateOrganisation(res);
  };

  const clear = useCallback(() => {
    organisationsStore.organisations = [];
    organisationsStore.pagination = { current_page: NaN, count_pages: 1 };
  }, []);

  return {
    organisations: organisationsStore.organisations,
    pagination: organisationsStore.pagination,
    requireOrganisationsByPage,
    requireOrganisationsByKeyword,
    pending,
    createOrganisation,
    removeOrganisation,
    getOrganisation,
    updateOrganisation,
    clear,
  };
}

export { useOrganisations };

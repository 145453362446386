import { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { PageWrapper } from "components";
import { useColors, useOtherColors } from "libs";
import { Color } from "types";
import { ColorsContext } from "contexts";
import { ColorView } from "./colorView";
import { ColorDescription } from "./colorDescription";
import { Palette } from "./palette";
import { OtherColors } from "./otherColors";
import styles from "./colourpage.module.scss";

function ColourPage() {
  const { colourName } = useParams();
  const { requireColor } = useColors();
  const { requireOtherColors, isColorsPending } = useOtherColors();
  const { colorsParams, setColorsParams } = useContext(ColorsContext);
  const colorParamsRef = useRef(colorsParams);

  useEffect(() => {
    colorParamsRef.current = colorsParams;
  }, [colorsParams]);

  useEffect(() => {
    if (!colourName) return;

    requireColor({ ...colorParamsRef.current, color: colourName }).then((res) => {
      const color = res?.colors?.find((c: Color) => c.id === colourName);

      if (color) requireOtherColors(color);
    });
  }, [requireColor, colourName, requireOtherColors, setColorsParams]);

  useEffect(
    () => () => {
      const newColorsParams = { ...colorParamsRef.current };
      delete newColorsParams.color;
      setColorsParams(newColorsParams);
    },
    [setColorsParams],
  );

  return (
    <PageWrapper>
      <div className={styles.ColourPage_root}>
        <ColorDescription />
        <Palette />
        <OtherColors isPending={isColorsPending} />
        <ColorView />
      </div>
    </PageWrapper>
  );
}

export default ColourPage;

import { useNavigate, useParams, Navigate } from "react-router-dom";
import { Modal } from "components";
import { RemoveSeason } from "features/admin";
import styles from "../styles.module.scss";

export function RemoveSeasonPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return <Navigate to='/admin/seasons' />;

  const close = () => {
    navigate("/admin/seasons");
  };

  return (
    <Modal className={styles.addUser} width={430} open close={close}>
      <h3>
        Вы действительно хотите <br /> скрыть подборку?
      </h3>
      <RemoveSeason id={id} />
    </Modal>
  );
}

import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { Button, Input, Modal } from "components";
import { ReactComponent as AddedLightIcon } from "assets/icons/added_icon_light.svg";
import styles from "../styles.module.scss";

const colors = [
  { id: 1, hex: "345664" },
  { id: 2, hex: "645564" },
];

function EditPalettePage() {
  const navigate = useNavigate();

  const moveToPalettes = () => {
    navigate("/admin/season-palettes");
  };

  const renderColors = useMemo(
    () =>
      colors.map((c) => (
        <div className={styles.color} key={c.id} style={{ background: `#${c.hex}` }}>
          <div className={styles.color_info}>
            <span>F521</span>
            <span>Медиана</span>
          </div>
          <AddedLightIcon />
        </div>
      )),
    [],
  );

  return (
    <Modal className={styles.addUser} open width={716} padding={24} close={moveToPalettes}>
      <h3>Редактировать палитру</h3>
      <div className={styles.addUser_aboutContainer}>
        <div className={styles.addUser_aboutItem}>
          <p className={styles.addUser_aboutItemLabel}>Название палитры</p>
          <p className={styles.addUser_aboutItemValue}>Новый год</p>
        </div>
      </div>
      <div className={styles.addUser_search}>
        <p className={styles.addUser_searchLabel}>Цвета в палитре</p>
        <Input placeholder='Поиск...' icon='search' position='right' />
      </div>
      <div className={styles.colors}>{renderColors}</div>
      <div className={cn(styles.addUser_actions, styles.addUser_actions_container)}>
        <Button>Сохранить</Button>
        <Button className={styles.addUser_actions_cancel} onClick={moveToPalettes}>
          Отмена
        </Button>
      </div>
    </Modal>
  );
}

export { EditPalettePage };

import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components";
import { Id } from "types";
import { useAdminSeasons } from "hooks";
import { ToastContext } from "contexts";
import styles from "./styles.module.scss";

interface RemoveSeasonProps {
  id: Id;
}

export function RemoveSeason({ id }: RemoveSeasonProps) {
  const { toast } = useContext(ToastContext);
  const { removeSeasonById, requireSeasons } = useAdminSeasons();
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();

  const moveToSeasons = () => {
    navigate("/admin/seasons");
  };

  const remove = () => {
    setIsPending(true);

    toast
      .promise(removeSeasonById(id), {
        pending: "Скрытие подборки...",
        success: "Подборка успешно скрыта!",
        error: "Ошибка при скрытии подборки!",
      })
      .then(() => {
        requireSeasons();
        navigate("/admin/seasons");
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <div className={styles.actions} style={{ justifyContent: "center" }}>
      <Button disabled={isPending} onClick={remove}>
        Скрыть
      </Button>
      <Button className={styles.cancel} onClick={moveToSeasons}>
        Отмена
      </Button>
    </div>
  );
}

import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Konva from "konva";
import { Stage, Layer, Image as KonvaImage, Group } from "react-konva";
import { Html } from "react-konva-utils";
import cn from "classnames";
import { hexToRgb, rgbaToHex, useZoom } from "libs";
import { ColorsContext, DrawContext, HistoryContext, PresetsContext, ToastContext } from "contexts";
import { TOOLS } from "constants/index";
import { getSimilarColors } from "services";
import { Loader } from "components";
import styles from "./decoratePreset.module.scss";

const layerWidth = 980;
const layerHeight = 664;
const day = "день";
const night = "ночь";

function DecoratePreset() {
  const { presets, saveMasks } = useContext(PresetsContext);
  const {
    timeOfDay,
    isVisibleHelp,
    stageRef,
    tool,
    isResetAll,
    setIsResetAll,
    setTool,
    setTextures,
    textures,
  } = useContext(DrawContext);
  const { toast } = useContext(ToastContext);
  const { paint, setEyeDropperColors } = useContext(ColorsContext);
  const { createSnapshot } = useContext(HistoryContext);
  const [sizeAndScaleForLayers, setSizeAndScaleForLayers] = useState<any>(null);
  const [mainImage, setMainImage] = useState<any>(null);
  const [masks, setMasks] = useState<any[]>([]);
  const [helpImage, setHelpImage] = useState<any>(null);
  const [dayTextures, setDayTextures] = useState<any[]>([]);
  const [nightTextures, setNightTextures] = useState<any[]>([]);
  const [activeTextures, setActiveTextures] = useState<any[]>([]);
  const [isPending, setIsPending] = useState(true);
  const mainImagesRef = useRef<any[]>([]);
  const mainImageRef = useRef<any>(null);
  const dayTexturesRef = useRef<any[]>([]);
  const nightTexturesRef = useRef<any[]>([]);
  const { presetName } = useParams();
  const { handleWheel, zoomIn, zoomOut } = useZoom();
  const dayPreset = useMemo(
    () =>
      presets.find((p) => p.lighting === day && p.id.includes(presetName?.replace(`_${day}`, ""))),
    [presetName, presets],
  );
  const nightPreset = useMemo(
    () =>
      presets.find(
        (p) => p.lighting === night && p.id.includes(presetName?.replace(`_${day}`, "")),
      ),
    [presetName, presets],
  );
  const [activePreset, setActivePreset] = useState<any>(null);

  useEffect(() => {
    if (textures.length === 0) return;

    setActiveTextures(textures);
  }, [textures]);

  // очищаем текстуры из глобального стора
  useEffect(
    () => () => {
      setTextures([]);
      saveMasks([]);
    },
    [saveMasks, setTextures],
  );

  useEffect(() => {
    if (!dayPreset || !nightPreset) return;

    if (timeOfDay === day) {
      setActivePreset(dayPreset);
      return;
    }

    if (timeOfDay === night) {
      setActivePreset(nightPreset);
    }
  }, [timeOfDay, dayPreset, nightPreset]);

  // переключение дня и ночи
  useEffect(() => {
    if (timeOfDay === day && mainImageRef.current) {
      mainImageRef.current.image(mainImagesRef.current[0]);
      return;
    }

    if (timeOfDay === night && mainImageRef.current) {
      mainImageRef.current.image(mainImagesRef.current[1]);
    }
  }, [timeOfDay]);

  // переключение текстур на дневные или ночные
  useEffect(() => {
    if (dayTexturesRef.current.length === 0 || nightTexturesRef.current.length === 0) return;

    setActiveTextures((prevActiveTextures) => {
      const updatedTextures: any[] = [];

      if (timeOfDay === night) {
        for (const nt of nightTexturesRef.current) {
          const dayTexture = prevActiveTextures.find((dt) => dt.index === nt.index);

          updatedTextures.push({
            ...dayTexture,
            color: dayTexture.color,
            visible: dayTexture.visible,
            image: dayTexture.nightEffect !== null ? dayTexture.nightEffect : nt.image,
            ncs: dayTexture.ncs,
          });
        }

        nightTexturesRef.current = updatedTextures;
      }

      if (timeOfDay === day) {
        for (const dt of dayTexturesRef.current) {
          const nightTexture = prevActiveTextures.find((nt) => nt.index === dt.index);

          updatedTextures.push({
            ...nightTexture,
            color: nightTexture.color,
            visible: nightTexture.visible,
            image: nightTexture.effect !== null ? nightTexture.effect : dt.image,
            ncs: nightTexture.ncs,
          });
        }

        dayTexturesRef.current = updatedTextures;
      }

      setTextures(updatedTextures);

      return updatedTextures;
    });
  }, [setTextures, timeOfDay]);

  const loadMainImage = useCallback(() => {
    if (!activePreset || mainImagesRef.current.length === 2) return;

    setIsPending(true);

    const mainImg = new Image();

    mainImg.src = activePreset.image;

    mainImg.onload = () => {
      const scale = Math.min(layerWidth / mainImg.width, layerHeight / mainImg.height);
      const newWidth = mainImg.width * scale;
      const newHeight = mainImg.height * scale;

      setMainImage(mainImg);
      mainImagesRef.current.push(mainImg);

      mainImg.width = newWidth;
      mainImg.height = newHeight;

      setSizeAndScaleForLayers({
        width: newWidth,
        height: newHeight,
      });

      setIsPending(false);
    };
  }, [activePreset]);

  const loadHelpImage = useCallback(() => {
    if (!activePreset || !sizeAndScaleForLayers || helpImage) return;

    const helpImg = new Image();

    helpImg.src = activePreset.help;

    helpImg.onload = () => {
      helpImg.width = sizeAndScaleForLayers.width;
      helpImg.height = sizeAndScaleForLayers.height;

      setHelpImage(helpImg);
    };
  }, [activePreset, helpImage, sizeAndScaleForLayers]);

  // сброс всего
  useEffect(() => {
    if (!isResetAll) return;

    setIsResetAll(false);

    setActiveTextures((prevActiveTextures) => {
      const updatedTextures = prevActiveTextures.map((t) => ({
        ...t,
        color: null,
        effect: null,
        visible: false,
        nightEffect: null,
      }));

      setTextures(updatedTextures);
      setDayTextures(updatedTextures);
      setNightTextures(updatedTextures);

      return updatedTextures;
    });
  }, [isResetAll, setIsResetAll, setTextures]);

  useEffect(() => {
    loadMainImage();
  }, [loadMainImage]);

  useEffect(() => {
    loadHelpImage();
  }, [loadHelpImage]);

  // загрузка масок
  useEffect(() => {
    if (!activePreset || !sizeAndScaleForLayers || masks.length > 0) return;

    activePreset.layouts.forEach((l, i) => {
      const maskImg = new Image();

      maskImg.crossOrigin = "Anonymous";
      maskImg.src = l.mask;
      maskImg.width = sizeAndScaleForLayers.width;
      maskImg.height = sizeAndScaleForLayers.height;

      maskImg.onload = () => {
        setMasks((prevMasks) => [
          ...prevMasks,
          { textures: l.textures, image: maskImg, index: i, name: l.name },
        ]);
      };
    });
  }, [activePreset, sizeAndScaleForLayers, masks.length]);

  useEffect(() => {
    if (masks.length === 0) return;

    saveMasks(masks);
  }, [masks, saveMasks]);

  // если доступен window.EyeDropper используем его
  // иначе отработает handleClickOnStage
  // https://app.weeek.net/ws/435314/task/4865
  useEffect(() => {
    if (tool !== TOOLS.eyeDropper) return;
    // @ts-ignore
    if (!window.EyeDropper) return;

    // @ts-ignore
    const eyeDropper = new EyeDropper();
    eyeDropper.open().then((res) => {
      getSimilarColors(res.sRGBHex.replace("#", ""), 4)
        .then((res1) => {
          setEyeDropperColors(res1);
        })
        .finally(() => {
          setTool(TOOLS.brush);
        });
    });
  }, [setEyeDropperColors, setTool, tool]);

  const handleClickOnStage = useCallback(
    (e) => {
      if (tool === TOOLS.eyeDropper) {
        const canvas = stageRef.current.toCanvas();
        const ctx = canvas.getContext("2d");
        const { x } = e.evt;
        const { y } = e.evt;
        const imageData = ctx.getImageData(x, y, 1, 1).data;
        const pixelColor = { r: imageData[0], g: imageData[1], b: imageData[2], a: imageData[3] };

        // если цвет не распознан, то показываем ошибку
        // https://app.weeek.net/ws/435314/task/4865
        if (pixelColor.r === 0 && pixelColor.g === 0 && pixelColor.b === 0) {
          toast.error(
            "Цвет не был распознан, попробуйте приблизить изображение и повторить снова",
            { position: "top-right" },
          );
          return;
        }

        const hexColor = rgbaToHex(pixelColor.r, pixelColor.g, pixelColor.b);

        getSimilarColors(hexColor, 4)
          .then((res) => {
            setEyeDropperColors(res);
          })
          .finally(() => {
            setTool(TOOLS.brush);
            stageRef.current.off("click");
          });
      }

      if (tool === TOOLS.zoomIn) {
        zoomIn();
      }

      if (tool === TOOLS.zoomOut) {
        zoomOut();
      }
    },
    [setEyeDropperColors, setTool, stageRef, toast, tool, zoomIn, zoomOut],
  );

  // загрузка дневных текстур
  useEffect(() => {
    if (!dayPreset || !sizeAndScaleForLayers || dayTextures.length > 0) return;

    const insideTextures: any[] = [];
    const newTextures: any[] = [];

    dayPreset.layouts.forEach((l) => {
      insideTextures.push({
        name: l.name,
        texture: l.textures.filter((t) => t.base)[0].texture,
      });
    });

    const promises = insideTextures.map(
      (it, i) =>
        new Promise((resolve, reject) => {
          const textureImg = new Image();

          textureImg.crossOrigin = "Anonymous";
          textureImg.src = it.texture;
          textureImg.width = sizeAndScaleForLayers.width;
          textureImg.height = sizeAndScaleForLayers.height;

          textureImg.onload = () => {
            newTextures.push({
              index: i,
              image: textureImg,
              color: null,
              effect: null,
              name: it.name,
              visible: false,
              src: "",
              nightEffect: null,
              oldImage: textureImg,
              ncs: "",
            });
            resolve("");
          };

          textureImg.onerror = (error) => {
            reject(error);
          };
        }),
    );

    Promise.all(promises).then(() => {
      dayTexturesRef.current = newTextures;
      setDayTextures(newTextures);
      setActiveTextures(newTextures);
      setTextures(newTextures);

      createSnapshot(newTextures);
    });
  }, [dayPreset, sizeAndScaleForLayers, dayTextures.length, setTextures, createSnapshot]);

  // загрузка ночных текстур
  useEffect(() => {
    if (!nightPreset || nightTextures.length > 0 || !sizeAndScaleForLayers) return;

    const insideTextures: any[] = [];
    const newTextures: any[] = [];

    nightPreset.layouts.forEach((l) => {
      insideTextures.push({
        name: l.name,
        texture: l.textures.filter((t) => t.base)[0].texture,
      });
    });

    const promises = insideTextures.map(
      (it, i) =>
        new Promise((resolve, reject) => {
          const textureImg = new Image();
          textureImg.crossOrigin = "Anonymous";
          textureImg.src = it.texture;
          textureImg.width = sizeAndScaleForLayers.width;
          textureImg.height = sizeAndScaleForLayers.height;

          textureImg.onload = () => {
            newTextures.push({
              index: i,
              image: textureImg,
              color: null,
              effect: null,
              name: it.name,
              visible: false,
              src: "",
              nightEffect: null,
              oldImage: textureImg,
              ncs: "",
            });
            resolve("");
          };

          textureImg.onerror = (error) => {
            reject(error);
          };
        }),
    );

    Promise.all(promises).then(() => {
      nightTexturesRef.current = newTextures;
      setNightTextures(newTextures);
      setIsPending(false);
    });
  }, [nightTextures, nightPreset, sizeAndScaleForLayers]);

  const handleClickOnMask = useCallback(
    (mask) => () => {
      if (!paint || tool !== TOOLS.fillFood) return;
      setIsPending(true);

      const { index } = mask;
      const newTexture = { ...activeTextures.find((t) => t.name === mask.name) };
      const foundTexture = mask.textures.find((t) => t.name === paint.effect.name);

      newTexture.decoration = paint.decoration;
      newTexture.color = { ...hexToRgb(paint.color.hex) };
      newTexture.visible = true;
      newTexture.src = foundTexture.texture;
      newTexture.ncs = paint.color.ncs;
      newTexture.index = index;

      const dayImg = new Image();
      const nightImg = new Image();

      dayImg.crossOrigin = "Anonymous";
      dayImg.src = foundTexture.texture;
      nightImg.crossOrigin = "Anonymous";
      nightImg.src = foundTexture.texture.replace(day, night);
      newTexture.image = timeOfDay === day ? dayImg : nightImg;

      newTexture.effect = !foundTexture.base ? dayImg : null;
      newTexture.nightEffect = !foundTexture.base ? nightImg : null;

      Promise.allSettled([
        new Promise((resolve) => {
          dayImg.onload = () => resolve("");
        }),
        new Promise((resolve) => {
          nightImg.onload = () => resolve("");
        }),
      ])
        .then(() => {
          const newTextures = [...activeTextures.filter((t) => t.index !== index), newTexture];

          setActiveTextures(newTextures);
          setTextures(newTextures);
          createSnapshot(newTextures);
        })
        .finally(() => {
          setIsPending(false);
        });
    },
    [activeTextures, createSnapshot, paint, setTextures, timeOfDay, tool],
  );

  const renderMasks = useMemo(
    () =>
      masks.map((m, i) => (
        <KonvaImage
          onClick={handleClickOnMask(m)}
          opacity={0}
          key={`mask-${i}`}
          image={m.image}
          ref={(node) => {
            if (!node) return;
            node.cache();
            node.drawHitFromCache();
          }}
        />
      )),
    [masks, handleClickOnMask],
  );

  const renderTextures = useMemo(() => {
    if (!sizeAndScaleForLayers) return null;

    return textures.map((t, i) => (
      <KonvaImage
        red={t.color?.r}
        green={t.color?.g}
        blue={t.color?.b}
        filters={t.decoration === "Фасадная" ? undefined : [Konva.Filters.RGB]}
        key={`texture_${i}`}
        image={t.image}
        width={sizeAndScaleForLayers.width}
        height={sizeAndScaleForLayers.height}
        ref={(node) => {
          if (!node || !t.color) return;
          node.clearCache();
          node.cache();
        }}
        visible={t.visible}
      />
    ));
  }, [textures, sizeAndScaleForLayers]);

  const renderLoader = useMemo(() => {
    if (!isPending) return;

    return (
      <Layer>
        <Html
          divProps={{
            style: {
              background: "black",
              opacity: 0.5,
              height: "100%",
              width: "100%",
            },
          }}
        />
        <Html
          divProps={{
            style: {
              bottom: 0,
              right: 0,
            },
          }}
        >
          <Loader />
        </Html>
      </Layer>
    );
  }, [isPending]);

  return (
    <Stage
      ref={stageRef}
      width={layerWidth}
      height={layerHeight}
      onWheel={handleWheel}
      onClick={handleClickOnStage}
      draggable={false}
      className={cn(
        styles.container,
        { [styles.eyeDropperIcon]: tool === TOOLS.eyeDropper },
        { [styles.zoomInIcon]: tool === TOOLS.zoomIn },
        { [styles.zoomOutIcon]: tool === TOOLS.zoomOut },
        { [styles.fillFloodIcon]: tool === TOOLS.fillFood },
      )}
    >
      <Layer name='main-layer'>
        <KonvaImage ref={mainImageRef} image={mainImage} />
        <Group name='textures'>{renderTextures}</Group>
        <KonvaImage visible={isVisibleHelp} image={helpImage} />
      </Layer>
      <Layer>
        <Group>{renderMasks}</Group>
      </Layer>
      {renderLoader}
    </Stage>
  );
}

export { DecoratePreset };

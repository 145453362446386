import { api, isError } from "libs";

async function getPresets() {
  try {
    const res = await api.get("/catalog/presets");
    return res.data.data;
  } catch (e) {
    if (isError(e)) {
      console.error(e.message);
    }
  }
}

export { getPresets };

import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "components";
import { useManagers } from "hooks";
import { ToastContext } from "contexts";

function RemoveManager() {
  const { id } = useParams();
  const { getManager, deleteManager } = useManagers();
  const { toast } = useContext(ToastContext);
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();

  const onClick = () => {
    if (!id) throw new Error("id is required");

    const manager = getManager(id);

    if (!manager) throw new Error("manager is required");

    setPending(true);

    toast
      .promise(deleteManager(id), {
        pending: "Скрытие менеджера...",
        success: "Менеджер успешно скрыт!",
        error: "Ошибка при скрытии менеджера!",
      })
      .then(() => {
        navigate("/admin/managers");
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <Button disabled={pending} onClick={onClick}>
      Скрыть
    </Button>
  );
}

export { RemoveManager };

import { useContext, useMemo } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { ButtonIcon, PageWrapper } from "components";
import { DrawContext, PresetsContext } from "contexts";
import styles from "./presetspage.module.scss";

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 1,
  speed: 500,

  focusOnSelect: true,
  nextArrow: <ButtonIcon iconType='arrow_left' />,
  prevArrow: <ButtonIcon iconType='arrow_right' />,
};

function PresetsPage() {
  const { presets } = useContext(PresetsContext);
  const { setTimeOfDay } = useContext(DrawContext);

  const renderPresets = useMemo(
    () =>
      presets
        .filter((p) => p.lighting === "день")
        .map((p) => (
          <div key={p.name} className={styles.PresetsPage_slide}>
            <img src={p.image} alt='' loading='lazy' />
            <Link to={`/editor/presets/${p.id}`}>
              <ButtonIcon
                iconType='paint'
                onClick={() => {
                  setTimeOfDay("день");
                }}
                className={styles.PresetsPage_slide__btn}
                text={p.purpose === "внешняя" ? "Покрасить этот фасад" : "Покрасить эту комнату"}
              />
            </Link>
          </div>
        )),
    [presets],
  );

  return (
    <PageWrapper fullScreenContent>
      <div className={styles.PresetsPage_root}>
        <Slider {...settings}>{renderPresets}</Slider>
      </div>
    </PageWrapper>
  );
}

export default PresetsPage;

import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Input } from "components";
import { Organisation } from "types";
import { ToastContext } from "contexts";
import { useOrganisations } from "hooks";
import { isError } from "libs";
import styles from "./styles.module.scss";

type Values = Omit<Organisation, "id">;

interface Errors {
  name: string[];
}

function AddOrganisation() {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<Values>();
  const [pending, setPending] = useState(false);
  const { toast } = useContext(ToastContext);
  const { createOrganisation, requireOrganisationsByPage } = useOrganisations();
  const [errors, setErrors] = useState<Errors | null>(null);

  const navigateToOrganisationsPage = () => {
    navigate("/admin/organisations");
  };

  const onSubmit: SubmitHandler<Values> = (values) => {
    setPending(true);

    toast
      .promise(createOrganisation(values), {
        pending: "Создание организации...",
        success: "Организация успешно создана!",
        error: "Ошибка при создании организации!",
      })
      .then(() => {
        requireOrganisationsByPage();
        navigateToOrganisationsPage();
      })
      .catch((e) => {
        if (isError(e) && e.response) {
          setErrors(e.response.data.error);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        error={errors?.name?.toString()}
        name='name'
        register={register}
        placeholder='Введите название'
        label='Название организации'
      />
      <div className={styles.actions}>
        <Button disabled={pending} type='submit'>
          Добавить
        </Button>
        <Button onClick={navigateToOrganisationsPage} className={styles.cancel}>
          Отмена
        </Button>
      </div>
    </form>
  );
}

export { AddOrganisation };

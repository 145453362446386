import { AdminPreset, Id, Pagination } from "types";
import { privateApi } from "libs/api";
import { PAGINATION_COUNT } from "constants/index";

class AdminPresetsService {
  private static uri = "/admin/presets";

  static async requirePresets(
    page: number,
    limit: number = PAGINATION_COUNT,
    search?: string,
  ): Promise<{
    presets: AdminPreset[];
    pagination: Pagination;
  }> {
    const res = await privateApi.get<{ data: AdminPreset[]; pagination: Pagination }>(
      AdminPresetsService.uri,
      {
        params: { page, limit, search },
      },
    );
    return { presets: res.data.data, pagination: res.data.pagination };
  }

  static async updatePresetAllow(id: Id, organisationsIds: Id[]): Promise<AdminPreset> {
    const res = await privateApi.put(`${AdminPresetsService.uri}/${id}`, {
      organisations: organisationsIds,
    });

    return res.data.data;
  }
}

export default AdminPresetsService;

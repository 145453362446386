import { ChangeEventHandler, ReactNode } from "react";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import styles from "./uploadFile.module.scss";

interface UploadFileProps {
  title?: string;
  icon?: ReactNode;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
}

function UploadFile({ title = "Загрузить фото", icon, onChange }: UploadFileProps) {
  return (
    <button className={styles.Upload}>
      <div className={styles.Upload_text}>
        {icon || <Plus className={styles.Upload_icon} />}
        {title}
      </div>
      <label className={styles.Upload_label} htmlFor='upload-file' />
      <input
        accept='image/jpeg'
        onChange={onChange}
        className={styles.Upload_input}
        id='upload-file'
        type='file'
      />
    </button>
  );
}

export { UploadFile };

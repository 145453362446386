import axios, { AxiosResponse, isAxiosError, AxiosError } from "axios";
import { APP, BASE_API_URL } from "constants/index";

const api = axios.create({ baseURL: BASE_API_URL });

const privateApi = axios.create({ baseURL: BASE_API_URL });

privateApi.interceptors.request.use((res) => {
  const token = localStorage.getItem(`${APP}_token`);
  res.headers.Authorization = token ? `Bearer ${token}` : "";
  return res;
});

privateApi.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      console.error("Error while refreshing token", error);
      localStorage.removeItem(`${APP}_token`);
      window.location.replace("/admin/login");
    } else {
      console.error(error);
      throw error;
    }
  },
);

export { api, isAxiosError as isError, privateApi };
export type { AxiosResponse as Response, AxiosError as Error };

import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { Button, Input, PhoneMask } from "components";
import { ToastContext } from "contexts";
import { isError } from "libs";
import { CreateUserParams, useUsers } from "hooks";
import styles from "./styles.module.scss";

interface AddUserProps {
  close: () => void;
}

interface Errors {
  email: string[];
  phone: string[];
  name: string[];
  password: string[];
}

function AddUser({ close }: AddUserProps) {
  const { register, handleSubmit, control } = useForm<CreateUserParams>();
  const [pending, setPending] = useState(false);
  const { toast } = useContext(ToastContext);
  const navigate = useNavigate();
  const { createUser } = useUsers();
  const [errors, setErrors] = useState<Errors | null>(null);

  const onSubmit: SubmitHandler<CreateUserParams> = (values) => {
    setPending(true);

    toast
      .promise(createUser(values), {
        pending: "Создание пользователя...",
        success: "Пользователь успешно создан!",
        error: "Ошибка при создании пользователя!",
      })
      .then(() => {
        navigate("/admin/users");
      })
      .catch((e) => {
        if (isError(e) && e.response) {
          setErrors(e.response.data.error);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        error={errors?.email?.toString()}
        register={register}
        name='email'
        label='E-mail'
        placeholder='Введите e-mail'
      />
      <Controller
        control={control}
        name='phone'
        render={({ field: { ref, ...field } }) => (
          <PhoneMask
            {...field}
            label='Телефон'
            placeholder='Введите номер телефона'
            error={errors?.phone?.toString()}
            name='phone'
            inputProps={{ ref }}
          />
        )}
      />
      <Input
        error={errors?.name?.toString()}
        register={register}
        name='name'
        label='ФИО'
        placeholder='Введите ФИО'
      />
      <Input
        error={errors?.password?.toString()}
        register={register}
        name='password'
        label='Пароль'
        placeholder='Придумайте пароль'
        type='password'
      />
      <div className={styles.actions}>
        <Button disabled={pending} type='submit'>
          Добавить
        </Button>
        <Button className={styles.cancel} onClick={close}>
          Отмена
        </Button>
      </div>
    </form>
  );
}

export { AddUser };

import { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button, ButtonIcon, Input, PhoneMask, ReactSelect } from "components";
import { useManagers, useOrganisations } from "hooks";
import { ToastContext } from "contexts";
import { isError } from "libs";
import { formManager, Values } from "./helpers";
import styles from "./styles.module.scss";

interface EditManagerProps {
  close: () => void;
}

interface Errors {
  email: string[];
  phone: string[];
  name: string[];
  password: string[];
  organisation_id: string[];
  organisation_sub: string[];
}

function EditManager({ close }: EditManagerProps) {
  const { id } = useParams();
  const { getManager, editManager } = useManagers();
  const { register, control, handleSubmit } = useForm<Values>();
  const { requireOrganisationsByKeyword, pending } = useOrganisations();
  const [editManagerPending, setEditManagerPending] = useState(false);
  const { toast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<Errors | null>(null);

  if (!id) return null;

  const manager = getManager(id);

  if (!manager) return null;
  const fetchOrganisationsByKeyword = async (keyword: string) => {
    const res = await requireOrganisationsByKeyword(keyword);
    return res.map((o) => ({ value: o.id, label: o.name }));
  };

  const onSubmit: SubmitHandler<Values> = (values) => {
    setEditManagerPending(true);

    toast
      .promise(editManager(formManager(values, manager.id)), {
        pending: "Обновление менеджера...",
        success: "Менеджер успешно обновлен!",
        error: "Ошибка при обновлении менеджера!",
      })
      .then(() => {
        navigate("/admin/managers");
      })
      .catch((e) => {
        if (isError(e) && e.response) {
          setErrors(e.response.data.error);
        }
      })
      .finally(() => {
        setEditManagerPending(false);
      });
  };

  const navigatoToHideManager = () => {
    navigate(`/admin/managers/${id}/remove`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        error={errors?.email?.toString()}
        value={manager.email}
        name='email'
        register={register}
        label='E-mail'
        placeholder='Введите e-mail'
      />
      <Controller
        control={control}
        name='phone'
        defaultValue={manager?.phone}
        render={({ field: { ref, ...field } }) => (
          <PhoneMask
            {...field}
            label='Телефон'
            placeholder='Введите номер телефона'
            error={errors?.phone?.toString()}
            name='phone'
            inputProps={{ ref }}
          />
        )}
      />
      <ReactSelect
        error={errors?.organisation_id?.toString()}
        required
        isAsync
        label='Наименование организации'
        placeholder='Выберите организацию'
        name='organisation'
        control={control}
        pending={pending}
        className={styles.select}
        callback={fetchOrganisationsByKeyword}
        value={
          manager && manager?.organisation
            ? { label: manager.organisation?.name, value: manager.organisation?.id }
            : undefined
        }
      />
      <Input
        error={errors?.organisation_sub?.toString()}
        value={manager?.organisation?.sub}
        name='organisation_sub'
        register={register}
        label='Подразделение'
        placeholder='Введите подразделение'
      />
      <Input
        error={errors?.name?.toString()}
        value={manager.name}
        name='name'
        register={register}
        label='ФИО'
        placeholder='Введите ФИО'
      />
      <Input
        error={errors?.password?.toString()}
        name='password'
        register={register}
        label='Пароль'
        placeholder='Придумайте пароль'
      />
      <div className={styles.actions} style={{ justifyContent: "space-between" }}>
        <div className={styles.actions_container}>
          <Button type='submit' disabled={editManagerPending}>
            Сохранить
          </Button>
          <Button className={styles.actions_cancel} onClick={close}>
            Отмена
          </Button>
        </div>
        <div className={styles.actions_container}>
          <ButtonIcon
            onClick={navigatoToHideManager}
            type='button'
            className={styles.actions_btn}
            iconType='eye'
          />
        </div>
      </div>
    </form>
  );
}

const EditManagerComponent = observer(EditManager);
export { EditManagerComponent as EditManager };

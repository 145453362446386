function filterColorsByCollectionName(colors, name: string) {
  if (name === "Все коллекции") return colors;

  return colors.filter((c) => c.collection.name === name);
}

function filterColorsByNameOrCode(colors, keyword) {
  if (keyword.trim() === "") return colors;

  return colors.filter(
    (c) =>
      c.ncs.toLowerCase().includes(keyword.toLowerCase().trim()) ||
      `#${c.hex}`.toLowerCase().includes(keyword.toLowerCase().trim()) ||
      c?.name?.toLowerCase().includes(keyword.toLowerCase().trim()),
  );
}

export { filterColorsByCollectionName, filterColorsByNameOrCode };

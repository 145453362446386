import { PAGINATION_COUNT } from "constants/index";
import { privateApi, Response } from "libs/api";
import { Pagination, Season, Id } from "types";

export class SeasonsService {
  private static url = "/admin/seasons";

  static async requireSeasons(
    page: number,
    limit: number = PAGINATION_COUNT,
  ): Promise<{ seasons: Season[]; pagination: Pagination }> {
    const res = await privateApi.get<{ data: Season[]; pagination: Pagination }>(
      SeasonsService.url,
      {
        params: { page, limit },
      },
    );
    return { seasons: res.data.data, pagination: res.data.pagination };
  }

  static async removeSeasonById(id: Id): Promise<void> {
    await privateApi.delete(`${SeasonsService.url}/${id}`);
  }

  static async restoreSeasonById(id: Id): Promise<void> {
    await privateApi.put(`${SeasonsService.url}/restore/${id}`);
  }

  static async requireSeasonById(id: Id): Promise<Season> {
    const res = await privateApi.get<Response<Season>>(`${SeasonsService.url}/${id}`);
    return res.data.data;
  }
}

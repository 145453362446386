import { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button, ButtonIcon, Input, PhoneMask } from "components";
import { useUsers, EditUserParams } from "hooks";
import { ToastContext } from "contexts";
import { isError } from "libs";
import { formUpdatedUser } from "./helpers";
import styles from "./styles.module.scss";

interface EditUserProps {
  close: () => void;
}

interface Errors {
  email: string[];
  phone: string[];
  name: string[];
  password: string[];
}

function EditUser({ close }: EditUserProps) {
  const { id } = useParams();
  const { getUser, editUser } = useUsers();
  const { register, handleSubmit, control } = useForm<EditUserParams>();
  const { toast } = useContext(ToastContext);
  const [pending, setPending] = useState(false);
  const [errors, setErrors] = useState<Errors | null>(null);
  const navigate = useNavigate();

  if (!id) return null;

  const user = getUser(id);

  if (!user) return null;

  const onSubmit: SubmitHandler<EditUserParams> = (values) => {
    setPending(true);

    toast
      .promise(editUser(formUpdatedUser(values, id)), {
        pending: "Обновление пользователя...",
        success: "Пользователь успешно обновлен!",
        error: "Ошибка при обновлении пользователя!",
      })
      .then(() => {
        close();
      })
      .catch((e) => {
        if (isError(e) && e.response) {
          setErrors(e.response.data.error);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  const navigateToUserToManagerPage = () => {
    navigate(`/admin/users/${id}/to-manager`);
  };

  const navigateToRemoveUser = () => {
    navigate(`/admin/users/${id}/remove`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        error={errors?.email?.toString()}
        register={register}
        label='E-mail'
        placeholder='Введите e-mail'
        name='email'
        value={user?.email}
      />
      <Controller
        control={control}
        name='phone'
        defaultValue={user?.phone}
        render={({ field: { ref, ...field } }) => (
          <PhoneMask
            {...field}
            label='Телефон'
            placeholder='Введите номер телефона'
            error={errors?.phone?.toString()}
            name='phone'
            inputProps={{ ref }}
          />
        )}
      />
      <Input
        error={errors?.name?.toString()}
        register={register}
        label='ФИО'
        placeholder='Введите ФИО'
        name='name'
        value={user?.name}
      />
      <Input
        error={errors?.password?.toString()}
        type='password'
        register={register}
        label='Пароль'
        placeholder='Придумайте пароль'
        name='password'
      />
      <Button onClick={navigateToUserToManagerPage} className={styles.removeToManager}>
        Перевести в менеджера
      </Button>
      <div className={styles.actions} style={{ justifyContent: "space-between" }}>
        <div className={styles.actions_container}>
          <Button disabled={pending} type='submit'>
            Сохранить
          </Button>
          <Button className={styles.cancel} onClick={close}>
            Отмена
          </Button>
        </div>
        <div className={styles.actions_container}>
          <ButtonIcon
            onClick={navigateToRemoveUser}
            className={styles.actions_btn}
            iconType='delete'
          />
        </div>
      </div>
    </form>
  );
}

const EditUserComponent = observer(EditUser);
export { EditUserComponent as EditUser };

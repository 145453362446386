import { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button, Input, PhoneMask, ReactSelect } from "components";
import { ToastContext } from "contexts";
import { useManagers, useOrganisations } from "hooks";
import { isError } from "libs";
import { formManager, Values } from "./helpers";
import styles from "./styles.module.scss";

interface AddManagerProps {
  close: () => void;
}

interface Errors {
  email: string[];
  phone: string[];
  name: string[];
  password: string[];
  organisation_id: string[];
  organisation_sub: string[];
}

function AddManager({ close }: AddManagerProps) {
  const { register, handleSubmit, control } = useForm<Values>();
  const { toast } = useContext(ToastContext);
  const { createManager } = useManagers();
  const navigate = useNavigate();
  const { requireOrganisationsByKeyword, pending } = useOrganisations();
  const [createManagerPending, setCreateManagerPending] = useState(false);
  const [errors, setErrors] = useState<Errors | null>(null);

  const onSubmit: SubmitHandler<Values> = (values) => {
    setCreateManagerPending(true);

    toast
      .promise(createManager(formManager(values)), {
        pending: "Создание менеджера...",
        success: "Менеджер успешно создан!",
        error: "Ошибка при создании менеджера!",
      })
      .then(() => {
        navigate("/admin/managers");
      })
      .catch((e) => {
        if (isError(e) && e.response) {
          setErrors(e.response.data.error);
        }
      })
      .finally(() => {
        setCreateManagerPending(false);
      });
  };

  const fetchOrganisationsByKeyword = async (keyword: string) => {
    const res = await requireOrganisationsByKeyword(keyword);
    return res.map((o) => ({ value: o.id, label: o.name }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        error={errors?.email?.toString()}
        register={register}
        name='email'
        label='E-mail'
        placeholder='Введите e-mail'
      />
      <Controller
        control={control}
        name='phone'
        render={({ field: { ref, ...field } }) => (
          <PhoneMask
            {...field}
            label='Телефон'
            placeholder='Введите номер телефона'
            error={errors?.phone?.toString()}
            name='phone'
            inputProps={{ ref }}
          />
        )}
      />
      <ReactSelect
        required
        error={errors?.organisation_id?.toString()}
        control={control}
        isAsync
        label='Наименование организации'
        placeholder='Выберите организацию'
        name='organisation'
        className={styles.select}
        callback={fetchOrganisationsByKeyword}
        pending={pending}
      />
      <Input
        error={errors?.organisation_sub?.toString()}
        register={register}
        name='organisation_sub'
        label='Подразделение'
        placeholder='Введите подразделение'
      />
      <Input
        error={errors?.name?.toString()}
        register={register}
        name='name'
        label='ФИО'
        placeholder='Введите ФИО'
      />
      <Input
        error={errors?.password?.toString()}
        register={register}
        name='password'
        label='Пароль'
        placeholder='Придумайте пароль'
        type='password'
      />
      <div className={styles.actions}>
        <Button type='submit' disabled={createManagerPending}>
          Добавить
        </Button>
        <Button className={styles.cancel} onClick={close}>
          Отмена
        </Button>
      </div>
    </form>
  );
}

const AddManagerComponent = observer(AddManager);
export { AddManagerComponent as AddManager };

import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useUsers } from "hooks/useUsers";
import { AboutEntity } from "../components";

function AboutUserPage() {
  const { id } = useParams();
  const { getUser } = useUsers();

  if (!id) return null;

  const user = getUser(id);

  if (!user) return null;

  return <AboutEntity entity={user} variant='user' />;
}

const AboutUserPageComponent = observer(AboutUserPage);
export { AboutUserPageComponent as AboutUserPage };

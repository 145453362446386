import { Response, api } from "libs";
import { Id, Paint, Season } from "types";

export class SeasonsService {
  private static url = "/catalog/seasons";

  static async requireSeasons(): Promise<Season[]> {
    const res = await api.get<Response<Season[]>>(SeasonsService.url);
    return res.data.data;
  }

  static async requirePaintsById(id: Id): Promise<Paint[]> {
    const res = await api.get<Response<Season[]>>(`${SeasonsService.url}/${id}`);
    return res.data.data[0]?.paints ?? [];
  }
}

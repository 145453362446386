import { createContext, useCallback, useContext, useReducer } from "react";
import { Action } from "types";
import { DrawContext } from "./drawContext";

const HistoryContext = createContext(Object.create(null));

interface State {
  snapshots: any[];
  version: number;
}

const initialState: State = {
  snapshots: [],
  version: -1,
};

const saveSnapshot = (snapshots: any[], textures: any[], version: number) => {
  const newSnapshots = [...snapshots];

  if (version < newSnapshots.length) {
    newSnapshots.splice(version, newSnapshots.length - version);
  }

  newSnapshots[version] = textures;
  return newSnapshots;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "setSnapshots":
      return {
        ...state,
        snapshots: saveSnapshot(state.snapshots, action.payload, state.version + 1),
      };

    case "increaseVersion":
      return { ...state, version: state.version + 1 };

    case "decreaseVersion":
      return { ...state, version: state.version - 1 };

    case "resetSnapshots":
      return { ...state, snapshots: action.payload, version: 0 };

    default:
      return { ...state };
  }
};

function HistoryProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setTextures } = useContext(DrawContext);

  const prev = () => {
    if (state.version < 1) return;
    dispatch({ type: "decreaseVersion" });
    setTextures(state.snapshots[state.version - 1]);
  };

  const next = () => {
    if (state.snapshots[state.version + 1]) {
      dispatch({ type: "increaseVersion" });
      setTextures(state.snapshots[state.version + 1]);
    }
  };

  const createSnapshot = useCallback((textures) => {
    dispatch({
      type: "setSnapshots",
      payload: textures,
    });

    dispatch({ type: "increaseVersion" });
  }, []);

  const resetSnapshots = () => {
    dispatch({ type: "resetSnapshots", payload: [state.snapshots[0]] });
  };

  return (
    <HistoryContext.Provider
      value={{
        next,
        prev,
        createSnapshot,
        resetSnapshots,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
}

export { HistoryContext, HistoryProvider };

function rgbaToHex(r, g, b, a?) {
  if (r === undefined || g === undefined || b === undefined) return null;

  // Проверка наличия альфа-канала
  const hasAlpha = a !== undefined;

  // Преобразование значений в диапазон от 0 до 255
  const round = (num) => Math.round(Math.min(Math.max(0, num), 255));

  // Конвертация в HEX
  const hex = (num: number) => {
    const newHex = num.toString(16);
    return newHex.length === 1 ? `0${newHex}` : newHex;
  };

  // @ts-ignore
  let hexValue = hex(round(r)) + hex(round(g)) + hex(round(b));

  // Добавление альфа-канала, если он задан
  if (hasAlpha) {
    const alphaHex = hex(Math.round(a * 255));
    hexValue += alphaHex;
  }

  return hexValue;
}

export { rgbaToHex };

import { useContext, useState } from "react";
import { Button } from "components";
import { useUsers } from "hooks/useUsers";
import { ToastContext } from "contexts/toastContext";
import styles from "./styles.module.scss";

export function DownloadUsers() {
  const { downloadUsers } = useUsers();
  const { toast } = useContext(ToastContext);
  const [pending, setPending] = useState(false);

  const download = () => {
    setPending(true);

    toast
      .promise(downloadUsers(), {
        pending: "Скачивание файла...",
        success: "Файл успешно скачан!",
        error: "Ошибка при скачивании файла!",
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <Button disabled={pending} onClick={download} className={styles.button}>
      Выгрузить CSV-файл
    </Button>
  );
}

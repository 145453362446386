import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, ButtonIcon, Table } from "components";
import { ReactComponent as Plus } from "assets/iconsBtn/plus.svg";
import { useManagers } from "hooks";
import { Id } from "types";
import { DownloadManagers, RestoreManager } from "features/admin";
import { Header } from "../components";
import styles from "../styles.module.scss";

function ManagersPage() {
  const navigate = useNavigate();
  const { managers, pending, pagination, requireManagersByPage, clear } = useManagers();

  useEffect(() => {
    requireManagersByPage();

    return () => {
      clear();
    };
  }, [requireManagersByPage, clear]);

  const navigateToAddManager = () => {
    navigate("/admin/managers/add");
  };

  const navigateToEditManager = (id: Id) => {
    navigate(`/admin/managers/${id}/edit`);
  };

  const navigateToRemoveManager = (id: Id) => {
    navigate(`/admin/managers/${id}/remove`);
  };

  const moveToAboutManager = (id: Id) => {
    navigate(`/admin/managers/${id}/about`);
  };

  const onPageChange = ({ selected }) => {
    requireManagersByPage(selected + 1);
  };

  const restoreManagerCallback = (id: Id) => <RestoreManager id={id} />;

  return (
    <>
      <Header
        title='Менеджеры'
        uploadCVSFile={<DownloadManagers />}
        addEntityFeature={
          <Button onClick={navigateToAddManager} className={styles.button}>
            <Plus /> Добавить менеджера
          </Button>
        }
      />
      <Table
        headers={["ID", "E-mail", "Телефон", "ФИО", "Организация", "Подразделение"]}
        fields={["id", "email", "phone", "name", "org_name", "org_sub"]}
        deleteAtFieldName='deleted_at'
        values={managers.map(({ id, name, email, phone, organisation, deleted_at }) => ({
          id,
          email,
          phone,
          name,
          org_name: organisation?.name,
          org_sub: organisation?.sub,
          deleted_at,
        }))}
        widthValues={[57, 300, 170, 270, 370, 270]}
        editable
        removable
        editCallback={navigateToEditManager}
        removeCallback={navigateToRemoveManager}
        additionalFeatures={[
          {
            id: 1,
            component: <ButtonIcon className={styles.addUser_actions_btn} iconType='about' />,
            width: 46,
            callback: moveToAboutManager,
          },
        ]}
        removableIcon='eye'
        pending={pending && managers.length === 0}
        paginate
        pageCount={pagination.count_pages}
        onPageChange={onPageChange}
        currentPage={pagination.current_page}
        restoreEntityCallback={restoreManagerCallback}
      />
      <Outlet />
    </>
  );
}

const ManagersPageComponent = observer(ManagersPage);
export { ManagersPageComponent as ManagersPage };

import { useContext } from "react";
import classNames from "classnames";
import { ReactComponent as DeleteIcon } from "assets/iconsBtn/delete.svg";
import { ColorsContext } from "contexts";
import { useColors } from "libs";
import styles from "./styles.module.scss";

export function ResetSelectedColor() {
  const { colorsParams, resetOtherColors } = useContext(ColorsContext);
  const { requireColor } = useColors();

  const onClick = () => {
    if (colorsParams?.color) {
      const newColorsParams = { ...colorsParams };
      delete newColorsParams.color;
      requireColor(newColorsParams);
      resetOtherColors();
    }
  };

  return (
    <div
      onClick={onClick}
      className={classNames(styles.container, { [styles.container_active]: !colorsParams?.color })}
    >
      <DeleteIcon />
    </div>
  );
}

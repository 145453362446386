import { UseFormRegister } from "react-hook-form";
import cn from "classnames";
import { ReactComponent as Search } from "assets/icons/search.svg";
import styles from "./input.module.scss";

type Icon = "search";

interface InputProps {
  label?: string;
  className?: string;
  placeholder: string;
  type?: "text" | "password";
  error?: string;
  value?: string;
  onChange?: (value: string) => void;
  icon?: Icon;
  position?: "right" | "left";
  register?: UseFormRegister<any>;
  name?: string;
  required?: boolean;
}

interface Rules {
  required?: string;
}

function Input({
  label,
  className,
  placeholder,
  type = "text",
  error,
  value,
  onChange,
  icon,
  position = "right",
  register,
  name,
  required = false,
}: InputProps) {
  const iconBuilder = (iconValue: Icon) => {
    switch (iconValue) {
      case "search":
        return (
          <Search
            style={
              position === "left"
                ? { left: "16px", right: "initial" }
                : { right: "16px", left: "initial" }
            }
            className={styles.Input_icon}
          />
        );
      default:
    }
  };

  const generateValidator = () => {
    const rules: Rules = {};

    if (required) {
      rules.required = "Поле обязательное для заполнения";
    }

    return rules;
  };

  return (
    <div className={cn(styles.Input_root, className)}>
      {label && <p className={styles.Input_label}>{label}</p>}
      {icon && iconBuilder(icon)}
      {register && name ? (
        <input
          type={type}
          placeholder={placeholder}
          className={cn(styles.Input_component, {
            [styles.Input_withError]: error,
          })}
          style={{ paddingLeft: position === "left" ? "54px" : "16px" }}
          {...register(name, { ...generateValidator(), value })}
        />
      ) : (
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          className={cn(styles.Input_component, {
            [styles.Input_withError]: error,
          })}
          style={{ paddingLeft: position === "left" ? "54px" : "16px" }}
          onChange={(e) => onChange && onChange(e.target.value)}
        />
      )}
      {error && <p className={styles.Input_error}>{error}</p>}
    </div>
  );
}

export { Input };

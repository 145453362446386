import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, ButtonIcon, Input } from "components";
import { useOrganisations, Values } from "hooks/useOrganisations";
import { ToastContext } from "contexts";
import styles from "./styles.module.scss";

function EditOrganisation() {
  const { id } = useParams();
  const { register, handleSubmit } = useForm<Values>();
  const { getOrganisation, updateOrganisation } = useOrganisations();
  const navigate = useNavigate();
  const { toast } = useContext(ToastContext);
  const [pending, setPending] = useState(false);

  if (!id) return null;

  const organisation = getOrganisation(id);

  const onSubmit: SubmitHandler<Values> = (values) => {
    setPending(true);

    toast
      .promise(updateOrganisation({ ...values, id }), {
        pending: "Обновление организации...",
        success: "Организация успешно обновлена!",
        error: "Ошибка при обновлении организации!",
      })
      .then(() => {
        navigate("/admin/organisations");
      })
      .finally(() => {
        setPending(false);
      });
  };

  const navigateToRemoveOrganisation = () => {
    navigate(`/admin/organisations/${id}/remove`);
  };

  const cancel = () => {
    navigate("/admin/organisations");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        value={organisation?.name}
        name='name'
        register={register}
        placeholder='Введите название'
        label='Название организации'
      />
      <div className={styles.actions} style={{ justifyContent: "space-between" }}>
        <div className={styles.actions_container}>
          <Button disabled={pending} type='submit'>
            Сохранить
          </Button>
          <Button onClick={cancel} type='reset' className={styles.cancel}>
            Отмена
          </Button>
        </div>
        <div className={styles.actions_container}>
          <ButtonIcon
            type='button'
            onClick={navigateToRemoveOrganisation}
            className={styles.actions_btn}
            iconType='delete'
          />
        </div>
      </div>
    </form>
  );
}

export { EditOrganisation };

import { ReactNode } from "react";
import cn from "classnames";
import Header from "./Header";
import Footer from "./Footer";
import { Sidebar } from "./Sidebar";
import styles from "./pageWrapper.module.scss";

interface PageWrapperProps {
  children: ReactNode;
  withMenu?: boolean;
  fullScreenContent?: boolean;
  isAdmin?: boolean;
}

function PageWrapper({
  children,
  withMenu = true,
  fullScreenContent = false,
  isAdmin = false,
}: PageWrapperProps) {
  const renderHeader = () => {
    if (isAdmin) {
      return (
        <div className={styles.PageWrapper_adminHeader}>
          <Sidebar />
        </div>
      );
    }

    return <Header withMenu={withMenu} />;
  };

  const renderFooter = () => {
    if (isAdmin) {
      return <div>renderFooter</div>;
    }

    return <Footer />;
  };

  const renderChildren = () => {
    if (isAdmin) {
      return <div className={styles.PageWrapper_adminContent}>{children}</div>;
    }

    return (
      <div
        className={cn(styles.PageWrapper_content, {
          [styles.PageWrapper_contentFull]: fullScreenContent,
        })}
      >
        {children}
      </div>
    );
  };

  const renderContent = () => {
    if (isAdmin) {
      return (
        <div className={styles.PageWrapper_adminContainer}>
          {renderHeader()}
          {renderChildren()}
        </div>
      );
    }

    return (
      <>
        {renderHeader()}
        {renderChildren()}
        {renderFooter()}
      </>
    );
  };

  return <div className={styles.PageWrapper_root}>{renderContent()}</div>;
}

export { PageWrapper };

import { api, Response, privateApi } from "libs";

export async function login<T>(data: T): Promise<string> {
  const res = await api.post<Response<{ token: string }>>("/admin/login", data);
  return res.data.data.token;
}

export async function logout(): Promise<void> {
  await privateApi.post("/admin/logout", null);
}

export async function code(c: string): Promise<any> {
  await privateApi.post("/admin/code", { code: c });
}
